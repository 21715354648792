import React, {useState, useEffect, useRef} from "react";
import './dmpRealDataCreate.css';
import {Link, useNavigate} from "react-router-dom";
import ToastGrid from "../../toast/toastGrid";
import RegisterDmp from "../../registerDmp/registerDmp";
import Cookies from 'js-cookie';
import {
    useRegisterDataStore,
    useToastSetStore,
    useToastSetResultStore, useToastSetMethodStore
} from "../../stores/lastDmpData";
import ImportDataSet from "../../importDataSet/importDataSet";
import api from '../../api';
import {
    useDataSetsToastStore,
    useDataSummaryToastFilterStore,
} from "../../stores/dataSetsStore";
import ProgressModal from "../../progressModal/progressModal";

function DmpRealDataCreate() {

    const taskRef = useRef(null);
    const dataPageSummaryRef = useRef(null);
    const imgRef = useRef(null);

    const { registerdataSets, resetRegisterDataSets } = useRegisterDataStore();
    const { callSummaryFilterDataSets, resetRegisterDataSummarySets } = useDataSummaryToastFilterStore();
    const { toastdataSets, resetToastDataSets } = useToastSetStore();
    const [files, setFiles] = useState([]);
    const [showCallImgData, setShowCallImgData] = useState([]);
    const [dataPageSummaryData, setDataPageSummaryData] = useState([]);
    const { callToastDataSets, resetDataSummarySets } = useDataSetsToastStore();
    const [showRegisterDmpCheck, setShowRegisterDmpCheck] = useState(false);
    const [showImportData, setShowImportData] = useState(false);
    const [sendImgFile, setSendImgFile] = useState([]);


    // 프로그레스 바

    const [progress, setProgress] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);


    const [fileNames, setFileNames] = useState([]);


    // 읽기 권한
    const [isImported, setIsImported] = useState(false); // 데이터 가져오기 여부 상태

    // 직접 작성 로직
    const [isDataTypeDES, setIsDataTypeDES] = useState('');
    const [isDataTypeName, setIsDataTypeName] = useState('')
    const [isDataSetName, setIsDataSetName] = useState('')
    const [isDataSetNameSub, setIsDataSetNameSub] = useState('')



    //파일 업로드 완료
    const [uploadImgFile, setUploadImgFile] = useState([])

    const [_, setUploadData] = useState([]);
    const DataPageMethodDataSets = useToastSetMethodStore(state => state.toastMethoddataSets);
    const DataPageResultDataSets = useToastSetResultStore(state => state.toastResultdataSets);
    const navigate = useNavigate();

    // 페이지가 로드될 때 초기화 작업
    useEffect(() => {
        resetRegisterDataSets(); // 등록된 데이터 세트 초기화
        resetRegisterDataSummarySets(); // 등록된 데이터 요약 초기화
        resetToastDataSets(); // 토스트 데이터 세트 초기화
        resetDataSummarySets(); // 데이터 요약 초기화
        setDataPageSummaryData([]); // 데이터 페이지 요약 상태 초기화
        setFiles([]); // 파일 리스트 초기화
        setShowCallImgData([]); // 이미지 데이터 초기화
        setSendImgFile([]); // 업로드 이미지 초기화
        setShowImportData(false); // 데이터 임포트 초기화
        setShowRegisterDmpCheck(!showRegisterDmpCheck);
    }, []);

    useEffect(() => {
        if (callSummaryFilterDataSets.length > 0) {
            setDataPageSummaryData(callSummaryFilterDataSets);
            setIsImported(true);
        }
        else{
            setDataPageSummaryData([]);
        }
    }, [callSummaryFilterDataSets]);

    const handleUpdateDataPageChange = (id, event) => {
        event.preventDefault(); // 이벤트의 기본 행동을 방지합니다.
        const newValue = event.target.value; // 사용자가 선택한 새로운 값을 가져옵니다.

        if(id === undefined) {
            setIsDataSetName(newValue);
            return
        }

        const updatedDataSets = dataPageSummaryData.map(dataSet =>
            dataSet.DtaSetNo === id ? { ...dataSet, DtaSetNm: newValue } : dataSet
        );

        setDataPageSummaryData(updatedDataSets);
    }

    const handleUpdateRequierChange = (id, event) => {
        event.preventDefault(); // 이벤트의 기본 행동을 방지합니다.
        const newValue = event.target.value; // 사용자가 선택한 새로운 값을 가져옵니다.

        if(id === undefined) {
            setIsDataSetNameSub(newValue);
            return
        }

        const updatedDataSets = dataPageSummaryData.map(dataSet =>
            dataSet.DtaSetNo === id ? { ...dataSet, DtaSetNmSub: newValue } : dataSet
        );

        setDataPageSummaryData(updatedDataSets);
    }

    const handleDataPageInputChange = (id, event) => {
        event.preventDefault(); // 이벤트의 기본 행동을 방지합니다.
        const newValue = event.target.value; // 사용자가 선택한 새로운 값을 가져옵니다.

        if(id === undefined) {
            setIsDataTypeName(newValue);
            return
        }


        const updatedDataSets = dataPageSummaryData.map(dataSet =>
            dataSet.DtaSetNo === id ? { ...dataSet, DtaNm: newValue } : dataSet
        );

        setDataPageSummaryData(updatedDataSets);
    }

    const handleDataPageDES = (id, event) => {
        const newValue = event.target.value; // 사용자가 선택한 새로운 값을 가져옵니다.

        if(id === undefined) {
            setIsDataTypeDES(newValue);
            return
        }

        const updatedDataSets = dataPageSummaryData.map(dataSet =>
            dataSet.DtaSetNo === id ? { ...dataSet, DtaDs: newValue } : dataSet
        );

        setDataPageSummaryData(updatedDataSets);
    }
    const toggleObjCheck = () => {
        setShowRegisterDmpCheck(!showRegisterDmpCheck);
    }

    const toggleImportDataSets = () => {
        setShowImportData(!showImportData);
    }

    //신규 데이터 등록에 필요한 로직



    const handleFileChange = (event) => {
        if (Array.isArray(showCallImgData) && showCallImgData.length > 0) {
            // 기존 파일 목록에 새로운 파일들을 추가
            setShowCallImgData((prev) => [...prev, ...Array.from(event.target.files)]);
        } else {
            setFiles((prev)=> [...prev, ...Array.from(event.target.files)]);
        }
    };

    const handleUpload = async (file, index) => {
        if (files.length === 0) {
            alert("파일을 먼저 선택해주세요.");
            return;
        }

        setProgress(0); // 초기 진행률
        setIsCompleted(false); // 완료 상태 초기화
        setIsModalOpen(true); // 모달 열기

        const uploadedFileSignatures = new Set(
            sendImgFile.map((uploadedFile) =>
                `${uploadedFile.OriginalFileName}-${uploadedFile.Size}` // 서버에서 반환된 구조에 따라 수정
            )
        );

        const newFiles = files.filter(
            (file) =>
                !uploadedFileSignatures.has(`${file.name}-${file.size}`) // 파일의 고유 식별자 생성 및 비교
        );

        if (newFiles.length === 0) {
            alert("같은 이름의 파일은 업로드가 불가능 합니다.");
            setIsModalOpen(false);
            return;
        }

        const formData = new FormData();
        newFiles.forEach((file) => {
            formData.append("files", file);
        });
        formData.append("ct", "Data");

        const csrfToken = Cookies.get("XSRF-TOKEN");

        try {
            const response = await api.post("/api/file/uploadfiles", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "X-XSRF-TOKEN": csrfToken,
                },
                withCredentials: true,
                onUploadProgress: (progressEvent) => {
                    const total = progressEvent.total || 0;
                    const loaded = progressEvent.loaded || 0;
                    const percentage = Math.round((loaded / total) * 100);
                    setProgress(percentage); // 진행률 업데이트
                },
            });

            setSendImgFile((prev) => [...prev, ...response.data]); // 서버에서 받은 파일 추가
            setUploadImgFile((prev) => [...prev, ...newFiles.map((f) => f.name)]); // 업로드된 파일 이름 추가
            setIsCompleted(true); // 완료 상태 설정

        } catch (error) {
            console.error("파일 업로드 실패:", error);
            alert("파일이 업로드되지 않았습니다.");
        } finally {
            setTimeout(() => {
                setIsModalOpen(false); // 모달 닫기
                setProgress(0); // 초기화
            }, 500); // 2초 후 모달 닫기
        }
    };

    // 상태 업데이트 후 로그를 확인하기 위해 useEffect 사용
    useEffect(() => {

    }, [showCallImgData]);


    const closeFile = (indexRemoveFile) => {
            setFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexRemoveFile));

            const removeUploadFile = setUploadData((prevFiles)=> prevFiles.filter((item, index) => {
                if (index === indexRemoveFile) {
                    return (item.SaveFile);
                }
            }));

            const csrfToken = Cookies.get('XSRF-TOKEN');

            if(removeUploadFile !== undefined) {
                api.post(`/api/file/deleteFile`, {
                    SaveFile : removeUploadFile,
                }, {
                    headers: {
                        'X-XSRF-TOKEN': csrfToken
                    }
                })
                alert("업로드 된 파일 삭제!!");
        }
    }

    //삭제 이후 데이터 제출 렌더링
    useEffect(() => {
    }, [showCallImgData]);


    const getSelectedDataSets = (DtaSetNm) => {

        if (DtaSetNm === '시료데이터') {
            return toastdataSets;
        } else if (DtaSetNm === '방법데이터') {
            return DataPageMethodDataSets;
        } else if (DtaSetNm === '결과데이터') {
            return DataPageResultDataSets;
        }
        return []; // Return an empty array if none of the conditions match
    };

    const updateSummaryDataSets = () => {
        if (dataPageSummaryData && dataPageSummaryData.length > 0 && dataPageSummaryData[0].DtaSetNm) {
            return getSelectedDataSets(dataPageSummaryData[0].DtaSetNm);
        }
        return []; // Return an empty array if no valid data is found
    };

    const validatePage = (isTemporary = false) => {
        if (registerdataSets?.length === 0 || registerdataSets.DataMain?.some(item => !item.ProjNo || !item.ProjNm || !item.RspnEmpNm)) {
            alert("DMP 정보를 선택해주세요."); // DMP 정보 검증 실패 시 메시지
            taskRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return false;
        }
        if (dataPageSummaryData.length === 0 && isDataSetName === '') {
            alert("데이터 세트의 Summary 값이 비어있습니다."); // 데이터세트 요약 검증 실패 시 메시지
            dataPageSummaryRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return false;
        }

        if (callToastDataSets.length === 0 && getSelectedDataSets(isDataSetName).length === 0) {
            alert("데이터세트에 값이 없습니다."); // 데이터세트 값 검증 실패 시 메시지
            dataPageSummaryRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return false;
        }

        if (!isTemporary && sendImgFile.length === 0) {
            alert("제출 데이터를 선택해주세요."); // 제출 데이터 검증 실패 시 메시지
            imgRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return false;
        }

        return true; // 모든 검증을 통과하면 true 반환
    };



    const realTempData = async() => {


        if (dataPageSummaryData.length === 0 && (isDataSetName === '' || isDataSetNameSub === '' || isDataTypeName === '' || isDataTypeDES === '' )) {
            alert("데이터 세트의 Summary까지는 작성해주셔야 임시저장이 가능합니다."); // 검증 실패 시 사용자 메시지

            return; // 저장 중단
        }

        const summaryData = dataPageSummaryData && dataPageSummaryData[0] ? dataPageSummaryData[0] : {};

        const dmpMain = registerdataSets && registerdataSets.dmpMain ? registerdataSets.dmpMain : {};
        const formData = new FormData();

        files.forEach((file) => {
            formData.append('files', file);
        });

// 파일 리스트가 비어 있으면 기본값 할당
        const resultSaveFileList = (sendImgFile && sendImgFile.length > 0) ? sendImgFile.map((item) => ({
            DtaFilePath: item.FilePath || null,
            DtaFileNm: item.OriginalFileName || null,
            DtaSaveFileNm: item.SaveFile || null,
            DtaFileSize: item.Size || null,
        })) : [];

// Toast 데이터가 비어 있으면 기본값 할당
        let resultSaveDataSets = (callToastDataSets && callToastDataSets.length > 0) ? callToastDataSets.map((item) => ({
            DtaSetNo: item.DtaSetNo,
            DmpNo: item.DmpNo,
            DtaFld: item.DtaFld || '-',
            DtaTyp: item.DtaTyp || '-',
            DtaUnit: item.DtaUnit || '-',
            DtaMtdIdx: item.DtaMtdIdx || '-',
            DtaMtdNm: item.DtaMtdNm || '-',
            DtaDsc: item.DtaDsc || '-',
            UserNo: dmpMain.UserNo || '-',
            UserNm: dmpMain.UserNm || '-',
            DelFlg: "N"
        })) : [{
            // DtaSetNo: '-',
            // DmpNo: '-',
            DtaFld: '-',
            DtaTyp: '-',
            DtaUnit: '-',
            DtaMtdIdx: '-',
            DtaMtdNm: '-',
            DtaDsc: '-',
            UserNo: dmpMain.UserNo || '-',
            UserNm: dmpMain.UserNm || '-',
            DelFlg: "N"
        }];

// 업데이트된 데이터셋이 비어 있으면 기본값 사용
        const updatedDataSets = updateSummaryDataSets() || []; // 기본적으로 빈 배열이 아닌 경우에만 처리

        if (updatedDataSets.length > 0) {
            resultSaveDataSets = updatedDataSets.map((item) => ({
                DtaSetNo: summaryData.DtaSetNo,
                DmpNo: dmpMain.DmpNo,
                DtaFld: item.field || '-',
                DtaTyp: item.type || '-',
                DtaUnit: item.unit || '-',
                DtaMtdIdx: item.index || '-',
                DtaMtdNm: item.name || '-',
                DtaDsc: item.methoddescription || item.description || '-',
                UserNo: dmpMain.UserNo,
                UserNm: dmpMain.UserNm,
                DelFlg: "N"
            }));
        }



        try {

            const csrfToken = Cookies.get('XSRF-TOKEN');

            const response = await api.post('/api/data/savedata', {
                Summary:
                    {
                        DmpNo : dmpMain.DmpNo,
                        DtaSetNo:callToastDataSets.length === 0 ? 0 : summaryData.DtaSetNo,
                        DtaSetNm: callToastDataSets.length === 0 ? isDataSetName : summaryData.DtaSetNm,
                        DtaSetNmSub : callToastDataSets.length === 0 ? isDataSetNameSub : summaryData.DtaSetNmSub,
                        DtaNm: callToastDataSets.length === 0 ? isDataTypeName :summaryData.DtaNm,
                        DtaDs : callToastDataSets.length === 0 ? isDataTypeDES : summaryData.DtaDs,
                        DmpFlg: callToastDataSets.length === 0 ? 'N' : summaryData.DmpFlg,
                    },
                DataMain: resultSaveDataSets,

                DataSubmit:
                    {
                        // DataEmpEmail:"dlrudgh1303@gmail.com",
                        SubmitStatus : 0,
                        // OpenStatus: 0,
                        // OpenStartDt: "2024-09-02 03:27:41",
                        // OpenEndDt: "2024-09-02 03:27:41",
                        // InsDt:"2024-09-02 03:27:41",
                        // UpdDt:"2024-09-02 03:27:41",
                        UserNo : dmpMain.UserNo,
                        UserNm : dmpMain.UserNm,
                        DelFlg: "N"
                    },

                DataSubmitFile: resultSaveFileList,
                TmpYn: "Y",

            }, { withCredentials: true,
                headers: {
                    'X-XSRF-TOKEN': csrfToken,
                }
            }).then((res) => res.data);
            alert('임시저장 성공');
            navigate("/dmpRealData/dmpRealDataList")
        } catch (error) {
            console.error('Upload error:', error);
        }
    }



    const realDataSave = async () => {
            const formData = new FormData();

            files.forEach((file) => {
                formData.append('files', file);
            });

            const resultSaveFileList = sendImgFile.map((item) => ({
                DtaFilePath: item.FilePath,
                DtaFileNm: item.OriginalFileName,
                DtaSaveFileNm: item.SaveFile,
                DtaFileSize: item.Size,
            }));

        let resultSaveDataSets = callToastDataSets.map((item) => ({
            DtaSetNo: item.DtaSetNo,
            DmpNo: item.DmpNo,
            DtaFld: item.DtaFld,
            DtaTyp: item.DtaTyp,
            DtaUnit: item.DtaUnit,
            DtaMtdIdx: item.DtaMtdIdx,
            DtaMtdNm: item.DtaMtdNm,
            DtaDsc: item.DtaDsc,
            UserNo: registerdataSets.dmpMain.UserNo,
            UserNm: registerdataSets.dmpMain.UserNm,
            DelFlg: "N"
        }));

        const updatedDataSets = updateSummaryDataSets(); // updateSummaryDataSets 호출 결과를 변수에 저장


        if(updatedDataSets.length > 0) {
            resultSaveDataSets = updatedDataSets.map((item) => ({
                DtaSetNo: item.DtaSetNo,
                DmpNo: item.DmpNo,
                DtaFld: item.field,
                DtaTyp: item.type,
                DtaUnit: item.unit,
                DtaMtdIdx: item.index,
                DtaMtdNm: item.name,
                DtaDsc: item.methoddescription || item.description,
                UserNo: registerdataSets.dmpMain.UserNo,
                UserNm: registerdataSets.dmpMain.UserNm,
                DelFlg: "N",
            }));
        }

        if(callToastDataSets.length === 0) {
            const updatedDataSets = getSelectedDataSets(isDataSetName);
            resultSaveDataSets = updatedDataSets.map((item) => ({
                DtaSetNo: item.DtaSetNo,
                DmpNo: item.DmpNo,
                DtaFld: item.field,
                DtaTyp: item.type,
                DtaUnit: item.unit,
                DtaMtdIdx: item.index,
                DtaMtdNm: item.name,
                DtaDsc: item.methoddescription || item.description,
                UserNo: registerdataSets.dmpMain.UserNo,
                UserNm: registerdataSets.dmpMain.UserNm,
                DelFlg: "N",
            }));
        }



        if (!validatePage(false)) {
            return; // 검증에 실패하면 저장 중단
        }

        try {

            const csrfToken = Cookies.get('XSRF-TOKEN');
                const response = await api.post('/api/data/savedata', {
                    Summary:
                        {
                            DmpNo : registerdataSets.dmpMain.DmpNo,
                            DtaSetNo:callToastDataSets.length === 0 ? 0 : dataPageSummaryData[0].DtaSetNo,
                            DtaSetNm: callToastDataSets.length === 0 ? isDataSetName : dataPageSummaryData[0].DtaSetNm,
                            DtaSetNmSub :  callToastDataSets.length === 0 ? isDataSetNameSub : dataPageSummaryData[0].DtaSetNmSub,
                            DtaNm:  callToastDataSets.length === 0 ? isDataTypeName : dataPageSummaryData[0].DtaNm,
                            DtaDs : callToastDataSets.length === 0 ? isDataTypeDES : dataPageSummaryData[0].DtaDs,
                            // DmpFlg : dataPageSummaryData[0].DmpFlg,
                            DmpFlg: callToastDataSets.length === 0 ? 'N' : dataPageSummaryData[0].DmpFlg,
                        },
                    DataMain: resultSaveDataSets,


                    DataSubmit:
                        {
                            // DataEmpEmail:"dlrudgh1303@gmail.com",
                            SubmitStatus : 1,
                            // OpenStatus: 0,
                            // OpenStartDt: "2024-09-02 03:27:41",
                            // OpenEndDt: "2024-09-02 03:27:41",
                            // InsDt:"2024-09-02 03:27:41",
                            // UpdDt:"2024-09-02 03:27:41",
                            UserNo : registerdataSets.dmpMain.UserNo,
                            UserNm : registerdataSets.dmpMain.UserNm,
                            DelFlg: "N"
                        },

                    DataSubmitFile: resultSaveFileList,
                    TmpYn: "N",

                }, { withCredentials: true,
                    headers: {
                        'X-XSRF-TOKEN': csrfToken,
                    }
                }).then((res) => res.data);
                alert('신규데이터 저장 성공하셨습니다.');
            navigate("/dmpRealData/dmpRealDataList")
            } catch (error) {
                console.error('Upload error:', error);
                alert('저장 api를 확인해보세요.');
            }
    };

    const dataCancel = () => {
        navigate("/dmpRealData/dmpRealDataList")
    }


    const previewDMPBtn = (name, id) => {

        if(registerdataSets.length === 0) {
            alert("먼저 DMP 정보를 선택해주세요.")
            return;
        }

        api.get(`/api/hwp/${name}?DmpNo=${id}`, {
            withCredentials: true,
            responseType: 'blob'  // 응답을 blob으로 받기 위해 설정
        })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error('Failed to download file, status code: ' + res.status);
                }
                return res.data;  // blob 데이터
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `${name}.hwp`; // 파일명
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
                // setDownloadedRow(id);
            })
            .catch(error => {
                console.error('Error downloading HWP file:', error);
                alert("문서 다운로드가 불가능합니다. 담당자를 통해 확인해주세요.")
            });
    };

    useEffect(() => {
        // 세션을 유지하기 위한 함수
        const keepSessionAlive = async () => {
            try {
                // 백엔드에 세션 유지 요청 보내기
                const response = await api.get('/api/auth/user', { withCredentials: true });
            } catch (error) {
                console.error('Failed to keep session alive:', error);
            }
        };

        // 30분(1800000ms)마다 세션 유지 요청 보내기
        const intervalId = setInterval(keepSessionAlive, 1200000); // 20분 = 1200000ms

        // 컴포넌트가 언마운트될 때 setInterval 정리
        return () => clearInterval(intervalId);
    }, []);

    return(
        <>
            <div className="dmpRealData-banner-container">
                <div className="dmpRealData-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/sub-introimg03.jpg`} className="dmpRealData-img02" alt="search Logo" />
                    <div className="RealDatabanner-text">
                        <span className="intro-subName">데이터 등록</span>
                    </div>
                    <div className="dmpRealData-description">
                        <div className="dmpRealData-description02">
                            <img  src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터> 데이터 등록</span>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="RealData-management-title">
                        <h1>데이터 등록</h1>
                        <div>
                            <span className="required_color">*</span>
                            <span>필수 항목</span>
                        </div>
                    </div>
                    <div className="under_line"></div>
                    <div className="RealData-object-info" ref={taskRef}>
                        <div className="RealData-object-info-title">
                            <span className="RealData-info">DMP 정보</span>
                            <span className="required_color">*</span>
                        </div>
                        <div className="RealData-task-title">
                            <div className="RealData-task-wrapper" >
                                <button className="Real-check-obj" onClick={toggleObjCheck}>등록 DMP 불러오기</button>
                                {showRegisterDmpCheck && <RegisterDmp/>}

                                {
                                    registerdataSets && registerdataSets.dmpMain ? (
                                        <div key={registerdataSets.dmpMain?.DmpNo} className="createCallDMP">
                                            <input
                                                type="text"
                                                className="edit--box"
                                                name="projectNumber"
                                                readOnly
                                                value={registerdataSets?.dmpMain?.ProjNo || ""}
                                                placeholder="과제번호"
                                            />
                                            <input
                                                type="text"
                                                className="edit--box"
                                                name="projectName"
                                                readOnly
                                                value={registerdataSets?.dmpMain?.ProjNm || ""}
                                                placeholder="과제명"
                                            />
                                            <input
                                                type="text"
                                                className="edit--box"
                                                name="projectLeader"
                                                readOnly
                                                value={registerdataSets?.dmpMain?.RspnEmpNm || ""}
                                                placeholder="연구책임자"
                                            />
                                        </div>
                                    ) : (
                                        <div className="createCallDMP">
                                            <input
                                                type="text"
                                                className="edit--box"
                                                name="projectNumber"
                                                readOnly
                                                value={""}
                                                placeholder="과제번호"
                                            />
                                            <input
                                                type="text"
                                                className="edit--box"
                                                name="projectName"
                                                readOnly
                                                value={""}
                                                placeholder="과제명"
                                            />
                                            <input
                                                type="text"
                                                className="edit--box"
                                                name="projectLeader"
                                                readOnly
                                                value={""}
                                                placeholder="연구책임자"
                                            />
                                        </div>
                                    )
                                }

                                <button className="Real-check-obj"
                                        onClick={()=> previewDMPBtn(registerdataSets?.dmpMain?.ProjNm, registerdataSets?.dmpMain?.DmpNo)}>DMP 보기</button>
                            </div>
                            <div>
                                <span className="tast-des">* 등록된 DMP를 선택하면 DMP 정보가 자동으로 입력됩니다.</span>
                            </div>
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="dataCreate-container">
                        <div className="dataCreate-title">
                            <span>등록 대상 데이터</span>
                            <span className="required_color">*</span>
                        </div>
                        <div>
                            <div className="dmpDataSets-container">
                                {showImportData && <ImportDataSet DmpNo={registerdataSets?.dmpMain?.DmpNo} />}

                                {(() => {
                                    let dataSetsToRender = dataPageSummaryData.length > 0 ? dataPageSummaryData : [{}]; // 데이터가 없으면 빈 배열로 기본 값을 설정
                                    return dataSetsToRender.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <div className="dataSets-Inputboxs">
                                                <button className="dataSet-bring" onClick={toggleImportDataSets} ref={dataPageSummaryRef}>DMP 데이터세트 불러오기</button>
                                                <div className="selectbox-container">
                                                    <div className="DataPage-selectbox-check">
                                                        <select
                                                            className="createDataPage"
                                                            id={`selectBox-${item?.DtaSetNo || index}`} // item이 없으면 기본 인덱스를 사용
                                                            // value={item?.DtaSetNm || ''}
                                                            // readonly
                                                            // onChange={(event) => handleUpdateDataPageChange(item?.DtaSetNo, event)}

                                                            value={isDataSetName || item?.DtaSetNm || ''}
                                                            readOnly={isImported} // 가져온 데이터일 때만 읽기 전용
                                                            onChange={(event) =>
                                                                handleUpdateDataPageChange(item?.DtaSetNo, event)
                                                            }
                                                        >
                                                            <option value=''>-- 구분 --</option>
                                                            <option value="시료데이터">시료데이터</option>
                                                            <option value="방법데이터">방법데이터</option>
                                                            <option value="결과데이터">결과데이터</option>
                                                        </select>

                                                        <select
                                                            className="createDataPage"
                                                            id="selectBox"
                                                            value={isDataSetNameSub || item?.DtaSetNmSub || ''}
                                                            readOnly={isImported}
                                                            onChange={(e) => handleUpdateRequierChange(item?.DtaSetNo, e)}
                                                        >
                                                            <option value=''>-- 선택 * 필수 --</option>
                                                            <option value="Food, Unprocessed">Food, Unprocessed</option>
                                                            <option value="Food, Processed">Food, Processed</option>
                                                            <option value="Food, Derived">Food, Derived</option>
                                                            <option value="Animal">Animal</option>
                                                            <option value="Human">Human</option>
                                                            <option value="Invertebrate">Invertebrate</option>
                                                            <option value="Microbe">Microbe</option>
                                                            <option value="Cell, Animal">Cell, Animal</option>
                                                            <option value="Cell, Human">Cell, Human</option>
                                                            <option value="Cell, Plant">Cell, Plant</option>
                                                            <option value="etc">etc</option>
                                                        </select>

                                                        <input
                                                            type="text"
                                                            className="edit--box"
                                                            value={isDataTypeName || item.DtaNm || ''}
                                                            onChange={(e) => handleDataPageInputChange(item?.DtaSetNo, e)}
                                                            id="data-type-name"
                                                            name="DtaNm"
                                                            readOnly={isImported}
                                                            placeholder="데이터 타입명"
                                                        />

                                                        <input
                                                            type="text"
                                                            className="edit--box"
                                                            value={isDataTypeDES || item?.DtaDs || ''}
                                                            readOnly={isImported}
                                                            onChange={(e) => handleDataPageDES(item?.DtaSetNo, e)}
                                                            id="data-type-desc"
                                                            name="DtaDs"
                                                            placeholder="데이터 타입 설명"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="datePage-targetData-des">
                                                <span className="tast-des">* 등록된 DMP 데이터세트를 선택하면 데이터세트가 자동으로 입력됩니다.</span>
                                                <span className="tast-des">* TYPE은 문자열:"STRING", 숫자:"NUMERIC", 날짜:"DATE", 파일명:"FILE" 로 사용을 권장합니다.</span>
                                                <ToastGrid DateSets={callToastDataSets} DmpFlg = {dataPageSummaryData[0]?.DmpFlg} MethodData={item?.DtaSetNm || isDataSetName} />
                                            </div>
                                        </React.Fragment>
                                    ));
                                })()}
                            </div>

                        </div>
                    </div>
                    <div className="start_line"></div>

                    <div className="RealDataSend-title">
                        <div className="RealData-send" ref={imgRef}>
                            <span>데이터 제출</span>
                            <span className="required_color">*</span>
                        </div>
                        <div className="RealData-sendFile-container">
                            {Array.isArray(files) && files.length > 0 && (
                                <>
                                    {files.map((item, index) => (
                                        <div className="creatFile-upload" key={index}>
                                            <div className="fileName">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/btn_close.png`}
                                                    alt="close"
                                                    onClick={() => closeFile(index)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <div>
                                                    <p>{item.name}</p> {/* 파일의 이름을 표시합니다 */}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {/* 모든 파일 목록이 표시된 후 업로드 버튼을 한 번만 보여줌 */}
                                    <div className="RealData-btnContainer">
                                        <button className="RealData-save-button" onClick={() => handleUpload(files)}>
                                            업로드
                                        </button>
                                        <ProgressModal
                                            isOpen={isModalOpen}
                                            progress={progress}
                                            fileNames={fileNames}
                                            isCompleted={isCompleted}
                                        />
                                    </div>
                                </>
                            )}
                            <div>
                                <div className="research-method-title">
                                    <label htmlFor="fileDiagram">
                                        <img id="imgDiagram" src={`${process.env.PUBLIC_URL}/btn_file_select.png`} alt="Diagram" />
                                    </label>
                                </div>
                                <input
                                    type="file"
                                    id="fileDiagram"
                                    name="dataSubmitFiles"
                                    multiple
                                    onChange={handleFileChange}
                                    className="search-input"
                                    placeholder="파일 이름"
                                />
                            </div>
                        </div>

                    </div>
                </section>

                <div className="RealData-save-btn-container">
                    <button id="dmp_list"  onClick={dataCancel}>
                        취소
                    </button>
                    <button id="dmp_list" onClick={realTempData}>
                        임시저장
                    </button>
                    <button id="save-btn"  onClick={realDataSave} >
                        저장
                    </button>
                    
                </div>
            </div>
        </>
    )
}

export default DmpRealDataCreate
