import React, {useCallback, useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import '../submitStatus/dataSubmitStatus.css';
import api from "../../api";
import Cookies from 'js-cookie';
import {useSearchHomeStore} from "../../stores/dataSetsStore";
import {debounce} from "lodash";

function DmpSubmitStatus() {

    const [tableData, setTableData] = useState([]);
    const pageSize = 10; // 한 페이지에 표시할 데이터 수
    const pageGroupSize = 10; // 한 번에 표시할 페이지 번호 개수
    const [listCount, setListCount] = useState(0); // 총 데이터 개수
    const [totalPages, setTotalPages] = useState(0); // 전체 페이지 수
    const [btnPage, setBtnPage] = useState(1);


    // 체크박스 상태
    const [checkedItems, setCheckedItems] = useState({});
    const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

    // 제출 상태
    const [submitStatuses, setSubmitStatuses] = useState({});

    const currentYear = new Date().getFullYear();
    const [years, setYears] = useState([]);


    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const [selectedYear, setSelectedYear] = useState(Number(queryParams.get("year")) || '');

    const [currentPage, setCurrentPage] = useState(Number(queryParams.get("page")) || 1);
    const [searchTerm, setSearchTerm] = useState(queryParams.get("search") || "");



    // 2022년부터 현재 연도까지의 배열을 생성
    useEffect(() => {
        const startYear = 2022;
        const yearList = [];
        for (let year = startYear; year <= currentYear; year++) {
            yearList.push(year);
        }
        setYears(yearList);
    }, [currentYear]);

    const dataSendPage = async ( searchTerm = "", year  = "", page = 1) => {

        const start = (page - 1) * pageSize + 1; // 페이지 시작 위치 계산
        let endPoint = `/api/m/dmpstatus?kw=${searchTerm}&year=${year}&ps=${start}&pl=${pageSize}`;
        try {
            const res = await api.get(endPoint, {
                withCredentials: true,
            });
            setTableData(res.data.item);
            setListCount(res.data.pages.Total); // 총 데이터 개수 설정
            setTotalPages(Math.ceil(res.data.pages.Total / pageSize)); // 전체 페이지 수 계산
        } catch (error) {
            console.error('API 에러를 확인해주세요.', error);
        }
    };

    useEffect(() => {
        // 페이지 이동 시 데이터를 초기화
        setTableData([]); // 초기화
        dataSendPage(searchTerm, selectedYear, currentPage);
    }, [currentPage, selectedYear, searchTerm]);

    useEffect(() => {
        const params = new URLSearchParams();
        if(selectedYear) params.set("year", selectedYear);
        if (searchTerm) params.set("search", searchTerm);
        if (currentPage) params.set("page", currentPage);
        navigate(`?${params.toString()}`, { replace: true }); // URL을 업데이트하되 브라우저 기록을 추가하지 않음
    }, [currentPage, searchTerm, selectedYear,  navigate]);


    // const debouncedFetchData = useCallback(
    //     debounce((value, page) => dataSendPage(value, page), 300), // 1초 동안 입력이 없으면 호출
    //     []
    // );

    const handlerInputChange = (event) => {
        const value = event.target.value;

        // 입력된 값의 길이가 14자를 넘으면 alert를 띄우고 값을 초기화
        if (value.length > 14) {
            alert("입력 가능한 최대 길이는 14자입니다.");
            setSearchTerm(""); // 값을 초기화
            event.target.value = ""; // input 필드도 초기화
            return;
        }

        setSearchTerm(value); // 상태 업데이트
        setCurrentPage(1); // 페이지를 초기화
        dataSendPage(value, selectedYear,1); // 최신 값을 사용하여 즉시 데이터 가져오기
    };
    // 페이지네이션 렌더링

    const handleYearChange = (e) => {
        const year = e.target.value;
        setSelectedYear(year);
        setCurrentPage(1); // 페이지를 처음으로 리셋
        setBtnPage(1);
    };

    const handerbtn = (pageNumber, i) => {
        // setCurrentPage(pageNumber);
        // setBtnPage(i);

        setCurrentPage(pageNumber); // 상태 업데이트
        dataSendPage(searchTerm,selectedYear, pageNumber); // 최신 값을 사용하여 즉시 데이터 가져오기
    };
    const renderPagination = () => {
        const startPage = Math.floor((currentPage - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

        const pages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <button className="pagination-button" onClick={() => handerbtn(startPage - 1)}>
                        이전
                    </button>
                )}
                {pages.map((page) => (
                    <button
                        key={page}
                        className={`pagination-button ${page === currentPage ? "active" : ""}`}
                        onClick={() => handerbtn(page)}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages && (
                    <button className="pagination-button" onClick={() => handerbtn(endPage + 1)}>
                        다음
                    </button>
                )}
            </div>
        );
    };



    const adminMenu = [
        { 'id': 1, 'name': "공개시기 관리(캘린더)", 'path': "/adminPage" },
        { 'id': 2, 'name': "과제 목록", 'path': "/adminPage/objView" },
        { 'id': 3, 'name': "데이터 제출 현황", 'path': "/adminPage/submintStatus" },
        { 'id': 4, 'name': "데이터 제출 목록 일괄", 'path': "/adminPage/dataSubmitList" },
        { 'id': 5, 'name': "데이터 제출 상태 변경", 'path': "/adminPage/dataSubmitStatusChange" },
        { 'id': 6, 'name': "DMP 작성 현황 및 제출 상태 변경", 'path': "/adminPage/dmpSubmitStatus" },
        { 'id': 7, 'name': "데이터 관리 계획 접근권한(User별)", 'path': "/adminPage/userManagement" },
        { 'id': 8, 'name': "데이터 관리 계획 접근권한(과제별)", 'path': "/adminPage/dmpAccessCheck" },
        { 'id': 9, 'name': "생산계획 누락현황 확인", 'path': "/adminPage/productionPlanMng" },
        { 'id': 10, 'name': "신청서 관리 페이지(신청서 정보)", 'path': "/adminPage/applicationManagement" },
    ];
    // 전체 선택 핸들러
    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        setIsSelectAllChecked(isChecked);
        const newCheckedItems = {};
        tableData.forEach(row => {
            newCheckedItems[row.DmpNo] = isChecked;
        });
        setCheckedItems(newCheckedItems);
    };

    // 개별 체크박스 핸들러
    const handleCheckboxChange = (event, id) => {
        const isChecked = event.target.checked;
        setCheckedItems(prevState => ({
            ...prevState,
            [id]: isChecked,
        }));
    };

    // 제출 상태 변경 핸들러
    const handleStatusChange = (e, id) => {
        const newStatus = e.target.value;
        setSubmitStatuses(prevStatuses => ({
            ...prevStatuses,
            [id]: newStatus, // 해당 행의 상태만 변경
        }));
    };

    // 일괄 상태 변경 핸들러
    const handleBulkStatusChange = async () => {
        const selectedIds = Object.keys(checkedItems).filter(id => checkedItems[id]);

        if(selectedIds.length === 0) {
            alert("해당 체크박스를 체크해주세요.")
            return;
        }
        try {
            const unchangedStatuses = selectedIds.filter(id => !submitStatuses[id] || submitStatuses[id] === ""); // 상태값 조건에 맞게 수정 필요

            if (unchangedStatuses.length > 0) {
                alert("모든 체크된 항목의 상태값을 변경해주세요.");
                return; // 상태 변경 실행을 중단
            }
            await Promise.all(
                selectedIds.map(async (id) => {
                    // 개별 id의 상태 값을 가져옴
                    const status = submitStatuses[id]; // 기본값 "1" 사용 (필요에 따라 조정)
                    const csrfToken = Cookies.get('XSRF-TOKEN');

                    // API 요청
                    await api.patch(`/api/m/dmpsubmit/${id}/${status}`, null, {
                        withCredentials: true,
                        headers: {
                            'X-XSRF-TOKEN': csrfToken,
                        },
                    });
                })
            );

            alert("상태가 성공적으로 업데이트되었습니다.");

            // window.location.reload();

            window.location.reload();
            dataSendPage(searchTerm, currentPage);

            // 체크박스 초기화
            setCheckedItems({});
            setIsSelectAllChecked(false);

        } catch (err) {
            console.error("일괄 상태 변경 에러:", err);
        }
    };

    const dmpHwpDownload = (name, id) => {
        api.get(`/api/hwp/${name}?DmpNo=${id}`, {
            withCredentials: true,
            responseType: 'blob'  // 응답을 blob으로 받기 위해 설정
        })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error('Failed to download file, status code: ' + res.status);
                }
                return res.data;  // blob 데이터
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `${name}.hwp`; // 파일명
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            })
            .catch(error => {
                console.error('Error downloading HWP file:', error);
                alert("문서 다운로드가 불가능합니다. 담당자를 통해 확인해주세요.")
            });
    };

    const handleRowClick = (id) => {
        navigate('/dmpManagement/dmpModify', {
            state: { id }, // id를 state로 전달
        });


    }

    const activeMenuId = adminMenu.find((menu) => menu.path === location.pathname)?.id || null;


    return (
        <>
            <div className="adminpage-container">
                <div className="small-title">
                    <img src={`${process.env.PUBLIC_URL}/adminpage.png`} className="adminlist-img02" alt="search Logo" />
                    <div className="adminhd-line"></div>
                    <h2 className="menu-name">관리자 메뉴</h2>
                </div>
                <div className="menu-list">
                    <div className="menu-title">
                        {adminMenu.map((item) => (
                            <React.Fragment key={item.id}>
                                <div className={`menu-item ${activeMenuId === item.id ? "active" : ""}`}>
                                    <Link to={item.path}>
                                        {item.name}
                                    </Link>
                                </div>
                                <div className="admin-under"></div>
                            </React.Fragment>
                        ))}
                    </div>
                    <div className="admin-line"></div>
                    <div className="year-name">
                        <div className="submin-container">
                            <h3>DMP 작성 현황 및 제출 상태 변경</h3>
                            <h4>※ 조회하고자 하는 연도를 아래에서 선택해주세요.</h4>

                            <div>
                                <div className="accessCheck-year">
                                    과제수행년도
                                    <select value={selectedYear} className="submin-year" onChange={handleYearChange}>
                                        <option value="">전체</option>
                                        {years.map((year) => (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div>총 {listCount}건</div>
                        </div>

                        <div className="admin-searchbox">
                            <img src={`${process.env.PUBLIC_URL}/icon_search.png`} alt="search Logo" />
                            <input type="text" placeholder="검색어를 입력해주세요." value={searchTerm}
                                   onChange={handlerInputChange} />
                            <h4>※ 과제번호, 과제명, 연책, 키워드로 검색이 가능합니다.</h4>
                        </div>
                        <div className="status-btn04">
                            <button className="dataStatus-allChange" onClick={handleBulkStatusChange}>
                                선택된 항목 상태 일괄 변경
                            </button>
                        </div>
                        <div className="title-line02"></div>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                            <tr className="dmplist-title">
                                <th>
                                    <input
                                        type="checkbox"
                                        className="row-checkboxSize"
                                        checked={isSelectAllChecked}
                                        onChange={handleSelectAllChange}
                                    />
                                </th>
                                <th>DmpNo</th>
                                <th>과제번호</th>
                                <th>과제수행년도</th>
                                <th>과제명</th>
                                <th>연책</th>
                                <th>데이터담당</th>
                                <th>제출자</th>
                                <th>제출상태</th>
                                <th>최종수정일</th>
                                <th>DMP문서</th>

                            </tr>
                            </thead>
                            <tbody>
                            {tableData.map((row) => (
                                <tr key={row.DmpNo} className="dmplist-Data">
                                    <td>
                                        {row.DmpNo !== 0 ? (
                                            <input
                                                type="checkbox"
                                                className="row-checkboxSize"
                                                checked={checkedItems[row.DmpNo] || false}
                                                onChange={(e) => handleCheckboxChange(e, row.DmpNo)}
                                            />
                                        ) : null}
                                    </td>
                                    <td>{row.DmpNo}</td>
                                    <td>{row.ProjNo}</td>
                                    <td>{row.AnnlYy}</td>
                                    <td style={{ cursor: 'pointer' }} className='auth-user-cell' >
                                        {row.DmpNo !== 0 ? (
                                            <span onClick={() => handleRowClick(row.DmpNo)}>
                                                {row.ProjNm}
                                            </span>
                                        ) : (
                                            <span>{row.ProjNm}</span> // span 태그로 감싸서 렌더링
                                        )}
                                    </td>

                                    <td>
                                        {row.RspnEmpNm}
                                    </td>
                                    <td>{row.DataEmpNm}</td>
                                    <td style={{ color: row.DmpNo === 0 ? 'red' : 'inherit' }}>
                                        {row.DmpNo === 0 ? "미작성" : row.UserNm}
                                    </td>
                                    <td>
                                        <select
                                            style={{ height: '37px', fontSize: '13px',     width: '41%' }}
                                            value={row.DmpNo === 0 ? "미작성" : (submitStatuses[row.DmpNo] || row.SubmitStatus)}
                                            onChange={(e) => handleStatusChange(e, row.DmpNo)}
                                            disabled={row.DmpNo === 0} // DmpNo가 0일 경우 선택 불가
                                        >
                                            {/* DmpNo가 0일 때는 미작성만 선택되므로 다른 옵션은 표시하지 않음 */}
                                            {row.DmpNo === 0 ? (
                                                <>
                                                    <option value="미작성">미작성</option>
                                                </>
                                            ) : (
                                                <>
                                                    <option value="0">임시저장</option>
                                                    <option value="1">저장</option>
                                                    <option value="2">제출</option>
                                                    <option value="3">검토완료</option>
                                                    <option value="4">검토완료(의견있음)</option>
                                                </>
                                            )}
                                        </select>
                                    </td>
                                    <td>{row.UpdDt}</td>
                                    <td>
                                        <button className="download-button" onClick={() => dmpHwpDownload(row.ProjNm, row.DmpNo)}>다운로드</button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        {renderPagination()}
                    </div>
                </div>
            </div>
        </>
    );
}

export default DmpSubmitStatus;
