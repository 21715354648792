import create from 'zustand';
import { persist } from 'zustand/middleware'; // Zustand persist 사용

const useLoginAuthStore = create(
    persist(
        (set) => ({
            userId: null,
            isAdmin: false, // 관리자 여부 추가
            userRole: null, // 사용자 역할 추가

            // 상태 변경 함수
            setUserId: (id) => set({ userId: id }),
            clearUserId: () => set({ userId: null }),
            setIsAdmin: (isAdmin) => set({ isAdmin }),
            setUserRole: (role) => set({ userRole: role }),
            clearAuth: () => set({ userId: null, isAdmin: false, userRole: null }), // 모든 상태 초기화
        }),
        {
            name: 'loginAuthStore',
            getStorage: () => sessionStorage,
        }
    )
);

export default useLoginAuthStore;
