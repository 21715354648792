import React from "react";
import './footer.css';

function footer() {

    return(
        <>
            <div className="footer-container" style={{ display: 'flex', alignItems: 'center', padding: '10px', backgroundColor: '#333'}}>
                <div className="footer-left" >
                    <div className="footer-letter">
                        <div>
                            <img
                                src={`${process.env.PUBLIC_URL}/kfri-footer-img.png`}
                                alt="search Logo"
                                style={{ cursor: 'pointer' }} // 커서 스타일을 포인터로 변경하여 클릭 가능하게 보이도록 설정
                            />
                        </div>
                        <div className="footer-contents">
                            <div>게시된 내용에 대해 궁금하신 점은 아래의 연락처로 문의해 주세요.</div>
                            <div>담당자: 감사부 | 연락처: 063-219-9035</div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
export default  footer;