import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import '../submitStatus/dataSubmitStatus.css';
import api from "../../api";
function ProdFileDown() {
    const { DtaSbmSeqNo } = useParams();
    const [tableData, setTableData] = useState([]);

    const location = useLocation();

    const { ProjNm, DtaNm } = location.state || {}; // 전달된 id 가져오기


    const dataSendPage = async () => {
        const endPoint = `/api/m/sbmfiles?DtaSbmSeqNo=${DtaSbmSeqNo}`;
        try {
            const res = await api.get(endPoint, {
                withCredentials: true,
            });
            setTableData(res.data.file);

        }catch (error) {
            console.err('api 에러를 확인해주세요.', error);
        }
    }

    useEffect(() => {
        dataSendPage()
    }, []);

    const adminMenu = [
        { 'id': 1, 'name': "공개시기 관리(캘린더)", 'path': "/adminPage" },
        { 'id': 2, 'name': "과제 목록", 'path': "/adminPage/objView" },
        { 'id': 3, 'name': "데이터 제출 현황", 'path': "/adminPage/submintStatus" },
        { 'id': 4, 'name': "데이터 제출 목록 일괄", 'path': "/adminPage/dataSubmitList" },
        { 'id': 5, 'name': "데이터 제출 상태 변경", 'path': "/adminPage/dataSubmitStatusChange" },
        { 'id': 6, 'name': "DMP 작성 현황 및 제출 상태 변경", 'path': "/adminPage/dmpSubmitStatus" },
        { 'id': 7, 'name': "데이터 관리 계획 접근권한(User별)", 'path': "/adminPage/userManagement" },
        { 'id': 8, 'name': "데이터 관리 계획 접근권한(과제별)", 'path': "/adminPage/dmpAccessCheck" },
        { 'id': 9, 'name': "생산계획 누락현황 확인", 'path': "/adminPage/productionPlanMng" },
        { 'id': 10, 'name': "신청서 관리 페이지(신청서 정보)", 'path': "/adminPage/applicationManagement" },
    ];
    const previewHandler02 = async (fn, originName, dir, fileSeqNumber) => {
        console.log("경로 탐색", fn, originName, dir, fileSeqNumber);

        try {
            // API 호출
            const response = await api.get(`/api/file/downloadFile`, {
                params: {
                    fn: fn,
                    originName: originName,
                    dir: dir,
                    FileSeqNo: fileSeqNumber
                },
                withCredentials: true,
                responseType: 'blob', // Blob으로 응답 받음
            });

            // 파일 다운로드 성공 처리
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', originName); // originName을 파일 이름으로 설정
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // 다운로드 완료 후 링크 제거

            alert('다운로드 성공');
        } catch (err) {
            console.error('파일 다운로드 실패:', err);
            alert('파일을 다운로드할 수 없습니다.');
        }
    };

    const activeMenuId = adminMenu.find((menu) => menu.path === location.pathname)?.id || 9;

    return(
        <>
            <div className="adminpage-container">
                <div className="small-title">
                    <img src={`${process.env.PUBLIC_URL}/adminpage.png`} className="adminlist-img02" alt="search Logo" />
                    <div className="adminhd-line"></div>
                    <h2 className="menu-name">관리자 메뉴</h2>
                </div>
                <div className="menu-list">
                    <div className="menu-title">
                        {adminMenu.map((item) => (
                            <React.Fragment key={item.id}>
                                <div className={`menu-item ${activeMenuId === item.id ? "active" : ""}`}>
                                    <Link to={item.path}>
                                        {item.name}
                                    </Link>
                                </div>
                                <div className="admin-under"></div>
                            </React.Fragment>
                        ))}
                    </div>
                    <div className="admin-line"></div>
                    <div className="year-name">
                        <div className="fileDown-container">
                            <h3>
                                제출한 데이터 파일
                            </h3>
                            <h4>[{ProjNm}]</h4>
                            <p>{DtaNm}</p>
                        </div>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                            <tr className="dmplist-title">
                                <th>FileSeqNo</th>
                                <th>파일명</th>
                                <th>파일크기</th>
                                <th>다운로드 횟수</th>
                                <th>다운로드</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tableData?.map((row) => (
                                <tr key={row.id} className="dmplist-Data">
                                    <td>{row.FileSeqNo}</td>
                                    <td>{row.DtaFileNm}</td>
                                    <td>{(row.DtaFileSize / 1000000).toFixed(2)}GB</td>
                                    <td>{row.DwnCnt}</td>
                                    <td> <button className="dataStatus-management" onClick={() => previewHandler02(row.DtaSaveFileNm, row.DtaFileNm, row.DtaFilePath, row.FileSeqNo)}>파일 다운로드</button></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </>
    )
}

export  default ProdFileDown;