import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import api from './api'; // API 호출을 위한 모듈

function PrivateRoute({ children, adminOnly = false }) { // 추가된 adminOnly prop
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [userAuthCheck, setUserAuthCheck] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        // 로그인 상태 확인 API 호출
        const checkAuth = async () => {
            try {
                const res = await api.get(`/api/auth/user`, { withCredentials: true });

                // userNm이 null이 아니면 인증됨
                setIsAuthenticated(res.data.userNm !== null);
                setUserAuthCheck(res.data.isAdmin);

                // isAdmin 값이 "Y"이면 관리자로 설정
                setIsAdmin(res.data.isAdmin === "Y");
            } catch (error) {
                console.error("Failed to check authentication", error);
                setIsAuthenticated(false);
                setIsAdmin(false); // 에러 발생 시 관리자가 아님
            }
        };

        checkAuth();
    }, [navigate]);


    // 아직 인증 상태가 결정되지 않으면 로딩 상태 유지
    if (isAuthenticated === null) return null;

    // 인증 상태와 관리자인지 확인하여 페이지 접근 제어
    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    if (adminOnly && userAuthCheck === "N") {
        return <Navigate to="/notExist" />; // 관리자가 아니면 Unauthorized 페이지로 리다이렉트
    }

    return children;
}

export default PrivateRoute;
