import React, {useCallback, useEffect, useState} from "react";
import './dmpsend.css';
import api from '../../api';
import Cookies from 'js-cookie';
import {useLocation, useNavigate} from "react-router-dom";
import {debounce} from "lodash";

function Dmpsend() {
    const [dmpsend, setDmpSend] = useState([]); // DMP 목록
    const [dmpsendValue, setDmpsendValue] = useState(0); // 총 DMP 값
    const [totalPages, setTotalPages] = useState(1); // 전체 페이지 수
    const pageSize = 10; // 한 페이지에 표시할 DMP 수
    const pageGroupSize = 10; // 페이지네이션 그룹 크기

    const location = useLocation();
    const navigate = useNavigate();

    // URL 쿼리 파라미터에서 초기 값 추출
    const queryParams = new URLSearchParams(location.search);
    const [currentPage, setCurrentPage] = useState(Number(queryParams.get("page")) || 1);
    const [searchTerm, setSearchTerm] = useState(queryParams.get("search") || "");

    const [btnPage, setBtnPage] = useState(1); // 페이지 그룹 관리
    const [downloadedRow, setDownloadedRow] = useState(null); // 다운로드한 행의 ID 저장


    const fetchDmpList = ( searchTerm = "", page = 1) => {

        const start = (page - 1) * pageSize + 1; // 페이지 시작 위치 계산
        api.get(`/api/dmp/dmplist?kw=${searchTerm}&ps=${start}&pl=${pageSize}&pg=${'SBM'}`, {
            withCredentials: true
        }).then(res => {
            setDmpSend(res.data.items);
            setDmpsendValue(res.data.pages.Total); // 총 DMP 개수 설정
            setTotalPages(Math.ceil(res.data.pages.Total / pageSize)); // 전체 페이지 수 계산
        });
    };

    // 컴포넌트가 마운트되거나 currentPage가 변경될 때 DMP 리스트 불러오기
    useEffect(() => {
        fetchDmpList(searchTerm, currentPage);
    }, [currentPage, searchTerm]);


    useEffect(() => {
        const params = new URLSearchParams();
        if (searchTerm) params.set("search", searchTerm);
        if (currentPage) params.set("page", currentPage);
        navigate(`?${params.toString()}`, { replace: true }); // URL을 업데이트하되 브라우저 기록을 추가하지 않음
    }, [currentPage, searchTerm, navigate]);


    const debouncedFetchData = useCallback(
        debounce((value, page) => fetchDmpList(value, 1), 300),
        []
    );

    const dmpHwpDownload = (name, id) => {
        api.get(`/api/hwp/${name}?DmpNo=${id}`, {
            withCredentials: true,
            responseType: 'blob'  // 응답을 blob으로 받기 위해 설정
        })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error('Failed to download file, status code: ' + res.status);
                }
                return res.data;  // blob 데이터
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `${name}.hwp`; // 파일명
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
                setDownloadedRow(id);
            })
            .catch(error => {
                alert("문서 제출이 불가능합니다. 담당자를 통해 확인해주세요.")
            });
    };

    const dmpSendHandler = (id) => {
        if (downloadedRow !== id) {
            alert("DMP 제출 전 문서를 다운로드 하여 내용을 확인해주세요.\nDMP가 제출 된 후 수정을 원하시는 경우 지능화정책팀으로 문의하여 주시기 바랍니다.");
            return;
        }

        const csrfToken = Cookies.get('XSRF-TOKEN'); // CSRF 토큰을 쿠키에서 가져오기

        api.put(`/api/dmp/dmpsubmit?DmpNo=${id}`, {}, {
            headers: {
                'X-XSRF-TOKEN': csrfToken, // CSRF 토큰 헤더에 추가
            },
            withCredentials: true, // 쿠키 사용 시 필요
        })
            .then(res => {
                alert("제출이 완료 되었습니다.");
                window.location.reload();
                fetchDmpList(searchTerm, currentPage);

                // 로컬 상태에서 제출된 행 업데이트
                setDmpSend((prevDmpsend) =>
                    prevDmpsend.map((row) =>
                        row.DmpNo === id
                            ? { ...row, SubmitStatus: '2' } // 제출 상태를 '2'(제출 완료)로 업데이트
                            : row
                    )
                );
            })
            .catch(error => {
                console.error('Error submitting DMP:', error);
                alert("제출이 불가능합니다. 담당자를 통해 확인해주세요.")
            });
    };



    const handlerInputChange = (event) => {
        const value = event.target.value;

        // 입력된 값의 길이가 14자를 넘으면 alert를 띄우고 값을 초기화
        if (value.length > 14) {
            alert("입력 가능한 최대 길이는 14자입니다.");
            setSearchTerm(""); // 값을 초기화
            event.target.value = ""; // input 필드도 초기화
            return;
        }
        setSearchTerm(value); // 상태 업데이트
        setCurrentPage(1); // 페이지를 초기화
        fetchDmpList(value, 1); // 최신 값을 사용하여 즉시 데이터 가져오기
        // setSearchTerm(value);
        // setCurrentPage(1);
        // setBtnPage(1);
        // debouncedFetchData(value); // 디바운스된 API 호출
    };

    // const handerbtn = (pageNumber, i) => {
    //     setCurrentPage(pageNumber);
    //     setBtnPage(i);
    //
    // }

    const handerbtn = (pageNumber) => {
        setCurrentPage(pageNumber); // 상태 업데이트
        fetchDmpList(searchTerm, pageNumber); // 최신 값을 사용하여 즉시 데이터 가져오기
    };


    // 페이지네이션 렌더링 함수
    const renderPagination = () => {
        const startPage = Math.floor((currentPage - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

        const pages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <button className="pagination-button" onClick={() => handerbtn(startPage - 1)}>
                        이전
                    </button>
                )}
                {pages.map((page) => (
                    <button
                        key={page}
                        className={`pagination-button ${page === currentPage ? "active" : ""}`}
                        onClick={() => handerbtn(page)}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages && (
                    <button className="pagination-button" onClick={() => handerbtn(endPage + 1)}>
                        다음
                    </button>
                )}
            </div>
        );
    };

    const moveModify  =(id) => {
        navigate('/dmpManagement/dmpModify', {
            state: { id }, // id를 state로 전달
        });
    }

    return (
        <>
            <div className="dmpsend-container">
                <div className="dmpsend-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/sub-introimg02.jpg`} className="dmpsend-img02" alt="search Logo" />
                    <div className="banner-text">
                        <span className="intro-subName">데이터 관리 계획(DMP 제출)</span>
                    </div>
                    <div className="dmpsend-description">
                        <div className="dmpsend-description02">
                            <img src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터 관리 계획 > DMP 제출</span>
                        </div>
                    </div>
                </div>
                <div className="dmpReallist-inputbox">
                    <img src={`${process.env.PUBLIC_URL}/icon_search.png`} alt="search Logo" />
                    <input type="text" placeholder="검색어를 입력해주세요."
                           value={searchTerm}
                           onChange={handlerInputChange}

                    />
                </div>
                <div className="dmp-sendcontainer">
                    <div>
                        <div>총 {dmpsendValue}건</div>
                    </div>
                    <div className="dmpsend-start_line"></div>

                    {dmpsend.length === 0 ? (
                        <>
                            <div className="noDataList">
                                <h1>제출 DMP가 없습니다.</h1>
                            </div>
                        </>
                    ) : (
                        <>
                            <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '30px' }}>
                                <thead>
                                <tr className="dmpsend-title">
                                    <th>과제번호</th>
                                    <th>연차</th>
                                    <th>과제명</th>
                                    <th>연구 책임자</th>
                                    <th>DMP 문서 다운로드</th>
                                    <th>DMP 제출상태</th>
                                    <th>마지막 수정일</th>
                                </tr>
                                </thead>
                                <tbody>
                                {dmpsend.map((row, index) => (
                                    <tr key={index} className="dmpsend-Data">
                                        <td>{row.ProjNo}</td>
                                        <td>{row.ProjAnnl}년차</td>
                                        <td
                                            onClick={() => moveModify(row.DmpNo)}
                                        >{row.ProjNm}</td>
                                        <td>{row.RsPnEmpNm}</td>
                                        <td>
                                            <button className="download-button" onClick={() => dmpHwpDownload(row.ProjNm, row.DmpNo)}>다운로드</button>
                                        </td>
                                        <td>
                                            {row.SubmitStatus === '1' ? (
                                                <button className="dmpsend-submit01" onClick={() => dmpSendHandler(row.DmpNo)}>DMP 제출하기</button>
                                            ) : row.SubmitStatus === '2' ? (
                                                <button className="dmpsend-submit02" disabled>제출</button>
                                            ) : row.SubmitStatus === '3' ? (
                                                <button className="dmpsend-complete" disabled>검토완료</button>
                                            ) : row.SubmitStatus === '4' ? (
                                                <button className="dmpsend-complete-opinion" disabled>검토완료(의견있음)</button>
                                            ) : null }
                                        </td>


                                        <td>{row.UpdDt}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            {renderPagination()}
                        </>
                        )}
                </div>
            </div>
        </>
    );
}

export default Dmpsend;
