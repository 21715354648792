import React, { useCallback, useEffect, useState } from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import '../dmpRealDataList/dmpRealDataList.css';
import api from '../../api';
import { debounce } from "lodash";

function DmpHisDataList() {
    const [dataList, setDataList] = useState([]);
    const [dataCount, setDataCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState(""); // 검색어 상태

    const [btnPage, setBtnPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
    const [totalPages, setTotalPages] = useState(0); // 전체 페이지 수
    const pageSize = 10; // 한 페이지에 표시할 데이터 개수
    const pageGroupSize = 10; // 한 그룹에 표시할 페이지 번호 개수 (1-10, 11-20 등)


    const navigate = useNavigate();

    const location = useLocation();
    const { DmpNo,DtaSetNo } = location.state || {}; // 전달된 id 가져오기

    const fetchData = async (searchTerm = "", page = 1) => {
        try {
            const start = (page - 1) * pageSize + 1; // 페이지 시작 위치 계산
            const res = await api.get(`/api/datahis/list?kw=${searchTerm}&ps=${start}&pl=${pageSize}&DmpNo=${DmpNo}&DtaSetNo=${DtaSetNo}`, {
                withCredentials: true
            });
            setDataList(res.data.item); // 현재 페이지 데이터 목록
            setDataCount(res.data.pages.Total); // 총 데이터 개수
            setTotalPages(Math.ceil(res.data.pages.Total / pageSize)); // 전체 페이지 수 계산
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    // 페이지 변경 시 데이터를 다시 불러옴
    useEffect(() => {
        fetchData(searchTerm, currentPage); // 검색어와 현재 페이지를 기준으로 API 호출
    }, [currentPage, searchTerm]); // 페이지가 변경될 때만 호출


    const handleRowClick = (DmpNo, DtaSetHisNo) => {
        navigate('/dmpRealData/dmpHisDataView', {
            state: { DmpNo, DtaSetHisNo }, // id를 state로 전달
        });
    };

    // 검색어가 변경되었을 때만 디바운스 적용하여 API 호출
    // const debouncedFetchData = useCallback(
    //     debounce((value, page) => fetchData(value, page), 300), // 1초 동안 입력이 없으면 호출
    //     []
    // );

    // 검색어 입력 처리
    const handlerInputChange = (event) => {
        const value = event.target.value;

        // 입력된 값의 길이가 14자를 넘으면 alert를 띄우고 값을 초기화
        if (value.length > 14) {
            alert("입력 가능한 최대 길이는 14자입니다.");
            setSearchTerm(""); // 값을 초기화
            event.target.value = ""; // input 필드도 초기화
            return;
        }

        setSearchTerm(value); // 상태 업데이트
        setCurrentPage(1); // 페이지를 초기화
        fetchData(value, 1); // 최신 값을 사용하여 즉시 데이터 가져오기

        // setSearchTerm(value);
        // setCurrentPage(1); // 검색 시 페이지를 처음으로 리셋
        // debouncedFetchData(value, 1); // 디바운스된 API 호출
    };

    //
    // const handerbtn = (pageNumber, i) => {
    //     setCurrentPage(pageNumber);
    //     setBtnPage(i);
    // }
    const handerbtn = (pageNumber) => {
        setCurrentPage(pageNumber); // 상태 업데이트
        fetchData(searchTerm, pageNumber); // 최신 값을 사용하여 즉시 데이터 가져오기
    };

    // 페이지네이션 렌더링 함수

    const renderPagination = () => {
        const startPage = Math.floor((currentPage - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

        const pages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <button className="pagination-button" onClick={() => handerbtn(startPage - 1)}>
                        이전
                    </button>
                )}
                {pages.map((page) => (
                    <button
                        key={page}
                        className={`pagination-button ${page === currentPage ? "active" : ""}`}
                        onClick={() => handerbtn(page)}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages && (
                    <button className="pagination-button" onClick={() => handerbtn(endPage + 1)}>
                        다음
                    </button>
                )}
            </div>
        );
    };
    //이력을 볼 수있는 버튼


    return (
        <>
            <div className="dmpReallist-container">
                <div className="dmpReallist-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/sub-introimg03.jpg`} className="dmpReallist-img02" alt="search Logo" />
                    <div className="banner-Realtext">
                        <span className="intro-subName">데이터 이력</span>
                    </div>
                    <div className="dmpReallist-description">
                        <div className="dmpReallist-description02">
                            <img src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터 > 데이터 이력</span>
                        </div>
                    </div>
                </div>
                <div className="dmpReallist-inputbox">
                    <img src={`${process.env.PUBLIC_URL}/icon_search.png`} alt="search Logo" />
                    <input type="text" placeholder="검색어를 입력해주세요." value={searchTerm}
                           onChange={handlerInputChange} />
                </div>
                <div>
                    <div className="btn-Realcontainer">
                        <div>총 {dataCount}건</div>
                    </div>
                    <div className="dmpReallist-start_line"></div>

                    {dataList.length !== 0 ? (
                        <>
                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                <tr className="dmpReallist-title">
                                    <th>과제번호</th>
                                    <th>과제명</th>
                                    <th>데이터셋/설명</th>
                                    <th>연구 책임자</th>
                                    <th>수정자</th>
                                    <th>저장시간</th>
                                    <th>데이터 상태</th>
                                </tr>
                                </thead>
                                <tbody>
                                {dataList.map((row, index) => (
                                    <tr key={index} className="dmpReallist-Data">
                                        <td>{row.ProjNo}</td>
                                        <td>{row.ProjNm}</td>
                                        <td
                                            className="update-page"
                                            onClick={() => handleRowClick(row.DmpNo, row.DtaSetHisNo)}
                                        >
                                            {row.DtaSetNm} / {row.DtaDs}

                                        </td>
                                        <td>{row.RspnEmpNm}</td>
                                        <td>{row.UserNm}</td>
                                        <td>{row.HisInsDT}</td>
                                        <td>
                                            {
                                                row.OpenStatus === "1" ? (
                                                    <>
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/unlock.png`}
                                                            style={{ width: '20px', marginRight: '5px', verticalAlign: 'text-bottom' }}
                                                            alt="unlock Logo"
                                                        />
                                                        <span>공개</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/icon_lock.png`}
                                                            style={{ width: '25px', marginRight: '5px', verticalAlign: 'text-bottom' }}
                                                            alt="lock Logo"
                                                        />
                                                        <span>비공개</span>
                                                    </>
                                                )
                                            }
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            {renderPagination()}
                        </>
                    ) : (
                        <>
                            <div className="noDataList">
                                <h1>이력 데이터가 없습니다.</h1>
                            </div>
                        </>
                        )}
                </div>
            </div>
        </>
    );
}

export default DmpHisDataList;
