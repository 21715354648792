import React from 'react';
import { Link } from 'react-router-dom'; // 사용자가 다른 경로로 이동할 수 있도록 react-router-dom 사용

const ErrorPage = () => {
    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>404</h1>
            <p style={styles.message}>현재 존재하지 않는 페이지 입니다.</p>
            <Link to="/" style={styles.homeLink}>첫페이지로 이동하기</Link>
        </div>
    );
};

// 간단한 스타일 적용
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
    },
    heading: {
        fontSize: '5rem',
        margin: '0',
    },
    message: {
        fontSize: '1.5rem',
        margin: '1rem 0',
    },
    homeLink: {
        fontSize: '1.2rem',
        color: '#007BFF',
        textDecoration: 'none',
    }
};

export default ErrorPage;
