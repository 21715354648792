import React from 'react';
import Modal from 'react-modal';
import { ProgressBar } from "react-loader-spinner";
import './ProgressModal.css'; // CSS 스타일링

const ProgressModal = ({ isOpen, progress, fileNames, isCompleted }) => {

    return (
        <Modal
            isOpen={isOpen}
            className="modal-container"
            overlayClassName="modal-overlay"
            ariaHideApp={false}
        >
            <div className="modal-content" style={{width : '350px'}}>
                {!isCompleted ? (
                    <>
                        <h2>업로드 중...</h2>
                        <ProgressBar
                            height="80"
                            width="200"
                            color="#4fa94d"
                            ariaLabel="circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                        <p>업로드 진행률: {progress}%</p>
                        <ul>
                            {fileNames.map((fileName, index) => (
                                <li key={index}>{fileName}</li>
                            ))}
                        </ul>
                    </>
                ) : (
                    <>
                        <h2>업로드 완료!</h2>
                        <p>파일이 성공적으로 업로드되었습니다.</p>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default ProgressModal;
