import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import '../dmpRealData/dmpRealDataList/dmpRealDataList.css';

function CurationList() {
    return (
        <>
            <div className="dmpReallist-container">
                <div className="dmpReallist-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/sub-introimg05.jpg`} className="dmpReallist-img02" alt="search Logo" />
                    <div className="banner-Realtext">
                        <span className="intro-subName">데이터큐레이션</span>
                    </div>
                    <div className="dmpReallist-description">
                        <div className="dmpReallist-description02">
                            <img src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터큐레이션 > 데이터큐레이션 목록</span>
                        </div>
                    </div>
                </div>
                <div className="curation-title">
                    <h1>업데이트 예정입니다.</h1>
                </div>
            </div>
        </>
    );
}

export default CurationList;
