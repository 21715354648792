import React from "react";
import { Navigate } from "react-router-dom";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // 에러 발생 시 상태 업데이트
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // 에러 로깅
        console.error("ErrorBoundary에서 에러 발생:", error, errorInfo);
    }

    componentDidMount() {
        // 전역 에러 핸들링 설정
        window.onerror = (message , source, lineno, colno, error) => {
            console.error("Global Error:", { message, source, lineno, colno, error });

            if (message.includes("Cannot read properties of undefined (reading 'rowSpanMap')")) {
                console.warn('Suppressed rowSpanMap 222222222222222222222222222222222222error:', message);

                console.error("span-Error:", { message, source, lineno, colno, error });

                return true; // 에러를 브라우저에 표시하지 않음
            }

            this.setState({ hasError: true });
        };

        window.addEventListener("unhandledrejection", (event) => {
            // Promise Rejection에서 rowSpanMap 에러 처리
            const reason = event.reason;
            if (reason && reason.message && reason.message.includes("Cannot read properties of undefined (reading 'rowSpanMap')")) {
                console.warn('Suppressed rowSpanMap33333333333333333333333333333333333333 error:', reason.message);
                event.preventDefault(); // 브라우저 에러 창 방지
                return;
            }

            console.error("Unhandled Promise Rejection:", reason);
            this.setState({ hasError: true });
        });
    }

    componentWillUnmount() {
        // 전역 핸들러 제거
        window.onerror = null;
        window.removeEventListener("unhandledrejection", () => {});
    }

    render() {
        if (this.state.hasError) {
            // 에러 발생 시 ToastErrorPage로 리다이렉트
            // return <Navigate to="/toastError" replace />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
