import React, { useEffect, useState } from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../submitStatus/dataSubmitStatus.css';
import api from "../../api";
import Cookies from 'js-cookie';

function ApplicationManagementPrint() {
    const { AppNo } = useParams();
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const [fileData, setFileData] = useState([])
    const [dataInfo, setDataInfo] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const pageGroupSize = 10;
    const [listCount, setListCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [btnPage, setBtnPage] = useState(1);

    const [checkedItems, setCheckedItems] = useState({});
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [submitStatuses, setSubmitStatuses] = useState({});

    // 날짜 데이터

    const [startDate, setStartDate] = useState(null); // 시작 날짜
    const [endDate, setEndDate] = useState(null); // 종료 날짜



    const csrfToken = Cookies.get('XSRF-TOKEN');
    const dataSendPage = async (page = 1) => {
        let endPoit = `/api/m/applist?AppNo=${AppNo}&ps=${page}&pl=${pageSize}`;
        try {
            const res = await api.get(endPoit, { withCredentials: true });

            setDataInfo(res.data.appInfo);

            setTableData(res.data.item);
            setListCount(res.data.pages.Total);
            setTotalPages(Math.ceil(res.data.pages.Total / pageSize));

            const initialStatuses = {};
            res.data.item.forEach(row => {
                initialStatuses[row.JointNo] = row.Status; // JointNo를 키로 사용하여 초기 상태 설정
            });
            setSubmitStatuses(initialStatuses);
        } catch (error) {
            console.error('API 에러를 확인해주세요.', error);
        }
    };


    useEffect(() => {
        dataSendPage(currentPage);
    }, [currentPage]);

    useEffect(async () => {
        try {
            // API 호출
            const response = await api.get(`/api/m/termsfiles`, {
                params: { AppNo }, // Query parameter로 AppNo 전달
                withCredentials: true, // 인증 쿠키를 전달하는 경우 필요
            });

            if (response.status === 200) {
                setFileData(response.data.appFiles);
                return response.data; // 데이터를 반환
            } else {
                throw new Error(`Failed to fetch terms files: ${response.status}`);
            }
        } catch (error) {
            console.error("Error fetching terms files:", error);
            alert("데이터를 가져오는 중 오류가 발생했습니다. 다시 시도해주세요.");
            return null;
        }
    }, []);


    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        setIsAllChecked(isChecked);
        const newCheckedItems = {};
        tableData.forEach(row => {
            newCheckedItems[row.JointNo] = isChecked;
        });
        setCheckedItems(newCheckedItems);
    };

    const handleCheckboxChange = (event, jointNo) => {
        const isChecked = event.target.checked;
        setCheckedItems(prevState => ({
            ...prevState,
            [jointNo]: isChecked,
        }));
    };

    const handleStatusChange = (e, jointNo) => {
        const newStatus = e.target.value;
        setSubmitStatuses(prevStatuses => ({
            ...prevStatuses,
            [jointNo]: newStatus,  // JointNo를 사용하여 상태 업데이트
        }));
    };

    const handleReturnChange = async () => {

        if(tableData.length === 0) {
            alert("현재 데이터가 없습니다.");
            return
        }

        const confirmReturn = window.confirm(
            "신청자의 정보를 모드 반려 하시겠습니까?"
        );


        if(confirmReturn) {
            try {
                const status = "3";  // 선택된 첫 항목의 상태를 기준으로 설정
                const response = await api.patch(`/api/m/allappstatus/${AppNo}/${status}`, null, { withCredentials: true,
                    headers: {
                        'X-XSRF-TOKEN': csrfToken,
                    }
                });
                alert("상태가 성공적으로 일괄 업데이트되었습니다.");

                await dataSendPage(currentPage);

                setCheckedItems({});
                setIsAllChecked(false);
                window.location.reload()
            } catch (err) {
                console.error("일괄 상태 변경 에러:", err);
            }
        }

    };

    const handleApprovalChange = async () => {
        if(tableData.length === 0) {
            alert("현재 데이터가 없습니다.");
            return
        }

        const confirmApproval = window.confirm(
            "신청자의 정보를 모드 승인 하시겠습니까?"
        );




        try {

            if(confirmApproval) {
                const status = "2";  // 선택된 첫 항목의 상태를 기준으로 설정
                const response = await api.patch(`/api/m/allappstatus/${AppNo}/${status}`, null, { withCredentials: true,
                    headers: {
                        'X-XSRF-TOKEN': csrfToken,
                    }
                });
                alert("상태가 성공적으로 일괄 업데이트 되었습니다.");

                await dataSendPage(currentPage);

                setCheckedItems({});
                setIsAllChecked(false);
                window.location.reload()
            }
        } catch (err) {
            console.error("일괄 상태 변경 에러:", err);
        }
    };


    const handleStartDateChange = (date) => {
        setStartDate(date); // 선택된 시작 날짜 저장
    };

    const handleEndDateChange = (date) => {
        setEndDate(date); // 선택된 종료 날짜 저장
    };

    const handleCheckbtn = async () => {
        if(tableData.length === 0) {
            alert("현재 데이터가 없습니다.");
            return
        }
        const selectedIds = Object.keys(checkedItems).filter(jointNo => checkedItems[jointNo]);

        if (selectedIds.length === 0) {
            alert("개별 상태 변경할 항목을 선택해주세요.");
            return;
        }



        try {

            const st = startDate
                ? startDate.toISOString().split('T')[0] // 사용자가 시작일자를 변경한 경우
                : null; // 기본값으로 유지

            const ed = endDate
                ? endDate.toISOString().split('T')[0] // 사용자가 마감일자를 변경한 경우
                : null; // 기본값으로 유지

            await Promise.all(selectedIds.map(async (jointNo) => {
                // const status = submitStatuses[jointNo] || "1";  // JointNo에 맞는 상태 가져오기

                const status = submitStatuses[jointNo];  // JointNo에 맞는 상태 가져오기
                const response = await api.patch(`/api/m/appstatus/${jointNo}/${status}/${st}/${ed}`, null, { withCredentials: true,
                    headers: {
                        'X-XSRF-TOKEN': csrfToken,
                    }
                });
            }));
            alert("개별 상태가 성공적으로 업데이트 되었습니다.");

            await dataSendPage(currentPage);

            setCheckedItems({});
        } catch (err) {
            console.error("개별 상태 변경 에러:", err);
        }
    };

    const applicationRemove = async () => {

        if(tableData.length === 0) {
            alert("현재 데이터가 없습니다.");
            return
        }

        const selectedIds = Object.keys(checkedItems).filter(jointNo => checkedItems[jointNo]);

        if (selectedIds.length === 0) {
            alert("삭제 할 데이터를 체크해주세요.");
            return;
        }

        const appno = AppNo;

        if (!AppNo) {
            alert("삭제할 신청서 ID가 유효하지 않습니다.");
            return;
        }

        // 사용자 확인 메시지
        const confirmDelete = window.confirm(
            "신청서를 삭제하게 되면, 그 안에 신청자도 함께 삭제됩니다. 삭제 하시겠습니까?"
        );

        if (confirmDelete) {
            try {
                const response = await api.delete(`/api/my/delapp/${Number(appno)}`, {
                    headers: {
                        'X-XSRF-TOKEN': csrfToken, // CSRF 토큰 헤더에 추가
                    },
                    withCredentials: true, // 쿠키 사용 시 필요
                });
                alert(response.data); // 성공 메시지
                navigate(`/adminPage/applicationManagement`);
            } catch (error) {
                if (error.response && error.response.data) {
                    // 서버에서 반환된 오류 메시지 표시
                    alert(`삭제 실패: ${error.response.data}`);
                } else {
                    // 기타 오류 처리
                    alert("삭제 중 문제가 발생했습니다. 다시 시도해주세요.");
                }
            }
        } else {
            // 취소를 눌렀을 경우
            alert("삭제가 취소되었습니다.");
        }
    };

    const jointUsersRemove = async (jointno) => {

        const confirmDelete = window.confirm(
            "해당 신청자를 삭제 하시겠습니까?"
        );

        if (confirmDelete) {
            try {
                const response = await api.delete(`/api/my/delappuser/${Number(jointno)}`, {
                    headers: {
                        'X-XSRF-TOKEN': csrfToken, // CSRF 토큰 헤더에 추가
                    },
                    withCredentials: true, // 쿠키 사용 시 필요
                });
                alert(response.data); // 성공 메시지
                navigate(`/adminPage/applicationManagement`);
            } catch (error) {
                if (error.response && error.response.data) {
                    // 서버에서 반환된 오류 메시지 표시
                    alert(`삭제 실패: ${error.response.data}`);
                } else {
                    // 기타 오류 처리
                    alert("삭제 중 문제가 발생했습니다. 다시 시도해주세요.");
                }
            }
        } else {
            // 취소를 눌렀을 경우
            alert("삭제가 취소되었습니다.");
        }
    }

    const kfriFileDown = async (fn, originName, dir, fileSeqNumber) => {
        try {
            // API 호출
            const response = await api.get(`/api/file/downloadFile`, {
                params: {
                    fn: fn,
                    originName: originName,
                    dir: dir,
                    FileSeqNo: fileSeqNumber
                },
                withCredentials: true,
                responseType: 'blob', // Blob으로 응답 받음
            });

            // 파일 다운로드 성공 처리
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', originName); // originName을 파일 이름으로 설정
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // 다운로드 완료 후 링크 제거

            alert('다운로드 성공');
        } catch (err) {
            console.error('파일 다운로드 실패:', err);
            alert('파일을 다운로드할 수 없습니다.');
        }
    }


    const boxCheckRemove = async (termsno) => {

        const confirmDelete = window.confirm(
            "서약서를 삭제하시겠습니까?"
        );

        if (confirmDelete) {
            try {
                const response = await api.delete(`/api/my/delapptermsfile/${Number(termsno)}`, {
                    headers: {
                        'X-XSRF-TOKEN': csrfToken, // CSRF 토큰 헤더에 추가
                    },
                    withCredentials: true, // 쿠키 사용 시 필요
                });
                alert(response.data); // 성공 메시지
                navigate(`/adminPage/applicationManagement`);
            } catch (error) {
                if (error.response && error.response.data) {
                    // 서버에서 반환된 오류 메시지 표시
                    alert(`삭제 실패: ${error.response.data}`);
                } else {
                    // 기타 오류 처리
                    alert("삭제 중 문제가 발생했습니다. 다시 시도해주세요.");
                }
            }
        } else {
            // 취소를 눌렀을 경우
            alert("삭제가 취소되었습니다.");
        }
    }

    return (
        <>
            <div className="adminpage-container">

                <div>
                    <div className="year-name">
                        <div className="submin-container">
                            <div className="Print-Container">
                                <h3>신청서 관리 상세페이지(신청자 정보)</h3>
                            </div>

                            <div className="title-line03"></div>
                            <h4>신청서(공동이용자)</h4>
                            <div className="auth-container">
                            <textarea
                                placeholder="권한 정보 리스트"
                                value={dataInfo.JointUsers}
                                readOnly
                                style={{ width: '100%', height: '52px' }}
                            />
                            </div>
                            <h4>이용계획</h4>
                            <div className="auth-container">
                            <textarea
                                placeholder="권한 정보 리스트"
                                value={dataInfo.UsagePlan}
                                readOnly
                                style={{ width: '100%', height: '52px' }}
                            />
                            </div>

                            <div className="title-line04"></div>



                            <div className="title-line05"></div>

                            <div className="application-mngDetail">
                                <div>총 {listCount}건</div>
                                {/*<div className="application-status-container">*/}
                                {/*    <button className="application-status" onClick={ applicationRemove}>*/}
                                {/*        신청서 삭제*/}
                                {/*    </button>*/}
                                {/*    <button className="application-status" onClick={handleCheckbtn}>*/}
                                {/*        개별 상태 변경*/}
                                {/*    </button>*/}
                                {/*    <button className="dataStatus-allChange02" onClick={handleApprovalChange}>*/}
                                {/*        일괄 승인*/}
                                {/*    </button>*/}
                                {/*    <button className="dataStatus-allChange" onClick={handleReturnChange}>*/}
                                {/*        일괄 반려*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="title-line02"></div>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                            <tr className="dmplist-title">

                                <th>DMP정보</th>
                                <th>데이터세트</th>
                                <th>연책/이메일/전화번호</th>
                                <th>시작 ~ 마감</th>
                                <th>상태</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tableData?.map((row, index) => (
                                <tr key={row.JointNo} className="dmplist-Data">
                                    <td className="data-cell-width">[{row.ProjNo}]<br/>{row.ProjNm}</td>

                                    <td className="data-cell-width">[{row.DtaSetNm}]<br/>{row.DtaNm}</td>
                                    <td className="data-cell-width">[{row.RspnEmpNm}]<br/>{row.Email}<br/>{row.PhoneNumber}</td>
                                    <td>
                                        <DatePicker
                                            selected={startDate || row.StartDt} // 현재 선택된 시작 날짜
                                            onChange={handleStartDateChange} // 날짜 선택 시 호출되는 함수
                                            dateFormat="yyyy-MM-dd"
                                            style={{ border: '1px solid #ccc', padding: '5px', borderRadius: '4px' }} // 기본 스타일 추가
                                        />~
                                        <DatePicker
                                            selected={endDate || row.EndDt} // 현재 선택된 종료 날짜
                                            onChange={handleEndDateChange} // 날짜 선택 시 호출되는 함수
                                            dateFormat="yyyy-MM-dd"
                                            style={{ border: '1px solid #ccc', padding: '5px', borderRadius: '4px' }} // 기본 스타일 추가
                                        />
                                    </td>
                                    <td>
                                        <select
                                            style={{ height: '37px', fontSize: '13px' }}
                                            value={submitStatuses[row.JointNo] || row.Status}
                                            onChange={(e) => handleStatusChange(e, row.JointNo)}
                                        >
                                            <option value="1">신청</option>
                                            <option value="2">승인</option>
                                            <option value="3">반려</option>
                                        </select>
                                    </td>

                                </tr>
                            ))}
                            </tbody>
                        </table>

                        <div className="title-line02"></div>

                        <h3>신청서 (서약서 정보)</h3>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                            <tr className="dmplist-title">
                                <th>TERMSNO</th>
                                <th>파일명</th>
                                <th>파일경로</th>
                                <th>다운로드</th>
                                <th>삭제</th>
                            </tr>
                            </thead>
                            <tbody>
                            {fileData?.map((row, index) => (
                                <tr key={row.JointNo} className="dmplist-Data">

                                    <td>{row.TermsNo}</td>
                                    <td>{row.OriginNm}</td>
                                    <td>{row.FilePath}</td>
                                    <td>
                                        <button className="kfri-button"  onClick={() => kfriFileDown(row.SaveNm, row.OriginNm, row.FilePath, row.TermsNo)}>
                                            다운로드
                                        </button>
                                    </td>
                                    <td>
                                        <button className="blue-button" onClick={() => boxCheckRemove(row.TermsNo)}>
                                            삭제
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ApplicationManagementPrint;
