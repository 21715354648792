// src/homePage.js
import React, {useEffect, useState} from 'react';
import 'tui-grid/dist/tui-grid.css';
import './home.css'
import api from '../api';
import {Link, useNavigate} from "react-router-dom";
import {useSearchHomeStore} from "../stores/dataSetsStore";

function HomePage() {
    const [dataValue, setdateValue] = useState({})
    // const [searchData, setSearchData] = useState("");

    const setSearchData = useSearchHomeStore((state) => state.setSearchHomeData);
    const searchData = useSearchHomeStore((state) => state.searchHomeData);

    const resetSearchData = useSearchHomeStore((state) => state.resetSearchHomeData);

    const navigate = useNavigate();

    const images = [
        `${process.env.PUBLIC_URL}/final-home01.jpg`,
        `${process.env.PUBLIC_URL}/final-home02.jpg`,
        `${process.env.PUBLIC_URL}/final-home03.jpg`,
        `${process.env.PUBLIC_URL}/final-home04.jpg`,
        `${process.env.PUBLIC_URL}/final-home05.jpg`,
    ]

    const [currentIndex, setCurrentIndex] = useState(0);


    useEffect(() => {
        // API 호출하여 데이터를 불러옴
        api.get('/api/dmp/count').then((res)=> setdateValue(res.data))
            .catch((error) => console.error("home api를 확인해보세요.", error));

        // 슬라이더 이미지 변경
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 4000);

        // 검색어 초기화
        resetSearchData();

        // 컴포넌트 언마운트 시 인터벌 정리
        return () => clearInterval(interval);
    }, [resetSearchData, images.length]);  // 의존성 배열에 `resetSearchData`를 추가

    const handlerHomeChange = (e) => {
        setSearchData(e.target.value);
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            navigate("/dmpRealData/dmpRealDataList")
        }
    }

    const handleSearchClick = () => {
        navigate("/dmpRealData/dmpRealDataList")
    }

    const moveDmpListHandler = () => {
        navigate("/dmpManagement/dmpList")
    }

    const moveDataListHandler = () => {
        navigate("/dmpRealData/dmpRealDataList")
    }

    const moveOpenDataListHandler = () => {
        navigate("/openData")
    }

    return (
        <div className="App">
            <section className="main-img">
                <div className="slider-container">
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Slide ${index + 1}`}
                            className={`slider-image ${index === currentIndex ? 'active' : ''}`}
                        />
                    ))}
                        <div className="title-container">
                            <div className="main-title">
                                <h1>Welcome to</h1>
                                <h2>KFRI Food Data Platform</h2>
                            </div>
                            <div className="main-input-box">
                                <input
                                    type="text"
                                    placeholder="찾으시는 데이터를 입력해주세요."
                                    value={searchData}
                                    onChange={handlerHomeChange}
                                    onKeyPress={handleKeyPress} // 엔터키 이벤트 처리
                                />
                                <img
                                    src={`${process.env.PUBLIC_URL}/icon_search.png`}
                                    alt="search Logo"
                                    onClick={handleSearchClick} // 돋보기 아이콘 클릭 이벤트 처리
                                    style={{ cursor: 'pointer' }} // 커서 스타일을 포인터로 변경하여 클릭 가능하게 보이도록 설정
                                />
                            </div>
                            <div className="mainDataNumber">
                                <div className="registerDMP" onClick={moveDmpListHandler}>
                                    <span>{dataValue.count?.CntDmp}</span>
                                    <h4>등록된 DMP </h4>
                                </div>
                                <div className="registerData" onClick={moveDataListHandler}>
                                    <span>{dataValue.count?.CntData}</span>
                                    <h4>등록된 데이터 </h4>
                                </div>
                                <div className="openDMP" onClick={moveOpenDataListHandler}>
                                    <span>{dataValue.count?.CntOpenData}</span>
                                    <h4>공개된 데이터 </h4>
                                </div>
                                {/*<div className="archive-header">*/}
                                {/*    <div className="archive-move">*/}
                                {/*            <h2>식품정보 아카이브 바로가기 </h2>*/}
                                {/*        </Link>*/}
                                {/*    </div>*/}
                                {/*    <div className="archive-move">*/}
                                {/*        <Link to={`http://211.57.34.155/`}>*/}
                                {/*            <h2>데이터 클라우드 바로가기</h2>*/}
                                {/*        </Link>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                            <div className="home-archive-title">
                                <div className="home-archive-title02">
                                    <a
                                        href="http://211.57.34.155:8080/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src={`${process.env.PUBLIC_URL}/archive-info-btn.png`}
                                            alt="search Logo"
                                            style={{ cursor: 'pointer' }} // 커서 스타일을 포인터로 변경하여 클릭 가능하게 보이도록 설정
                                        />
                                    </a>
                                </div>
                                <div className="home-archive-title02">
                                    <a
                                        href="http://211.57.34.155/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src={`${process.env.PUBLIC_URL}/data-cloud-btn.png`}
                                            alt="search Logo"
                                            style={{ cursor: 'pointer' }} // 커서 스타일을 포인터로 변경하여 클릭 가능하게 보이도록 설정
                                        />
                                    </a>
                                </div>

                            </div>
                        </div>
                </div>
            </section>
            <div className="footer-silder">
                <Link to="https://www.kfri.re.kr/">
                    <img
                        src={`${process.env.PUBLIC_URL}/footerlogo-01.png`}
                        alt="search Logo"
                        style={{ cursor: 'pointer' }}
                    />
                </Link>
                <Link to= "https://dataon.kisti.re.kr/">
                    <img
                        src={`${process.env.PUBLIC_URL}/footer-logo03.png`}
                        alt="search Logo"
                        style={{ cursor: 'pointer', width: '130px' }}
                    />
                </Link>
                <img
                    src={`${process.env.PUBLIC_URL}/footer-logo02.png`}
                    alt="search Logo"
                    style={{ cursor: 'pointer' }}
                />
                <div>
                    천연물 소재은행
                </div>

                <div className="home-line"></div>
                <img
                    className="menual-title"
                    src={`${process.env.PUBLIC_URL}/manual-img.png`}
                    alt="search Logo"
                    style={{ cursor: 'pointer' }}
                />

            </div>
        </div>
    )
}

export default HomePage;
