import React, {useEffect, useRef, useState} from "react";
import Grid from "@toast-ui/react-grid";
import 'tui-grid/dist/tui-grid.css';
import {usePublicDataStore} from "../stores/lastDmpData";
import {useNewDmpPublicPlan} from "../stores/newWriteDmp";

function DataPublicGrid({updateProductionPn, DmpPublicHisNo, proplanDataTypes}) {

    const gridRef = useRef(null);
    const { setNewPublicPlandataSets } = useNewDmpPublicPlan();

    const [openData, setOpenData] = useState([]);



    //업데이트 전용 값
    // const {setPublicDataSets} = usePublicDataStore();

    useEffect(() => {
        if(updateProductionPn && updateProductionPn.Open) {
            const addList = updateProductionPn.Open.map(item => {
                return{
                    DmpNo:item.DmpNo,
                    DmpOpNo:item.DmpOpNo,
                    DmpPrdNo:item.DmpPrdNo,
                    dataType:item.DtaNm,
                    publictime:item.OpnTm,
                    externalTime:item.OpnPd,
                    // publicmethod:item.OpnMth,
                    // individualplan:item.OpnResn,
                    // individualmethod:item.OpnOpnMth,
                    InsDt: item.InsDt,
                    UpdDt: item.UpdDt,
                    delete:'삭제',
                }
            })
            setOpenData(addList);
        }
        else {
            setOpenData([]); // 또는 초기값을 설정
        }
    }, [updateProductionPn]);


    const columns = [
        {
            header: '데이터타입명',
            name: 'dataType',
            editor: DmpPublicHisNo ? false : {
                type: 'select',
                options: {
                    listItems: Array.isArray(proplanDataTypes)  // proplanDataTypes가 배열인지 확인
                        ? proplanDataTypes.map(type =>
                            typeof type === 'object' && type.DtaNm
                                ? { text: type.DtaNm, value: type.DtaNm }  // 객체일 때 처리
                                : { text: type, value: type }  // 문자열일 때 처리
                        )
                        : Array.isArray(proplanDataTypes?.Summary)
                            ? proplanDataTypes.Summary.map(type => ({
                                text: type.DtaNm || type,  // 객체일 경우 DtaNm 속성을 사용
                                value: type.DtaNm || type  // 객체일 경우 DtaNm 속성을 사용
                            }))
                            : []
                }

                // options: {
                //     listItems: [
                //         { text: 'DATABASE_MI', value: 'DATABASE_MI' },
                //         { text: 'PROJECT', value: 'PROJECT' },
                //         { text: 'PROJECT_FILE', value: 'PROJECT_FILE' },
                //         { text: 'NORMALIZATION', value: 'NORMALIZATION' }
                //     ]
                // }
            },
            width: 362,
        },
        {
            header: '원내 공개 시점',
            name: 'publictime',
            editor: DmpPublicHisNo ? false : 'text',
            width: 255
        },
        {
            header: '원외 공개 시점',
            name: 'externalTime',
            editor: DmpPublicHisNo ? false : 'text',
            width: 255
        },
        // {
        //     header: '공개방법',
        //     name: 'publicmethod',
        //     editor: DmpPublicHisNo ? false : {
        //         type: 'select',
        //         options: {
        //             listItems: [
        //                 { text: 'DMP 플랫폼', value: 'DMP 플랫폼' },
        //                 { text: '개별관리', value: '개별관리' }
        //             ]
        //         }
        //     },
        //     lineHeight: '1.5',
        //     width: 120,
        // },
        // {
        //     header: '개발공개 계획',
        //     name: 'individualplan',
        //     editor: DmpPublicHisNo ? false : 'text',
        //     lineHeight: '1.5',
        //     width: 220,
        // },
        // {
        //     header: '개별공개 방법',
        //     name: 'individualmethod',
        //     editor: DmpPublicHisNo ? false : 'text',
        //     lineHeight: '1.5',
        //     width: 220,
        //
        // },
        {
            header: '행 삭제',
            name: 'delete',
            formatter: ({ row }) => DmpPublicHisNo
                ? `<span></span>`
                : `<button class="btn-delete" data-row-key="${row.rowKey}">Delete</button>`,
            width: 100,
        }

    ];

    const handleAddRow = () => {
        const newRow = {
            id: Date.now(), // Using current timestamp as a unique ID
            dataType: '-',
            publictime: '-',
            externalTime: '-',
            // publicmethod: '-',
            // individualplan: '',
            // individualmethod: '',
        };
        setOpenData(prevList => [...prevList, newRow]);
    };

    const handleDeleteRow = (rowKey) => {
        const parsedRowKey = parseInt(rowKey, 10);
        if (isNaN(parsedRowKey)) {
            console.error("Invalid rowKey:", rowKey);
            return;
        }

        const resetRowKeys = (data) => data.map((row, index) => ({ ...row, rowKey: index }));

        const updatedList = resetRowKeys(openData.filter(row => row.rowKey !== parsedRowKey));

        setOpenData(updatedList);


        // 모든 행이 삭제되었을 때 빈 배열 반환
        if (updatedList.length === 0) {
            return [];
        }
        return updatedList;
    };

    useEffect(() => {
        const grid = gridRef.current.getInstance();

        if(!grid) return;

        grid.on('click', (ev) => {
            if(ev.targetType === 'emptySpace') {
                ev.stop();
                return;
            }

            if (ev.targetType === 'cell' && ev.nativeEvent.target.className.includes('btn-delete')) {
                const rowKey = ev.nativeEvent.target.getAttribute('data-row-key');
                handleDeleteRow(rowKey);
            }
        });


        grid.on('keydown', (ev) => {
            const focusedCell = grid.getFocusedCell();
            if (ev.targetType === 'emptySpace') {
                ev.stop();
                return;
            }
            // focusedCell이 없으면 이벤트 중단
            if (!focusedCell) {
                console.warn('No focused cell. Ignoring keydown event.');
                ev.stop(); // Grid 기본 동작 중단
                const nativeEvent = ev.nativeEvent;
                if (nativeEvent && typeof nativeEvent.preventDefault === 'function') {
                    nativeEvent.preventDefault();
                }
                return;
            }

            // editor가 없는 셀도 동작 중단
            if (!columns.find((col) => col.name === focusedCell.columnName)?.editor) {
                console.warn('Focused cell has no editor. Ignoring keydown event.');
                ev.stop();
                const nativeEvent = ev.nativeEvent;
                if (nativeEvent && typeof nativeEvent.preventDefault === 'function') {
                    nativeEvent.preventDefault();
                }
            }
        });

        // 데이터가 존재하면 상태 업데이트, 없으면 빈 배열로 처리
        if (Array.isArray(openData)) {
            setTimeout(() => {
                setNewPublicPlandataSets(openData.length > 0 ? openData : []);  // 상태 업데이트
            }, 10);  // 비동기 처리로 타이밍 조정
        }

    }, [openData, setNewPublicPlandataSets]);

    useEffect(() => {
        const grid = gridRef.current.getInstance();

        grid.on('editingStart', (ev) => {
            const { columnName } = ev;
            if (columnName === 'publictime' || columnName === 'externalTime') {
                setTimeout(() => {
                    const input = document.querySelector('.tui-grid-layer-editing input');
                    if (input) {
                        input.setAttribute('placeholder', 'YYYY-MM-DD');
                    }
                });
            }
        });
    }, []);




    const handleAfterChange = (ev) => {
        const datePattern = /^\d{4}-\d{2}-\d{2}$/;

        if (ev && ev.changes && ev.changes.length > 0) {
            const updatedData = [...openData];

            ev.changes.forEach(({ rowKey, columnName, value }) => {
                if (columnName === 'publictime' || columnName === 'externalTime') {
                    if (!datePattern.test(value)) {
                        alert("날짜 형식이 올바르지 않습니다. YYYY-MM-DD 형식으로 입력해 주세요.");
                        return;
                    }
                }
                if (updatedData[rowKey]) {
                    updatedData[rowKey] = { ...updatedData[rowKey], [columnName]: value };
                }
            });

            setOpenData(updatedData);
        }
    };

    return (
        <div className="toast-container">
            { (!DmpPublicHisNo) && (
                <div className="row-btn">
                    <button className="public-data-addrow" type="button" onClick={handleAddRow}>
                        행추가
                    </button>
                </div>
            )}

            <Grid
                data={openData}
                ref={gridRef}
                rowHeight={'auto'}
                bodyHeight={300}
                columns={columns}
                rowHeaders={['rowNum']}
                editable={true}  // 셀 편집 가능
                copyOptions={{ useFormattedValue: true }}  // 복사-붙여넣기 가능하게 설정
                onAfterChange={handleAfterChange}
            />
        </div>
    );
}

export default DataPublicGrid;
