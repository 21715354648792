import {create} from "zustand";

// 데이터 관리 계획에 첫 dmp 작성 시 연구데이터 공개 계획
const useNewPublicSetStore = create(set => ({
    newPublicdataSets: [],
    setNewPublicdataSets: (newDmpDataSets) => set({ newPublicdataSets: newDmpDataSets }),
}));


const useNewDmpProductionPlan = create((set) => ({
    newProductionPlandataSets: [],
    setNewProductionPlandataSets: (newValues) => set(() => ({
        newProductionPlandataSets: newValues  // 새로운 cellValues로 상태 업데이트
    })),
    // 상태 초기화 함수
    resetProductionPlandataSets: () => set({
        newProductionPlandataSets: []  // 상태 초기화 (빈 배열로 설정)
    }),
}));

//new 연구데이터 관리 계획
const useNewDmpManagementPlan  = create(set => ({
    newManagementPlandataSets: [],
    setNewManagementPlandataSets: (newValues) => set(() => ({
        newManagementPlandataSets: newValues  // 새로운 cellValues로 상태 업데이트
    })),
}));

const useNewDmpPublicPlan  = create(set => ({
    newPublicPlandataSets: [],
    setNewPublicPlandataSets: (newValues) => set(() => ({
        newPublicPlandataSets: newValues  // 새로운 cellValues로 상태 업데이트
    })),
}));

//시료데이터
const useNewDmpToastData  = create(set => ({
    newDmpToastDataSets: [],
    addnewDmpToastDataSets: (newDataSet) => set(state => ({
        newDmpToastDataSets: [...state.newDmpToastDataSets, newDataSet]
    })),
    updateNewDmpToastDataSets: (id, updatedDataSet) => set(state => ({
        newDmpToastDataSets: state.newDmpToastDataSets.map(dataSet =>
            dataSet.id === id ? { ...dataSet, ...updatedDataSet } : dataSet
        )
    })),
}));

const useDmpStore = create(set => ({
    sampleData: [],
    methodData: [],
    resultData: [],
    setSampleData: (newData) => set(state => ({ sampleData: newData })),
    setMethodData: (newData) => set(state => ({ methodData: newData })),
    setResultData: (newData) => set(state => ({ resultData: newData })),
}));


export {useNewPublicSetStore, useDmpStore, useNewDmpProductionPlan, useNewDmpManagementPlan, useNewDmpPublicPlan, useNewDmpToastData}