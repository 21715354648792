import {create} from 'zustand';

// 첫 번째 Zustand 스토어 생성
const useMemosStore = create(set => ({
    memos: {
        rsrcAnlyPlan: '',
    },
    setMemos: (newMemos) => set({ memos: newMemos }),
    updateMemo: (newMemo) => set(state => ({
        memos: {
            ...state.memos,
            rsrcAnlyPlan: newMemo
        }
    })),
}));

// 두 번째 Zustand 스토어 생성
const useMemoSetStore = create(set => ({
    dmpdataSets: [],
    setDmpDataSets: (newDmpDataSets) => set({ dmpdataSets: newDmpDataSets }),
}));

const useToastSetStore = create(set => ({
    toastdataSets: [],
    setToastDataSets: (newToastDataSets) => set(state => ({
        toastdataSets: newToastDataSets
    })),
    resetToastDataSets: () => set({
        toastdataSets: []  // 상태 초기화 (빈 배열로 설정)
    })
}));

const useToastSetMethodStore = create(set => ({
    toastMethoddataSets: [],
    setToastMethodDataSets: (newToastDataSets) => set(state => ({
        toastMethoddataSets: newToastDataSets
    }))
}));

const useToastSetResultStore = create(set => ({
    toastResultdataSets: [],
    setToastResultDataSets: (newToastDataSets) => set({
        toastResultdataSets: newToastDataSets // 덮어쓰기 방식으로 변경
    })
}));

// 과제 선택 전역 관리
const useTaskDataStore = create(set =>({
    taskdataSets: [],
    setTaskDataSets: (newTaskDataSets) => set({
        taskdataSets : newTaskDataSets
    })
}))

// 공개 데이터 전역 관리
const usePublicDataStore = create(set => ({
    publicdataSets: [],
    setPublicDataSets: (newPublicDataSets) => set({
        publicdataSets: newPublicDataSets
    }),
    resetPublicCellStoreSets: () =>
        set({
            publicCellStoreSets: [],
        }),
}));

//관리 계획 데이터

const useManagementDataStore = create(set => ({
    managementdataSets: [],
    setManagementDataSets: (newManagementDataSets) => set({
        managementdataSets: newManagementDataSets
    })
}));

//새로운 last데이터 불러오기
const useLastDataStore = create(set => ({
    lastdataSets: [],
    setLastDataSets: (newLastDataSets) => set({
        lastdataSets: newLastDataSets
    })
}));

//데이터 부분 등록 DMP 불러오기

const useRegisterDataStore = create((set) => ({
    registerdataSets: [], // 초기 상태

    // 상태 업데이트 함수
    setRegisterDataSets: (newRegisterDataSets) => set({
        registerdataSets: newRegisterDataSets
    }),

    // 상태 초기화 함수
    resetRegisterDataSets: () => set({
        registerdataSets: []  // 상태 초기화 (빈 배열로 설정)
    })
}));







// dmp 데이터 세트 불러오기
const useImportDataSetsStore = create(set => ({
    importdataSets: [],
    setImportDataSets: (newImportDataSets) => set({
        importdataSets: newImportDataSets
    })
}))

const useProductionDataSetsStore = create(set => ({
    productiondataSets: [],
    setProductionDataSets: (newProductionDataSets) => set({
        productiondataSets: newProductionDataSets
    })
}))

//toast UI의 셀 위치를 확인해 값을 가져오기

const useToastUICellStore = create((set) => ({
    toastUICellStoreSets: [],
    setToastUICellStoreSets: (newEntry) =>
        set((state) => ({
            toastUICellStoreSets: [...state.toastUICellStoreSets, newEntry],
        })),
    resetToastUICellStoreSets: () =>
        set({
            toastUICellStoreSets: [],
        }),
}));
//생산 관리 계획에 전역 변수 관리(타입 지정용)
const useProductionCellStore = create((set) => ({
    productionCellStoreSets: [],
    setProductionCellStoreSets: (newEntry) =>
        set((state) => ({
            productionCellStoreSets: [...state.productionCellStoreSets, newEntry],
        })),
    resetProductionCellStoreSets: () =>
        set({
            productionCellStoreSets: [],
        }),
}));

//연구데이터 관리 계획에 전역 변수 관리(타입 지정용)
const useManagementCellStore = create((set) => ({
    managementCellStoreSets: [],
    setManagementCellStoreSets: (newEntry) =>
        set((state) => ({
            managementCellStoreSets: [...state.managementCellStoreSets, newEntry],
        })),
    resetManagementCellStoreSets: () =>
        set({
            managementCellStoreSets: [],
        }),
}));

//연구데이터 관리 계획에 전역 변수 관리(타입 지정용)
const usePublicCellStore = create((set) => ({
    publicCellStoreSets: [],
    setPublicCellStoreSets: (newEntry) =>
        set((state) => ({
            publicCellStoreSets: [...state.publicCellStoreSets, newEntry],
        })),
    resetPublicCellStoreSets: () =>
        set({
            publicCellStoreSets: [],
        }),
}));

// 각각의 스토어를 내보내기
export { useMemosStore, useMemoSetStore, useToastSetMethodStore, useToastSetResultStore, useToastSetStore, useTaskDataStore , usePublicDataStore, useManagementDataStore
    ,useRegisterDataStore, useLastDataStore, useImportDataSetsStore, useProductionDataSetsStore , useToastUICellStore, useProductionCellStore, useManagementCellStore, usePublicCellStore};
