import './applicationPage.css'
import React, {useState, useEffect} from "react";
import api from "../api";
import Cookies from 'js-cookie';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ProgressModal from "../progressModal/progressModal";

function ApplicationPage({setShowPage, showPagess, selectedProjects,setSelectedProjects, cancelHandler, AppValue, myPagecancelHandler}) {
    const [currentDate, setCurrentDate] = useState("");
    const [topic, setTopic] = useState(""); // 성명
    // const [email, setEmail] = useState(""); // 이메일

    const [purpose, setPurpose] = useState(""); // 이용 목적
    const [usagePlan, setUsagePlan] = useState(""); // 이용 계획

    const [iointUsers, setJointUsers] = useState(""); // 공동이용자

    const [openFiles, setOpenFiles] = useState([]);

    const [openSendImgFile, setOpenSendImgFile] = useState([]);


    const [progress, setProgress] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);

    const [fileNames, setFileNames] = useState([]);
    const [userNo, setUserNo] = useState({});

    //마이페이지에서 데이터 보기
    const [myPageData ,setMyPageData] = useState({});
    const [myPagePurpose, setMyPagePurpose] = useState(''); // 선택된 목적의 상태 관리
    const today = new Date();

    // 상태를 관리할 변수들
    const [startDate, setStartDate] = useState(today);  // 시작 날짜
    const [endDate, setEndDate] = useState(null);  // 종료 날짜 (초기값 없음)




    const handleEndDateChange = (date) => {
        if (date < today) {
            alert('종료 날짜는 신청 날짜 이후여야 합니다.');
            return;  // 종료 날짜가 신청 날짜보다 이전이면 상태 업데이트하지 않음
        }
        const startformatedData = today.toISOString().split('T')[0]; // "2024-11-24" 형식으로 변환
        setStartDate(startformatedData);  // 시작일이 변경되면 상태 업데이트
        const formattedDate = date.toISOString().split('T')[0]; // "2024
        setEndDate(formattedDate);  // 유효한 종료 날짜일 경우 상태 업데이트
    };

    useEffect(() => {
        if (AppValue) {
            api.get(`/api/my/appinfo?AppNo=${AppValue}`, { withCredentials: true })
                .then((res) => {
                    setMyPageData(res.data);
                    setMyPagePurpose(res.data.appInfo?.Purpose);
                    setCurrentDate(res.data.appInfo.InsDt ? res.data.appInfo.InsDt.split(' ')[0] : '');

                });
        } else {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, "0");
            const day = String(today.getDate()).padStart(2, "0");
            setCurrentDate(`${year}-${month}-${day}`);

            api.get(`/api/auth/user`, { withCredentials: true }).then((res) => {
                // 상태를 업데이트하기 전에 현재 상태와 비교
                if (res.data !== userNo) {
                    setUserNo(res.data);
                }
            });
        }
    }, [AppValue]);

    // 입력 값 변경 처리 함수들
    const handlePurposeChange = (event) => setPurpose(event.target.value);

    const handleTopicChange = (e) => setTopic(e.target.value);
    // 서약서 신청
    const handleOpenDataFileChange = (event) => {
        // Flatten the file array
        const newFiles = Array.from(event.target.files); // 새로운 파일 목록

        setOpenFiles((prevFiles) => [
            ...prevFiles, // 이전 파일들
            ...newFiles,  // 새로운 파일들 추가
        ]);
    };

    const handleOpenUpload = async () => {
        if (openFiles.length === 0) {
            alert('서약서을 먼저 선택해주세요.');
            return;
        }

        setProgress(0); // 초기 진행률
        setIsCompleted(false); // 완료 상태 초기화
        setIsModalOpen(true); // 모달 열기



        const newFiles = openFiles.filter((file) =>
            !openSendImgFile.includes(file.name) // 파일 이름으로 업로드 여부 확인
        );

        if (newFiles.length === 0) {
            alert('같은 이름의 파일은 업로드가 불가능 합니다.');
            return;
        }

        const formData = new FormData();

        // 실제 파일 데이터를 FormData에 추가
        newFiles.forEach((file) => {
            formData.append('files', file);
        });
        formData.append('ct', "Terms");



        const csrfToken = Cookies.get('XSRF-TOKEN');

        try {
            const response = await api.post('/api/file/uploadfiles', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // 필요한 경우 추가 설정
                    'X-XSRF-TOKEN': csrfToken,
                },
                withCredentials: true,
                onUploadProgress: (progressEvent) => {
                    const total = progressEvent.total || 0;
                    const loaded = progressEvent.loaded || 0;
                    const percentage = Math.round((loaded / total) * 100);
                    setProgress(percentage); // 진행률 업데이트
                },
            });
            // setOpenSendImgFile(response.data);

            // 성공적으로 업로드된 파일 이름 추가

            setOpenSendImgFile((prev) => [...prev, ...response.data]);
            setIsCompleted(true); // 완료 상태 설정
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('파일이 업로드 되지 않았습니다.');
        }finally {
            setTimeout(() => {
                setIsModalOpen(false); // 모달 닫기
                setProgress(0); // 초기화
            }, 500); // 2초 후 모달 닫기
        }
    }

    // 상태 업데이트 후 로그를 확인하기 위해 useEffect 사용
    useEffect(() => {
    }, [openSendImgFile]);

    const openPreviewHandler = async (fn, originName, dir, fileSeqNumber) => {

        try {
            // API 호출
            const response = await api.get(`/api/file/downloadFile`, {
                params: {
                    fn: fn,
                    originName: originName,
                    dir: dir,
                    FileSeqNo: fileSeqNumber
                },
                withCredentials: true,
                responseType: 'blob', // Blob으로 응답 받음
            });

            // 파일 다운로드 성공 처리
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', originName); // originName을 파일 이름으로 설정
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // 다운로드 완료 후 링크 제거

            alert('다운로드 성공');
        } catch (err) {
            console.error('파일 다운로드 실패:', err);
            alert('파일을 다운로드할 수 없습니다.');
        }
    };

    const closeFile = async (indexToRemove) => {
        // 삭제할 파일의 정보를 가져옵니다.
        const fileToRemove = openFiles[indexToRemove];

        // 로컬 상태에서 파일 제거
        setOpenFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToRemove));

        // 업로드된 파일 목록에서도 제거
        const uploadedFileToRemove = openSendImgFile.find((uploadedFile) => uploadedFile === fileToRemove?.name);

        if (uploadedFileToRemove) {
            try {
                // 서버에 삭제 요청을 보냄
                const csrfToken = Cookies.get('XSRF-TOKEN');
                await api.post(
                    `/api/file/deleteFile`,
                    { SaveFile: uploadedFileToRemove },
                    {
                        headers: {
                            'X-XSRF-TOKEN': csrfToken,
                        },
                        withCredentials: true,
                    }
                );

                // 서버에서 삭제 성공 시 사용자에게 알림
                alert("업로드된 파일이 삭제되었습니다.");
                setOpenSendImgFile((prevFiles) => prevFiles.filter((file) => file !== uploadedFileToRemove));
            } catch (error) {
                console.error("파일 삭제 실패:", error);
                alert("파일 삭제에 실패했습니다. 다시 시도해주세요.");
            }
        } else {
            // 로컬에서만 삭제된 경우
            alert("파일이 삭제되었습니다.");
        }
    };


    // 신청 버튼 클릭 시 동적으로 입력 데이터를 applicationData에 반영하여 API 요청
    const applicationBtn = async () => {
        // 현재 날짜 가져오기

        // 유효성 검사
        if (!topic.trim()) {
            alert("주제를 입력해주세요.");
            return;
        }

        if (!purpose.trim()) {
            alert("이용 목적을 선택해주세요.");
            return;
        }

        if (!usagePlan.trim()) {
            alert("이용 계획을 입력해주세요.");
            return;
        }

        if (openSendImgFile.length === 0) {
            alert("서약서를 첨부해주세요.");
            return;
        }

        // 데이터 준비
        const jointUsers = selectedProjects.map((project) => ({
            DtaSbmSeqNo: project.DtaSbmSeqNo,
            Department: userNo.userDept,
            UserNo: userNo.userId,
            UserNm: userNo.userNm,
            Email: userNo.email,
            PhoneNumber: userNo.userTel,
            StartDt: startDate,
            EndDt: endDate,
            RespFlag: "Y",
            Status: 1,
        }));

        const uploadFilesData = openSendImgFile.map((item) => ({
            OriginNm: item.OriginalFileName,
            SaveNm: item.SaveFile,
            FilePath: item.FilePath,
            Size: item.Size,
        }));

        const applicationData = {
            DataApplication: {
                RespUserNo: userNo.userId,
                RespUserNm: userNo.userNm,
                Subject: topic,
                Purpose: purpose,
                JointUsers: iointUsers,
                UsagePlan: usagePlan,
                StartDt: startDate,
                EndDt: endDate,
            },
            JointUsers: jointUsers,
            TermsFile: uploadFilesData,
        };

        try {
            const csrfToken = Cookies.get('XSRF-TOKEN');

            const response = await api.post(`/api/open/application`, applicationData, {
                withCredentials: true,
                headers: {
                    'X-XSRF-TOKEN': csrfToken,
                },
            });
            console.log("신청 성공:", response.data);
            alert("신청이 완료되었습니다.");
            setShowPage(false);
            setSelectedProjects([]);
            window.location.reload();
        } catch (err) {
            console.error("신청 실패:", err);
            alert("신청에 실패했습니다. 신청서를 다시 한번 확인해주세요.");
        }
    };

    return (
            <div className="application-wrapper">
                <div className="form-container">
                    <h2>연구데이터 이용 신청서</h2>

                    <div className="section">
                        <div className="checkbox">
                            <h3 htmlFor="applicantInfo">※신청자 정보</h3>
                            <p>이용 날짜: {currentDate}</p>
                        </div>

                        <table className="applicant-info-table" border="0">
                            <tbody>
                            <tr>
                                <td rowSpan="3" className="main-title">이용책임자</td>
                                <td  className="main-title">소속</td>
                                <td>
                                    <input
                                        type="text"
                                        value={userNo?.userDept || myPageData?.appData?.[0]?.Department || ''}
                                    />
                                </td>
                                <td  className="main-title">사번</td>
                                <td>
                                    <input
                                        type="text"
                                        value={userNo?.userId || myPageData?.appData?.[0]?.UserNo || ''}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td  className="main-title">성명</td>
                                <td>
                                    <input
                                        type="text"
                                        value={userNo?.userNm || myPageData?.appData?.[0]?.UserNm || ''}
                                    />
                                </td>
                                <td  className="main-title">이메일</td>
                                <td>
                                    <input
                                        type="text"
                                        value={userNo?.email || myPageData?.appData?.[0]?.Email || ''}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td  className="main-title">전화번호</td>
                                <td>
                                    <input
                                        type="text"
                                        value={userNo?.userTel  || myPageData?.appData?.[0]?.PhoneNumber || ''}
                                    />
                                </td>

                            </tr>

                            </tbody>
                        </table>

                        <div className="notice">
                            ※ 연구데이터 이용자 전원 "연구데이터 이용 서약서" 확인 및 제출 필수
                        </div>
                    </div>



                    <div className="section">
                        <div className="checkbox">
                            <h3 htmlFor="applicationDetails">※신청사항</h3>
                        </div>

                        <div className="application-title02">
                            <div className= "application-title02-cont">주제</div>
                            <input type="text"
                                   style={{width : '100%'}} value={topic || myPageData?.appInfo?.Subject || ""}
                                   onChange={handleTopicChange}
                            />
                        </div>
                        <div className="application-underlien"></div>
                        <div className="purpose-title">
                            <div >이용목적</div>
                            <div>
                                <input
                                    type="radio"
                                    style={{width : '20px'}}
                                    name="purpose"
                                    value="연구기획"
                                    onChange={handlePurposeChange}
                                    checked= {myPagePurpose === "연구기획" || purpose === '연구기획'}
                                /> 연구기획
                            </div>
                            <div >
                                <input
                                    type="radio"
                                    name="purpose"
                                    style={{width : '20px'}}
                                    value="정책개발"
                                    onChange={handlePurposeChange}
                                    checked= {myPagePurpose === "정책개발"  || purpose === '정책개발'}
                                /> 정책개발
                            </div>
                            <div >
                                <input
                                    type="radio"
                                    name="purpose"
                                    style={{width : '20px'}}
                                    value="교육자료"
                                    onChange={handlePurposeChange}
                                    checked= {myPagePurpose === "교육자료"  || purpose === '교육자료'}
                                /> 교육자료
                            </div>
                            <div >
                                <input
                                    type="radio"
                                    name="purpose"
                                    style={{width : '20px'}}
                                    value="학술연구"
                                    onChange={handlePurposeChange}
                                    checked= {myPagePurpose === "학술연구"  || purpose === '학술연구'}
                                /> 학술연구
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    name="purpose"
                                    style={{width : '20px'}}
                                    value="논문/출판"
                                    onChange={handlePurposeChange}
                                    checked= {myPagePurpose === "논문/출판"  || purpose === '논문/출판'}
                                /> 논문/출판
                            </div>
                            <div >
                                <input
                                    type="radio"
                                    name="purpose"
                                    style={{width : '20px'}}
                                    value="기타"
                                    onChange={handlePurposeChange}
                                    checked= {myPagePurpose === "기타"  || purpose === '기타'}
                                />
                                기타
                                {/*(*/}
                                {/*<input type="text"  />*/}
                                {/*)*/}
                            </div>
                        </div>
                        <div className="application-underlien"></div>

                        <div className="period-days">
                            <div>이용기간</div>
                            <div className="period-dayes">
                                <div>
                                    <label>신청날짜 : </label>
                                    <DatePicker
                                        selected={today}  // 시작일을 오늘 날짜로 고정
                                        dateFormat="yyyy-MM-dd"  // 날짜 포맷
                                        style={{ width: "150px" }}
                                    />
                                </div>

                                ~

                                <div>
                                    <label>종료날짜 : </label>
                                    <DatePicker
                                        selected={endDate}  // 종료일 선택
                                        onChange={(date) => handleEndDateChange(date)}  // 종료일 변경 시 처리 함수
                                        dateFormat="yyyy-MM-dd"  // 날짜 포맷
                                        style={{ width: "150px" }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="application-underlien"></div>

                        <tr>
                            <td rowSpan="4" className="main-title" style={{ textAlign: 'center', verticalAlign: 'middle' }}>신청데이터</td>
                            <td style={{ textAlign: 'center' }}>종류</td>
                            <td style={{ textAlign: 'center' }}>명칭</td>
                            <td style={{ textAlign: 'center' }}>과제명/연구책임자</td>
                        </tr>

                        {
                            (myPageData?.appData?.length > 0) ? (
                                <>
                                    <tr>
                                        <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={myPageData.appData[0]?.DtaSetNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                        <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={myPageData.appData[0]?.DtaNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                        <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={myPageData.appData[0]?.ProjNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={myPageData.appData[1]?.DtaSetNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                        <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={myPageData.appData[1]?.DtaNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                        <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={myPageData.appData[1]?.ProjNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={myPageData.appData[2]?.DtaSetNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                        <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={myPageData.appData[2]?.DtaNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                        <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={myPageData.appData[2]?.ProjNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                    </tr>
                                    <div className="application-underlien"></div>
                                    <div className="text-table">
                                        <div className='text-titl02'>공동이용자</div>
                                        <div className='textarea-wrapper'>
                                            <textarea style={{ width: "100%", height: "100px"}} ></textarea>
                                        </div>
                                    </div>
                                    <div className="application-underlien"></div>

                                    <div className="text-table">
                                        <div className='text-titl02'>신청데이터<br/>이용계획</div>
                                        <div className='textarea-wrapper'>
                                            <textarea style={{ width: "100%", height: "120px"}} value={myPageData.appInfo?.UsagePlan} ></textarea>
                                        </div>
                                    </div>
                                    <div className="application-underlien"></div>
                                </>
                            ) : (
                                selectedProjects?.length > 0 && (
                                    <>
                                        <tr>
                                            <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={selectedProjects[0]?.DtaSetNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                            <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={selectedProjects[0]?.DtaNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                            <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={selectedProjects[0]?.ProjNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={selectedProjects[1]?.DtaSetNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                            <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={selectedProjects[1]?.DtaNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                            <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={selectedProjects[1]?.ProjNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={selectedProjects[2]?.DtaSetNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                            <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={selectedProjects[2]?.DtaNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                            <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={selectedProjects[2]?.ProjNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                        </tr>
                                        <div className="application-underlien"></div>
                                        <div className="text-table">
                                            <div className='text-titl02'>공동이용자</div>
                                            <div className='textarea-wrapper'>
                                                <textarea style={{ width: "100%", height: "120px"}} placeholder="소속 부서 이름 순으로 작성해주세요." value={iointUsers} onChange={(e) => {setJointUsers(e.target.value)}} ></textarea>
                                            </div>
                                        </div>
                                        <div className="application-underlien"></div>
                                        <div className="text-table">
                                            <div className='text-titl02'>신청데이터<br/>이용계획</div>
                                            <div className='textarea-wrapper'>
                                                <textarea style={{ width: "100%", height: "120px"}}  value={usagePlan} onChange={(event) => setUsagePlan(event.target.value)} ></textarea>
                                            </div>
                                        </div>
                                        <div className="application-underlien"></div>
                                    </>
                                )
                            )
                        }
                        <br/>
                        <div className= "Openoath-title">
                            <div className="Openoath-send" >
                                <span>서약서 제출</span>
                                <span className="required_color">*</span>
                            </div>
                            <div className="application-sendFile-container">
                                {
                                    myPageData?.appData && myPageData.appData.length > 0 ? (
                                        null
                                    ) :(
                                        <div className="research-method-title">
                                            <label htmlFor="openfileDiagram">
                                                <img id="openimgDiagram" src={`${process.env.PUBLIC_URL}/btn_file_select.png`} alt="Diagram" />
                                            </label>
                                        </div>
                                    )

                                }

                                <input type="file"
                                       id="openfileDiagram"
                                       name="dataSubmitFiles"
                                       multiple onChange={handleOpenDataFileChange}
                                       className="search-input" placeholder="파일 이름" />

                                <div className="application-file-list">

                                    {
                                        myPageData?.appData && myPageData.appData.length > 0 ? (
                                            <div className="mypage-viewData">
                                                <div className="fileName" key={myPageData.appInfo?.AppNo}>
                                                    {/* 이미지를 숨기고 파일 이름만 표시 */}
                                                    <div>
                                                        <p>{myPageData.appInfo?.OriginNm}</p> {/* Display the file name */}
                                                    </div>
                                                </div>
                                                <div className="RealData-btnContainer">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/btn_preview.png`}
                                                        alt="preview"
                                                        onClick={() => openPreviewHandler(myPageData.appInfo.SaveNm, myPageData.appInfo.OriginNm, myPageData.appInfo.FilePath, myPageData.appInfo.TermsNo)}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            openFiles.map((file, index) => (
                                                <div className="fileName" key={index}>
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/btn_close.png`}
                                                        alt="close"
                                                        onClick={() => closeFile(index)} // Implement this function to remove a file
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                    <div>
                                                        <p>{file?.name}</p> {/* Display the file name */}
                                                    </div>
                                                </div>
                                            ))
                                        )
                                    }
                                </div>

                                {myPageData?.appData?.length > 0 ? (
                                    <>
                                    </>
                                ): (
                                    <div className="RealData-btnContainer">
                                        <button className="RealData-save-button" onClick={handleOpenUpload} >
                                            업로드
                                        </button>
                                        <ProgressModal
                                            isOpen={isModalOpen}
                                            progress={progress}
                                            fileNames={fileNames}
                                            isCompleted={isCompleted}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        {/*<div className="signature">*/}
                        {/*    <span>이용책임자: </span>*/}
                        {/*    <input*/}
                        {/*        type="text"*/}
                        {/*        value={responsiblePerson}*/}
                        {/*        onChange={(e) => setResponsiblePerson(e.target.value)}*/}
                        {/*        placeholder="이용책임자 이름을 입력하세요"*/}
                        {/*    />*/}
                        {/*</div>*/}
                    </div>
                    <div className="application-btn">
                        {
                            myPageData?.appData?.length > 0 ? (
                                <>
                                    <button onClick={myPagecancelHandler} className="application-cancel">
                                        닫기
                                    </button>
                                </>


                            ) : (
                                <>
                                    <button className="application-apply" onClick={applicationBtn}>
                                        신청
                                    </button>
                                    <button onClick={cancelHandler} className="application-cancel">
                                        취소
                                    </button>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        );
}




export  default ApplicationPage;
