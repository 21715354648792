// src/services/api.js
import axios from 'axios';

// 환경 변수에서 API URL 가져오기
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // 환경 변수로 설정된 API URL
    // withCredentials: true, // 인증 정보를 포함
    // timeout: 10000, // 타임아웃 설정 (선택 사항)
    // headers: {
    //     'Content-Type': 'application/json',
    // },
});
export default api;
