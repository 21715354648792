import React, {useEffect, useState} from "react";
import './myPage.css';
import api from "../api";
import ApplicationPage from "../application/applicationPage";
import {Link, useNavigate} from "react-router-dom";
import {Calendar, momentLocalizer} from "react-big-calendar";
import moment from "moment/moment";
import {Tooltip} from "react-tooltip";

function MypageCalendar() {

    const [myPageData, setMyPageData] = useState([]);
    const [events, setEvents] = useState([]);

    const [modalEvents, setModalEvents] = useState([]); // 모달에 표시할 이벤트
    const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태

    const [selectedDate, setSelectedDate] = useState(new Date()); // 선택된 날짜 상태 추가


    const adminMenu = [
        {
            'id' : 1,
            'name': "마이페이지",
            'path': "/myPage"
        },
        {
            'id' : 2,
            'name': "캘린더(데이터 현황)",
            'path': "/myPage/calendar"
        },

    ]

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await api.get(`/api/my/mycalendar`, { withCredentials: true });
                setMyPageData(res.data);

                const calendarEvents = res.data.appData.map((item, index) => ([
                    {
                        id: `${item.AppNo}-start-${index}`,
                        title: `시작 : [${item.DtaSetNm}] - ${item.DtaDs}`,
                        title01: '시작일',
                        DtaSetNm:  item.DtaSetNm,
                        DtaNm: item.DtaNm,
                        DtaDs: item.DtaDs,
                        RspnEmpNm: item.RspnEmpNm,
                        RspnEmpNo: item.RspnEmpNo,
                        start: new Date(item.StartDt),
                        end: new Date(item.StartDt),
                        type: 'start', // 데이터 시작일
                        details: `내용: ${item.DtaDs}<br />작성 날짜: ${item.StartDt}`,
                        ProjNo: item.ProjNo,
                        ProjNm: item.ProjNm
                    },
                    {
                        id: `${item.AppNo}-end-${index}`,
                        title: `종료 : [${item.DtaSetNm}] - ${item.DtaDs}`,
                        title01: '종료일',
                        DtaSetNm:  item.DtaSetNm,
                        RspnEmpNm: item.RspnEmpNm,
                        RspnEmpNo: item.RspnEmpNo,
                        DtaNm: item.DtaNm,
                        DtaDs: item.DtaDs,
                        start: new Date(item.EndDt),
                        end: new Date(item.EndDt),
                        type: 'end', // 데이터 종료일
                        details: `내용: ${item.DtaDs}<br />작성 날짜: ${item.EndDt}`,
                        ProjNo: item.ProjNo,
                        ProjNm: item.ProjNm
                    }
                ])).flat();

                const calendarOpenPlanEvent = res.data.openPlan.map((item, index) => ([
                    {
                        id: `${item.DmpNo}-internal-${index}`,
                        title: `내부일자 : [${item.DtaNm}] - ${item.DtaNm}`,
                        DtaSetNm: '내부일자',
                        RspnEmpNm: item.RspnEmpNm,
                        RspnEmpNo: item.RspnEmpNo,
                        DtaNm: item.DtaNm,
                        start: new Date(item.OpnPd),
                        end: new Date(item.OpnPd),
                        type: 'internal', // 내부공개일자
                        details: `내용: ${item.DtaNm}<br />작성 날짜: ${item.OpnPd}`,
                        ProjNo: item.ProjNo,
                        ProjNm: item.ProjNm
                    },
                    {
                        id: `${item.DmpNo}-external-${index}`,
                        title: `외부일자 : [${item.ProjNm}] - ${item.DtaNm}`,
                        DtaSetNm: '외부일자',
                        DtaNm: item.DtaNm,
                        RspnEmpNm: item.RspnEmpNm,
                        RspnEmpNo: item.RspnEmpNo,
                        start: new Date(item.OpnTm),
                        end: new Date(item.OpnTm),
                        type: 'external', // 외부공개일자
                        details: `내용: ${item.DtaNm}<br />작성 날짜: ${item.OpnTm}`,
                        ProjNo: item.ProjNo,
                        ProjNm: item.ProjNm
                    }
                ])).flat();

// 두 이벤트 리스트 병합
                const combinedEvents = [...calendarEvents, ...calendarOpenPlanEvent];

// 병합된 이벤트를 캘린더에 설정
                setEvents(combinedEvents);
            } catch (err) {
                console.error("API Error:", err);
            }
        };

        fetchData();
    }, []);

    moment.locale('ko');
    console.log("데이터 확인>>>>> ",  myPageData)

    console.log("모달 이벤트>>>", modalEvents);

    // const handleShowMore = (events, date) => {
    //     // 모달을 띄우기 전에 `+more` 클릭 시 캘린더 뷰 변경을 막는 처리가 필요 없음
    //     setSelectedDate(date); // 선택된 날짜 저장
    //     setModalEvents(events); // 해당 날짜의 이벤트 저장
    //     setIsModalOpen(true); // 모달 열기
    // };

    const handleDateClick = (date) => {
        // Filter events for the selected date
        const filteredEvents = events.filter(
            (event) => moment(event.start).isSame(date, 'day')
        );

        if (filteredEvents.length > 0) {
            setModalEvents(filteredEvents); // Set events for the modal
            setSelectedDate(date); // Set the selected date
            setIsModalOpen(true); // Open the modal
        }
        else {
            alert("해당 날짜에 데이터가 없습니다."); // 데이터가 없는 경우 알림
        }
    };

    const closeModal = () => {
        setIsModalOpen(false); // 모달 닫기
    };

    const localizer = momentLocalizer(moment);

    return (
        <>
            <div className="small-title">
                <img src={`${process.env.PUBLIC_URL}/adminpage.png`} className="adminlist-img02" alt="search Logo" />
                <div className="adminhd-line"></div>
                <h2 className="menu-name">마이페이지 메뉴</h2>
            </div>
            <div className="mycontainer">
                <div className="menu-title">
                    {adminMenu.map((item,index) => (
                        <React.Fragment key={item.id}>
                            <div className="menu-item">
                                <Link to={item.path} style={{ display: 'block', width: '100%', height: '100%' }}>
                                    {item.name}
                                </Link>
                            </div>
                            <div className="admin-under"></div>
                        </React.Fragment>
                    ))}
                </div>
                <div className="admin-line"></div>
                <div className="main-content">
                    <h2 className="main-content--title">캘린더(데이터 현황)</h2>
                    <div className="mypage-side_line-title"></div>
                    <div className="mypage-calendar">
                        {/* 범례 추가 */}
                        <div className="calendar-legend" style={{ display: 'flex', gap: '20px', marginBottom: '15px', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: 'skyblue',
                                        marginRight: '8px',
                                        borderRadius: '3px',
                                    }}
                                ></div>
                                <span>데이터 시작일</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: 'orangered',
                                        marginRight: '8px',
                                        borderRadius: '3px',
                                    }}
                                ></div>
                                <span>데이터 종료일</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: '#4ad9b2',
                                        marginRight: '8px',
                                        borderRadius: '3px',
                                    }}
                                ></div>
                                <span>내부공개일자</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: '#9505ff',
                                        marginRight: '8px',
                                        borderRadius: '3px',
                                    }}
                                ></div>
                                <span>외부공개일자</span>
                            </div>
                        </div>

                        {/* 캘린더 */}

                        <Calendar
                            localizer={localizer}
                            events={events}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: '100vh' }}
                            selectable
                            onSelectSlot={(slotInfo) => handleDateClick(slotInfo.start)}
                            onNavigate={(newDate, view) => {
                                if (view === 'day') {
                                    return; // Do not navigate to the day view
                                }
                            }}
                            eventPropGetter={(event) => {
                                let backgroundColor;
                                switch (event.type) {
                                    case 'start':
                                        backgroundColor = 'skyblue';
                                        break;
                                    case 'end':
                                        backgroundColor = 'orangered';
                                        break;
                                    case 'internal':
                                        backgroundColor = '#4ad9b2';
                                        break;
                                    case 'external':
                                        backgroundColor = '#9505ff';
                                        break;
                                    default:
                                        backgroundColor = 'gray';
                                }

                                return {
                                    style: {
                                        backgroundColor,
                                        color: 'white',
                                        borderRadius: '5px',
                                        padding: '2px 5px',
                                    },
                                    'data-tooltip-id': `tooltip-${event.id}`,
                                    'data-tooltip-html': event.details,
                                };
                            }}
                            components={{
                                event: (props) => (
                                    <span
                                        data-tooltip-id={`tooltip-${props.event.id}`}
                                        data-tooltip-html={props.event.details}
                                    >
                {props.title}
            </span>
                                ),
                            }}
                            views={['month', 'week']} // Exclude the 'day' view
                        />


                        {isModalOpen && (
                            <div className="calendar-modal">
                                <div className="modal-content" style={{width: '100vh'}}>
                                    {/* 모달 닫기 버튼 */}
                                    <button className="close-btn" onClick={closeModal}>X</button>

                                    {/* 선택된 날짜 표시 */}
                                    <h1>{moment(selectedDate).format("YYYY-MM-DD")} 요약보기</h1>

                                    {/* 데이터/신청현황 */}
                                    <h2 style={{ textAlign: "left" }}>데이터(신청현황)</h2>
                                    <table className="modal-table">
                                        <thead>
                                        <tr>
                                            <th>구분</th>
                                            <th>과제번호</th>
                                            <th>과제명</th>
                                            <th>데이터셋 / 설명 </th>
                                            <th>연구책임자</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {modalEvents.filter(event => event.type === 'start' || event.type === 'end').length > 0 ? (
                                            modalEvents
                                                .filter(event => event.type === 'start' || event.type === 'end')
                                                .map((event, index) => (
                                                    <tr key={`${event.id}-${index}`}>
                                                        <td style={{width : '60px'}}>{event.title01}</td>
                                                        <td style={{width : '120px'}}>{event.ProjNo || '-'}</td>
                                                        <td>{event.ProjNm || '-'}</td>
                                                        <td>     <span>[{event.DtaSetNm}] {event.DtaNm}</span><br />
                                                            <span>{event.DtaDs}</span></td>
                                                        <td style={{width : '100px'}}>[{event.RspnEmpNo || '-'}]<br/>{event.RspnEmpNm || '-'}</td>
                                                    </tr>
                                                ))
                                        ) : (
                                            <tr>
                                                <td colSpan="7" style={{ textAlign: "center" }}>데이터가 없습니다</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>

                                    <div className="mypage-side_line-title"></div>

                                    {/* 공개시기 */}
                                    <h2 style={{ textAlign: "left" }}>공개시기</h2>
                                    <table className="modal-table">
                                        <thead>
                                        <tr>
                                            <th>구분</th>
                                            <th>과제번호</th>
                                            <th>과제명</th>
                                            <th>데이터셋</th>
                                            <th>연구책임자</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {modalEvents.filter(event => event.type === 'internal' || event.type === 'external').length > 0 ? (
                                            modalEvents
                                                .filter(event => event.type === 'internal' || event.type === 'external')
                                                .map((event, index) => (
                                                    <tr key={`${event.id}-status-${index}`}>
                                                        <td style={{width : '100px'}}>{event.title01}</td>
                                                        <td style={{width : '120px'}}>{event.ProjNo || '-'}</td>
                                                        <td>{event.ProjNm || '-'}</td>
                                                        <td>{event.DtaNm}</td>
                                                        <td style={{width : '100px'}}>[{event.RspnEmpNo || '-'}]<br/>{event.RspnEmpNm || '-'}</td>
                                                    </tr>
                                                ))
                                        ) : (
                                            <tr>
                                                <td colSpan="6" style={{ textAlign: "center" }}>데이터가 없습니다</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}



                        {/* 툴팁 */}
                        {events.map((event) => (
                            <Tooltip
                                key={event.id}
                                id={`tooltip-${event.id}`}
                                className="custom-tooltip"
                                place="top-end"
                                type="dark"
                                effect="float"
                                html={true} // HTML을 허용하도록 설정
                            />
                        ))}
                    </div>

                </div>
            </div>

        </>
    );
}

export default MypageCalendar;
