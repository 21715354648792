import React, { useState } from "react";
import "./uploadTask.css";
import api from "../../api";
import Cookies from "js-cookie";

function UploadTask({ onClose }) {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            alert("파일을 선택해주세요.");
            return;
        }

        // FormData 객체를 사용하여 파일 업로드
        const formData = new FormData();
        formData.append("file", selectedFile);

        try {
            const response = await api.post("/api/m/projexcel", formData, {
                headers: {
                    "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"), // CSRF 토큰 헤더
                    "Content-Type": "multipart/form-data",     // 멀티파트 데이터 처리
                },
                withCredentials: true, // 쿠키와 함께 요청
            });

            alert(response.data); // 서버에서 반환된 메시지 표시
            onClose(); // 업로드 완료 후 모달 닫기
        } catch (error) {
            // 기본 에러 메시지
            console.error("파일 업로드 실패:", error);

            // 서버 응답이 있는 경우
            if (error.response) {
                const { status, data } = error.response;
                console.error("응답 에러:", status, data);

                // 상태 코드에 따라 사용자 친화적인 메시지 표시
                if (status === 400) {
                    alert("업로드된 파일이 엑셀 파일이 아닙니다.");
                } else if (status === 401) {
                    alert("인증이 필요합니다. 다시 로그인해주세요.");
                } else if (status === 403) {
                    alert("권한이 없습니다.");
                } else if (status === 500) {
                    alert("서버 내부 오류가 발생했습니다. 관리자에게 문의하세요.");
                } else {
                    alert(`알 수 없는 오류가 발생했습니다. (상태 코드: ${status})`);
                }
            } else if (error.request) {
                // 요청이 전송되었으나 응답이 없는 경우
                console.error("응답 없음:", error.request);
                alert("서버로부터 응답이 없습니다. 네트워크를 확인해주세요.");
            } else {
                // 요청 설정 문제 등 기타 에러
                console.error("요청 설정 에러:", error.message);
                alert("파일 업로드 요청 중 오류가 발생했습니다: " + error.message);
            }
        }
    };


    return (
        <div className="modal-overlay">
            <div className="modal">
                <h2>파일 업로드</h2>
                <input
                    type="file"
                    accept="*/*"
                    onChange={handleFileChange}
                    className="file-input"
                />
                {selectedFile && (
                    <div className="file-info">
                        <p>선택된 파일: {selectedFile.name}</p>
                        <p>파일 크기: {(selectedFile.size / 1024).toFixed(2)} KB</p>
                    </div>
                )}
                <div className="modal-buttons">
                    <button className="upload-button" onClick={handleUpload}>
                        업로드
                    </button>
                    <button className="close-button" onClick={onClose}>
                        닫기
                    </button>
                </div>
            </div>
        </div>
    );
}

export default UploadTask;
