import React, {useEffect, useState, useRef} from 'react';
import './dmpcreate.css'
import DataManagementGrid from "../../dmpDataManagement/dataManagementGrid";
import LastDmpShow from "../../lastDmp/lastDmpShow";
import Cookies from 'js-cookie';
import {
    useTaskDataStore,
    useLastDataStore
} from "../../stores/lastDmpData";
import DataPublicGrid from "../../dmpDataPublic/dataPublicGrid";
import TaskCheck from "../../taskCheck/taskCheck";
import api from '../../api';
import {Link, useNavigate} from "react-router-dom";
import DmpDataProductionPlan from "../../dmpDataProductionPlan/dmpDataProductionPlan";
import {
    useNewDmpManagementPlan,
    useNewDmpProductionPlan,
    useNewDmpPublicPlan,
} from "../../stores/newWriteDmp";
import DmpToastGrid from "../../dmpToast/dmpToast";

function DmpcreatePage() {

    const navigate = useNavigate();


    const [allData, setAllData] = useState([]); // 각 연도별 데이터를 저장할 객체
    const dataRefs = useRef([]); // 동적으로 ref를 저장할 배열

    const productionPlanRefs = useRef({});

    const taskRef = useRef(null);
    const methodSummaryRef = useRef(null);
    const newDataSummaryRef = useRef(null);
    const newDataSetRef = useRef(null);
    const analysisPlanRef = useRef(null);
    const productionPlanRef = useRef(null);
    const managementPlanRef = useRef(null);
    const publicPlanRef = useRef(null);


    const [showLastDmp , setShowLastDmp] = useState(false);
    const [showObjCheck, setShowObjCheck] = useState(false);
    const [showHistory, setShowHistory] = useState(false);

    //새로운 summary 생성
    const [newDataSummary, setNewDataSummary] = useState([]);

    // 가져온 데이터 setData 입니다.
    const dataTypes = ["시료데이터","방법데이터","결과데이터"];

    //새로운 summary 제목에 필요한 버튼 생성
    const newDataType = newDataSummary[newDataSummary.length - 1]?.DtaSetNm;
    const currentNewTypeIndex = dataTypes.indexOf(newDataType);
    const newDataSummaryName = (currentNewTypeIndex + 1) % dataTypes.length;
    const resultSummaryType = dataTypes[newDataSummaryName];

    // 과제 선택 부분
    const tastdataSets = useTaskDataStore(state => state.taskdataSets);
    const lastDataCellValue = useLastDataStore(state => state.lastdataSets);

    // 이미지 불러오기 부분
    const [imageSrc, setImageSrc] = useState(`${process.env.PUBLIC_URL}/bg_file_select.png`);
    const [RsrcImg, setRsrcImg] = useState(''); // Base64로 변환된 이미지

    //지난 데이터 불러오기 시 과제명, 과제번호, 설명
    const [createProjNo, setCreateProjNo] = useState("" );
    const [createProName, setCreateProName] = useState("")
    const [rspnEmpNm, setRspnEmpNm] = useState('');
    const [createRspnEmpNo, setCreateRspnEmpNo] = useState("")
    const [createDateDmpNo, setCreateDateDmpNo] = useState("")
    const [createDateDmpNm, setCreateDateDmpNm] = useState("")

    // 지난 데이터 불러오기 시 요약을 입력할때
    const [methodSummary, setMethodSummary] = useState("");

    // 지난 데이터 불러오기 시 연구데이터 분석 계획
    // const [analysisPlan, setAnalysisPlan] = useState("");

    //지난 데이터 불러오기 시 데이터 세트 저장
    const [lastDataSets, setLastDataSets] = useState([]);

    //Summary 데이터 세트 값 저장


    //동적인 데이터 타입
    const [proplanDataTypes, setProplanDataTypes] = useState([]);  //

    //생산 계획 값 저장 new !!
    // const {newProductionPlandataSets} = useNewDmpProductionPlan();

    const newProductionPlandataSets = Object.values(allData).flat();

    //관리 계획 값 저장 new!!
    const { newManagementPlandataSets,setNewManagementPlandataSets } = useNewDmpManagementPlan();
    //공개계획 값 저장
    const { newPublicPlandataSets, setNewPublicPlandataSets } = useNewDmpPublicPlan();

    //생산 계획에 필요한 년도 자르기

        const productionStartYear =  new Date(tastdataSets[0]?.ProjDateSt).getFullYear();
        const productionEndYear = new Date(tastdataSets[0]?.ProjDateEd).getFullYear();


        const generateProductionPlans = () => {
            const years = [];
            for (let i = productionStartYear; i <= productionEndYear; i++) {
            years.push(i);

        }
        return years;
    };
/////////////////////////////////////////////////////////////////////////////// 지난 DMP 불러오기 코드 부분
    //등록 시 지난DMP 불러오기를 하면 과제 번호도 바껴야함 같이 전부 세팅 되어야한다.


    // useEffect(() => {
    //         setAllData(prevData => ({
    //             ...prevData,
    //             [year]: data // 해당 연도의 데이터를 업데이트
    //         }));
    //     };
    // }, [ allData]);


    //과제 선택에서 데이터 가져온 값 데이터 저장
    useEffect(() => {
        const lastItem = tastdataSets[tastdataSets.length - 1];

        if (lastItem) {
            setCreateProjNo(tastdataSets[0]?.ProjNo);
            setCreateProName(tastdataSets[0]?.ProjNm);
            setRspnEmpNm(tastdataSets[0]?.RspnEmpNm);
            setCreateRspnEmpNo(tastdataSets[0]?.RspnEmpNo);
            setCreateDateDmpNo(tastdataSets[0]?.DataEmpNo);
            setCreateDateDmpNm(tastdataSets[0]?.DataEmpNm);
            // setMethodSummary("");
            // setAnalysisPlan("");
            // setLastDataSets("");
        }
        if (lastDataCellValue) {
            // setCreateProjNo(lastDataCellValue.DmpMain?.ProjNo);
            // setCreateProName(lastDataCellValue.DmpMain?.ProjNm);
            // setRspnEmpNm(lastDataCellValue.DmpMain?.RspnEmpNm);
            // setCreateRspnEmpNo(lastDataCellValue.DmpMain?.RspnEmpNo);
            setCreateDateDmpNo(lastDataCellValue.DmpMain?.DataEmpNo);
            setCreateDateDmpNm(lastDataCellValue.DmpMain?.DataEmpNm);
            setMethodSummary(lastDataCellValue.DmpMain?.RsrcSmm);
            // setAnalysisPlan(lastDataCellValue.DmpMain?.RsrcAnlyPlan)
            setLastDataSets(lastDataCellValue?.SumData);
        }
    }, [tastdataSets, lastDataCellValue]);




    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const img = new Image();
                img.src = reader.result;

                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    // Set canvas dimensions to the image dimensions
                    canvas.width = img.width;
                    canvas.height = img.height;

                    // Draw the image onto the canvas
                    ctx.drawImage(img, 0, 0);

                    // Convert the canvas content to a JPEG base64 string
                    const jpegBase64 = canvas.toDataURL('image/jpeg');

                    setImageSrc(jpegBase64); // 이미지 미리보기
                    setRsrcImg(jpegBase64);  // Base64로 변환된 JPEG 이미지 저장
                };
            };
            reader.readAsDataURL(file); // 파일을 Base64로 읽음 (PNG 파일일지라도 처리됨)
        }
    };



    const newSummaryhandleChange = (id, key, value) => {
        if (Array.isArray(lastDataSets) && lastDataSets.length > 0) {
            setLastDataSets(prevData =>
                prevData.map(item =>
                    (item.Summary?.DtaSetNo === id || item.Summary?.id === id)
                        ? {
                            Summary: {
                                ...item.Summary,
                                [key]: value,
                                // DtaSetNm이 "방법데이터"나 "결과데이터"인 경우 DtaSetNmSub 초기화
                                ...(key === 'DtaSetNm' && (value === '방법데이터' || value === '결과데이터') && { DtaSetNmSub: "" })
                            },
                            DataMain: []
                        }
                        : item
                )
            );
        } else {
            setNewDataSummary(prevData =>
                prevData.map(item =>
                    item.Summary?.id === id
                        ? {
                            Summary: {
                                ...item.Summary,
                                [key]: value,
                                // DtaSetNm이 "방법데이터"나 "결과데이터"인 경우 DtaSetNmSub 초기화
                                ...(key === 'DtaSetNm' && (value === '방법데이터' || value === '결과데이터') && { DtaSetNmSub: "" })
                            },
                            DataMain: []
                        }
                        : item
                )
            );
        }

        if (key === 'DtaNm') {
            setProplanDataTypes(prevTypes => {
                // 기존 데이터 중 해당 id가 있는지 확인
                const existingIndex = prevTypes.findIndex(item => item.id === id);

                if (existingIndex !== -1) {
                    // id가 일치하는 데이터가 있으면 값을 덮어쓰기
                    const updatedTypes = [...prevTypes];
                    updatedTypes[existingIndex] = { id, [key]: value };
                    return updatedTypes;
                } else {
                    // id가 없으면 새로운 데이터를 추가
                    return [...prevTypes, { id, [key]: value }];
                }
            });
        }
    };


    // useEffect(() => {
    //     // 초기 데이터로 setProplanDataTypes 채우기
    //     if (Array.isArray(lastDataSets) && lastDataSets.length > 0) {
    //         setProplanDataTypes((prevTypes) => {
    //             const updatedTypes = [...prevTypes];
    //
    //             lastDataSets.forEach((item) => {
    //                 const id = item.Summary?.DtaSetNo || item.Summary?.id;
    //                 const existingIndex = updatedTypes.findIndex(
    //                     (type) => type.id === id || type.DtaSetNo === id
    //                 );
    //
    //                 if (existingIndex !== -1) {
    //                     // 이미 존재하면 업데이트
    //                     updatedTypes[existingIndex] = {
    //                         ...updatedTypes[existingIndex],
    //                         id: id || null,
    //                         DtaSetNo: id || null,
    //                         DtaNm: item.Summary?.DtaNm || updatedTypes[existingIndex].DtaNm || "",
    //                         DtaDs: item.Summary?.DtaDs || updatedTypes[existingIndex].DtaDs || "",
    //                     };
    //                 } else {
    //                     // 존재하지 않으면 새로 추가
    //                     updatedTypes.push({
    //                         id: id || null,
    //                         DtaSetNo: id || null,
    //                         DtaNm: item.Summary?.DtaNm || "",
    //                         DtaDs: item.Summary?.DtaDs || "",
    //                     });
    //                 }
    //             });
    //
    //             return updatedTypes;
    //         });
    //     }
    // }, [lastDataSets, setProplanDataTypes]);


    useEffect(() => {
        // lastDataSets가 유효한 배열인지 확인
        if (Array.isArray(lastDataSets) && lastDataSets.length > 0) {
            // setProplanDataTypes를 새로운 데이터로 초기화
            const newTypes = lastDataSets.map((item) => ({
                id: item.Summary?.DtaSetNo || item.Summary?.id || null,
                DtaSetNo: item.Summary?.DtaSetNo || item.Summary?.id || null,
                DtaNm: item.Summary?.DtaNm || "",
                DtaDs: item.Summary?.DtaDs || "",
            }));

            setProplanDataTypes(newTypes); // 이전 상태를 초기화하고 새 데이터를 설정
        }
    }, [lastDataSets, setProplanDataTypes]);
    // 핸들러 함수: 입력 필드의 변화를 관리
    // const newSummaryhandleChange = (id, key, value) => {
    //
    //     if (Array.isArray(lastDataSets) && lastDataSets.length > 0) {
    //         setLastDataSets(prevData =>
    //             prevData.map(item =>
    //                 // 두 조건이 모두 참인 경우에만 업데이트
    //                 item.Summary?.DtaSetNo === id || item.Summary?.id === id
    //                     ? { Summary: { ...item.Summary, [key]: value }, DataMain: [] }
    //                     : item
    //             )
    //         );
    //     }
    //
    //     else {
    //         setNewDataSummary(prevData =>
    //             prevData.map(item =>
    //                 item.Summary?.id === id ? { Summary: { ...item.Summary, [key]: value }, DataMain: []}
    //                     : item
    //             )
    //         );
    //     }
    //
    //
    //     if (key === 'DtaNm') {
    //         setProplanDataTypes(prevTypes => {
    //             // 기존 데이터 중 해당 id가 있는지 확인
    //             const existingIndex = prevTypes.findIndex(item => item.id === id);
    //
    //             if (existingIndex !== -1) {
    //                 // id가 일치하는 데이터가 있으면 값을 덮어쓰기
    //                 const updatedTypes = [...prevTypes];
    //                 updatedTypes[existingIndex] = { id, [key]: value };
    //                 return updatedTypes;
    //             } else {
    //                 // id가 없으면 새로운 데이터를 추가
    //                 return [...prevTypes, { id, [key]: value }];
    //             }
    //         });
    //     }
    // };



    // // 데이터 세트 추가 핸들러
    // const handleCreateAddDataSet = () => {
    //     if(Array.isArray(lastDataSets) && lastDataSets.length > 0) {
    //         setLastDataSets(prevData => [
    //             ...prevData,
    //             {
    //                 Summary:{
    //                     id: Date.now() + Math.random(), // 고유한 ID 생성
    //                     DtaSetNm: '', // dataTypes 배열의 각 값을 할당
    //                     DtaSetNmSub: '', // 초기값
    //                     DtaNm: '', // 초기값
    //                     DtaDs: '', // 초기값
    //                     DmpFlg: "Y",
    //                 },
    //                 DataMain: [],
    //             }
    //         ]);
    //     }
    //     setNewDataSummary(prevData => [
    //         ...prevData,
    //         {
    //             Summary:{
    //                 id: Date.now() + Math.random(), // 고유한 ID 생성
    //                 DtaSetNm: '', // dataTypes 배열의 각 값을 할당
    //                 DtaSetNmSub: '', // 초기값
    //                 DtaNm: '', // 초기값
    //                 DtaDs: '', // 초기값
    //                 DmpFlg: "Y",
    //             },
    //             DataMain: [],
    //         }
    //     ]);
    // };

    const createNewDataSet = () => ({
        Summary: {
            id: Date.now() + Math.random(), // 고유한 ID 생성
            DtaSetNm: '', // 이 부분에 값을 추가할 수 있음
            DtaSetNmSub: '', // 초기값
            DtaNm: '', // 초기값
            DtaDs: '', // 초기값
            DmpFlg: "Y",
        },
        DataMain: [],
    });

    const handleCreateAddDataSet = () => {
        // 새로운 데이터 세트 생성
        const newDataSet = createNewDataSet();

        // lastDataSets가 배열인지 확인하고 업데이트
        if (Array.isArray(lastDataSets)) {
            setLastDataSets(prevData => [...prevData, newDataSet]);
        }

        // newDataSummary 업데이트
        setNewDataSummary(prevData => [...prevData, newDataSet]);
    };

    const newHandleDeleteDataSet = (id) => {
        setNewDataSummary(newDataSummary.filter(dataSet => dataSet.Summary.id !== id));
    };

    const lastHandleDeleteDataSet = (id) => {
        setLastDataSets((prevDataSets) =>
            prevDataSets.filter(
                (dataSet) => dataSet.Summary.id !== id && dataSet.Summary.DtaSetNo !== id
            )
        );
    };
    // 지난 데이터 불러오기 컴포넌트

    const toggleLastDmp = () => {
        if(tastdataSets.length === 0) {
            alert("'과제 선택' 버튼을 눌러 DMP를 작성할 과제를 선택하세요.")
            return;
        }
        setShowLastDmp(!showLastDmp);
    }
    const toggleHistoryDmp = () => {
        if(tastdataSets.length === 0) {
            alert("'과제 선택' 버튼을 눌러 DMP를 작성할 과제를 선택하세요.")
            return;
        }
        setShowHistory(!showHistory);
    }

    const toggleObjCheck = () => {
        setShowObjCheck(!showObjCheck);
    }
    //검증 함수
    const validateData = () => {
        if (lastDataSets && lastDataSets.length > 0) {
            return true; // 유효성 통과
        }
        // && !lastDataCellValue.DmpMain?.analysisPlan
        // && !lastDataCellValue.DmpMain?.RsrcSmm

        // 과제 선택 유효성 검사
        // || lastDataCellValue.DmpMain?.some(item => !item.ProjNo || !item.ProjNm || !item.RspnEmpNm)
        if (tastdataSets?.length === 0 || tastdataSets?.some(item => !item.ProjNo || !item.ProjNm || !item.RspnEmpNm)) {
            alert("과제 정보를 선택해주세요.");
            taskRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }); // 해당 위치로 스크롤
            return false;
        }

        // 연구 방법 요약
        if (!methodSummary) {
            alert("연구 방법 요약을 작성해주세요.");
            methodSummaryRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return false;
        }

        // newDataSummary가 비어있거나 특정 필드가 없는 경우 유효성 실패
        if (newDataSummary.length === 0 || newDataSummary.some(item => !item.Summary?.DtaSetNm || !item.Summary?.DtaNm || !item.Summary?.DtaDs)) {
            alert("데이터 세트의 Summary 값이 비어있습니다.");
            newDataSummaryRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return false;
        }

        // newDataSummary.forEach(item => {
        //     if (item.DataMain.length === 0 || item.DataMain.some(data => !data.field?.trim())) {
        //         alert("데이터세트에 값이 없습니다.");
        //         newDataSetRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        //         return;  // 조건에 맞지 않으면 더 이상 진행하지 않음
        //     }
        // });



        const handleScrollToYear = (year) => {
            const ref = productionPlanRefs.current[year];

            if (ref) {
                ref.scrollIntoView({ behavior: 'smooth', block: 'center'});
            } else {
                console.log(`Ref for ${year} is not available.`); // ref가 할당되지 않은 경우
            }
        };

        const years = Object.keys(allData); // allData의 키 (년도) 가져오기

        for (let i = 0; i < years.length; i++) {
            const year = years[i];
            const plan = allData[year];

            if (plan.length === 0 || plan.some(item => !item.dataType || !item.count || !item.proPlan)) {
                alert(`${year.replace('production-plan-', '')}년도의 생산 계획에 데이터가 없습니다.`);

                const Onlyyears = year.split("-")[2]; // "2024"만 추출
                // 스크롤 이동 처리
                handleScrollToYear(Onlyyears); // 스크롤 이동
                // handleScrollToYear(year); // 스크롤 이동
                return false;
            }
        }

        // 연구 데이터 분석 계획
        // if (!analysisPlan) {
        //     alert("연구데이터 분석 계획을 작성해주세요.");
        //     analysisPlanRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        //     return false;
        // }

        // 연구 데이터 공개 계획 유효성 검사
        if (!newPublicPlandataSets || newPublicPlandataSets.length === 0) {
            alert("공개 계획에 데이터가 없습니다.");
            publicPlanRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;
        }

        if (newPublicPlandataSets.some(item => !item.publictime || !/^\d{4}-\d{2}-\d{2}$/.test(item.publictime))) {
            alert("원내 공개 시점을 YYYY-MM-DD 형식에 맞게 채워주세요.");
            publicPlanRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;
        }

        if (newPublicPlandataSets.some(item => !item.externalTime || !/^\d{4}-\d{2}-\d{2}$/.test(item.externalTime))) {
            alert("원외 공개 시점을 YYYY-MM-DD 형식에 맞게 채워주세요.");
            publicPlanRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;
        }


        // 연구 데이터 관리 계획 유효성 검사
        if (newManagementPlandataSets.length === 0 || newManagementPlandataSets.some(item => !item.dataType || !item.method)) {
            alert("관리 계획에 데이터가 없습니다.");
            managementPlanRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center'});
            return false;
        }



        return true;
    };

    const years = Object.keys(allData); // allData의 키 (년도) 가져오기

    const prepareProductionData = (tmpYn) => {
        const productionPlans = generateProductionPlans(); // PrdYear 값들을 가져옴

        if (!newProductionPlandataSets || newProductionPlandataSets.length === 0) {
            return productionPlans.map((year) => ({
                PrdYear: year,  // 각 년도를 개별적으로 사용
                DtaNm: '-',
                ProCnt: '-',
                ProPlan: '-'
            }));
        }

        return newProductionPlandataSets.map((newData, index) => {
            const year = productionPlans[index];  // 각 년도를 동적으로 할당
            return {
                PrdYear: tmpYn === "Y" ? (newData.PrdYear || year) : newData.PrdYear,
                DtaNm: tmpYn === "Y" ? (newData.dataType || '-') : newData.dataType,
                ProCnt: tmpYn === "Y" ? (newData.count || '-') : newData.count,
                ProPlan: tmpYn === "Y" ? (newData.proPlan || '-') : newData.proPlan
            };
        });
    };

    const prepareManageData = (tmpYn) => {
        if (!newManagementPlandataSets || newManagementPlandataSets.length === 0) {
            return [{
                DtaNm: '-',
                MngMth: '-',
                MngResn: '-',
                MngPos: '-',
                MngPd: '-',
                MngOpnMth: '-',
                MngStgMth: '-',
            }];
        }

        return newManagementPlandataSets.map((newData) => ({
            DtaNm: tmpYn === "Y" ? (newData.dataType || '-') : newData.dataType,
            MngMth: tmpYn === "Y" ? (newData.method || '-') : newData.method,
            MngResn: tmpYn === "Y" ? (newData.individualManagement || '-') : newData.individualManagement,
            MngPos: tmpYn === "Y" ? (newData.dataLocation || '-') : newData.dataLocation,
            MngPd: tmpYn === "Y" ? (newData.storagePeriod || '-') : newData.storagePeriod,
            MngOpnMth: tmpYn === "Y" ? (newData.storagePeriod || '-') : newData.storagePeriod,
            MngStgMth: tmpYn === "Y" ? (newData.dataLocation || '-') : newData.dataLocation
        }));
    };

    const preparePublicData = (tmpYn) => {
        // 현재 날짜를 YYYY-MM-DD 형식으로 변환
        const today = new Date().toISOString().split('T')[0];

        if (!newPublicPlandataSets || newPublicPlandataSets.length === 0) {
            return [{
                DtaNm: '-',
                OpnTm: today,
                OpnPd: today,
                OpnMth: '-',
                OpnResn: '-',
                OpnOpnMth: '-',
            }];
        }

        return newPublicPlandataSets.map((newData) => ({
            DtaNm: tmpYn === "Y" ? (newData.dataType || '-') : newData.dataType,
            OpnTm: tmpYn === "Y" ? today : newData.publictime,
            OpnPd: tmpYn === "Y" ? today : newData.externalTime,
            OpnMth: tmpYn === "Y" ? '-' : '-',
            OpnResn: tmpYn === "Y" ? '-' : '-',
            OpnOpnMth: tmpYn === "Y" ? '-' : '-'
        }));
    };



    const prepareMaterialData = (tmpYn) => {

        const defaultSummary = {
            DtaSetNm: '-',
            DtaSetNmSub: '-',
            DtaNm: '-',
            DtaDs: '-',
            DmpFlg: '-',
        };
        const mapDataItem = (dataItem) => {
            // DataMain이 없거나 비어 있을 때 기본값을 사용
            const defaultDataMain = [{
                DtaFld: '-',
                DtaTyp: '-',
                DtaUnit: '-',
                DtaMtdIdx: '-',
                DtaMtdNm: '-',
                DtaDsc: '-',
                UserNo: '-',
                UserNm: '-'
            }];

            console.log("dataItemdataItem@@", dataItem);

            const summary = dataItem.Summary || defaultSummary;

            return {
                DtaSetNm: tmpYn === "Y" ? (summary.DtaSetNm || '-') : summary.DtaSetNm,
                DtaSetNmSub: tmpYn === "Y" ? (summary.DtaSetNmSub || '-') : summary.DtaSetNmSub,
                DtaNm: tmpYn === "Y" ? (summary.DtaNm || '-') : summary.DtaNm,
                DtaDs: tmpYn === "Y" ? (summary.DtaDs || '-') : summary.DtaDs,
                DmpFlg: tmpYn === "Y" ? (summary.DmpFlg || '-') : summary.DmpFlg,
                DataMain: (dataItem.DataMain && dataItem.DataMain.length > 0)
                    ? dataItem.DataMain.map((item) => ({
                        DtaFld: tmpYn === "Y" ? (item.field || item.DtaFld || '-') : (item.field || item.DtaFld),
                        DtaTyp: tmpYn === "Y" ? (item.type || item.DtaTyp || '-') : (item.type || item.DtaTyp),
                        DtaUnit: tmpYn === "Y" ? (item.unit || item.DtaUnit || '-') : (item.unit || item.DtaUnit),
                        DtaMtdIdx: tmpYn === "Y" ? (item.index || item.DtaMtdIdx || '-') : (item.index || item.DtaMtdIdx),
                        DtaMtdNm: tmpYn === "Y" ? (item.name || item.DtaMtdNm || '-') : (item.name || item.DtaMtdNm),
                        DtaDsc: tmpYn === "Y" ? (item.description || item.methoddescription || '-') : (item.description || item.methoddescription),
                        UserNo: tmpYn === "Y" ? (item.UserNo || '-') : item.UserNo,
                        UserNm: tmpYn === "Y" ? (item.UserNm || '-') : item.UserNm
                    }))
                    : defaultDataMain // DataMain이 없거나 비어 있을 경우 기본값 사용
            };
        };

        const lastDataSetsTransformed = Array.isArray(lastDataSets) && lastDataSets.length > 0
            ? lastDataSets.map(mapDataItem)
            : null; // lastDataSets가 없으면 null 반환

        const newDataSummaryTransformed = Array.isArray(newDataSummary) && newDataSummary.length > 0
            ? newDataSummary.map(mapDataItem)
            : null; // newDataSummary가 없으면 null 반환

        // 우선순위: lastDataSets > newDataSummary
        return lastDataSetsTransformed || newDataSummaryTransformed || [];
        // return newDataSummary.map(mapDataItem);
    };



// Common function to make the API call
    const postData = (tmpYn, returnString) => {
        const productionPnNewData = prepareProductionData(tmpYn);
        const managePNNewData = prepareManageData(tmpYn);
        const publicNewData = preparePublicData(tmpYn);
        const materialDataSetValues = prepareMaterialData(tmpYn);



        // 저장 시 필수 값 검증 (tmpYn === "N"일 때만 검증)
        if (tmpYn === "N" && !validateData()) {
            return; // 검증에 실패하면 저장 중단
        }

        const csrfToken = Cookies.get('XSRF-TOKEN');

        // API 요청
        api.post('/api/dmp/saveDmp', {
            DmpMain: {
                ProjNo: createProjNo || "-",
                ProjNm: createProName || "-",
                RspnEmpNo: createRspnEmpNo || "-",
                RspnEmpNm: rspnEmpNm || "-",
                DataEmpNo: createDateDmpNo || "-",
                DataEmpNm: createDateDmpNm || "-",
                RsrcSmm: methodSummary || "-",
                // RsrcImg: "",
                // RsrcFileNm: "",
                // RsrcFilePath: "",
                RsrcImg: RsrcImg,
                RsrcFileNm: "",
                RsrcFilePath: "",
                RsrcAnlyPlan: "-",
                TmpYn: tmpYn
            },
            Summary: materialDataSetValues,
            Prod: productionPnNewData,
            Mnmt: managePNNewData,
            Open: publicNewData
        }, {
            withCredentials: true,
            headers: {
                'X-XSRF-TOKEN': csrfToken,
            }
        }).then((res) => {
                alert(returnString);
                navigate("/dmpManagement/dmpList");
            })
            .catch((error) => {
                console.error("API 통신 에러 발생: ", error.response ? error.response.data : "No response data");
                alert("작성하신 dmp를 확인해주세요.");
            });
    };

// 저장 버튼 (필수 입력 값이 없으면 저장 불가)
    const DataSaveBtn = (e) => {
        e.preventDefault();
        postData("N", "저장 성공");
    };

// 임시 저장 버튼 (빈 값은 '-'로 처리)
    const DataHisBtn = (e) => {
        e.preventDefault();
        postData("Y", "임시저장 성공");
    };

    //사이드 바 클릭 시 이동
    const taskInfoRef = useRef(null);
    const researchMethodRef = useRef(null);
    const researchImgRef = useRef(null);
    const sidebarDataSetsRef = useRef(null);
    const productionDataRef = useRef(null);
    // const analySideDataRef = useRef(null);
    const mgDataRef = useRef(null);
    const openDataRef = useRef(null);


    const combinedRef = (el) => {
        // 두 개의 ref에 동시에 접근
        newDataSummaryRef.current = el;
        sidebarDataSetsRef.current = el;
    };

    const handleScrollToSection = (ref) => {
        if (ref && ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth',  block: 'center'});
        }
    };

    const handleYear = (year) => {
        if (year === productionDataRef) {
            productionDataRef.current.scrollIntoView({ behavior: "smooth", block: 'center' });
        } else if (productionPlanRefs.current[year]) {
            productionPlanRefs.current[year].scrollIntoView({ behavior: "smooth", block: 'center' });
        }
    };

    useEffect(() => {
        // newDataSummary 배열이 변경될 때마다 ref 배열을 동기화
        dataRefs.current = dataRefs.current.slice(0, newDataSummary.length);
    }, [newDataSummary]);

    const handleScrollToElement = (index) => {
        if (dataRefs.current[index]) {
            dataRefs.current[index].scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    useEffect(() => {

        window.history.pushState(null, "", window.location.href);

        const handlePopState = (event) => {
            const userConfirmed = window.confirm(
                "현재 작성중인 데이터가 저장되지 않습니다. \n목록으로 이동하시겠습니까?"
            );

            console.log("확인 데이터", userConfirmed);

            if (userConfirmed) {
                // 사용자 확인 시 새로고침
                window.location.reload();
            } else if (userConfirmed === false) {

            }
        };
        // popstate 이벤트 감지 (뒤로가기 버튼 감지)
        window.addEventListener("popstate", handlePopState);

        // 컴포넌트 언마운트 시 이벤트 정리
        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);


    useEffect(() => {
        // 세션을 유지하기 위한 함수
        const keepSessionAlive = async () => {
            try {
                // 백엔드에 세션 유지 요청 보내기
                const response = await api.get('/api/auth/user', { withCredentials: true });
                console.log('Session kept alive:', response.data);
            } catch (error) {
                console.error('Failed to keep session alive:', error);
            }
        };

        // 30분(1800000ms)마다 세션 유지 요청 보내기
        const intervalId = setInterval(keepSessionAlive, 1200000); // 20분 = 1200000ms

        // 컴포넌트가 언마운트될 때 setInterval 정리
        return () => clearInterval(intervalId);
    }, []);


    return(
        <>
            <div className="dmp-banner-container">
                <div className="dmp-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/sub-introimg02.jpg`} className="dmp-img02" alt="search Logo" />
                    <div className="banner-text">
                        <span className="intro-subName">데이터 관리 계획</span>
                    </div>
                    <div className="dmp-description">
                        <div className="dmp-description02">
                            <img  src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터 관리 계획 > 데이터 관리 계획 등록</span>
                        </div>
                    </div>
                </div>

                <div className="dmp-side-wrapper">
                    <div className="side-container">
                        {/* 사이드바 */}
                        <div className="side-sidebar">
                            <span className="side-name">작성 단계</span>
                            <div className="side-hisBtn-container">
                                <button id="side-hisBtn" onClick={DataHisBtn}>
                                    임시저장
                                </button>
                            </div>
                            <div className="start-side_line"></div>
                            <ul className="side-menu-list">
                                <li>
                                    <button onClick={() => handleScrollToSection(taskInfoRef)} className="side-menu-button">
                                        과제 정보
                                        <span className="required_color">*</span>
                                    </button>
                                </li>
                                <li>
                                    <button onClick={() => handleScrollToSection(researchMethodRef)} className="side-menu-button">
                                        연구 방법 요약
                                        <span className="required_color">*</span>
                                    </button>
                                </li>
                                <li>
                                    <button onClick={() => handleScrollToSection(researchImgRef)} className="side-menu-button">
                                        연구데이터 구성
                                    </button>
                                </li>
                                <li>
                                    <button onClick={() => handleScrollToSection(sidebarDataSetsRef)} className="side-menu-button">
                                        데이터세트
                                        <span className="required_color">*</span>
                                    </button>
                                </li>
                                {lastDataSets && lastDataSets.length > 0 ? (
                                    lastDataSets.map((data, index) => (
                                        <li key={`last-${data.Summary.DtaSetNo}`}>
                                            <button className="side-datasets-button"
                                                    onClick={() => handleScrollToElement(index)} // 클릭 시 스크롤 이동
                                            >
                                                {`- (${data.Summary.DtaSetNm})`}<br />
                                                {`${data.Summary.DtaNm}`}
                                            </button>
                                        </li>
                                    ))
                                ) : (
                                    newDataSummary.map((data, index) => (
                                        <li key={`new-${data.Summary.id}`}>
                                            <button className="side-datasets-button"
                                                    onClick={() => handleScrollToElement(lastDataSets && lastDataSets.length > 0 ? lastDataSets.length + index : index)} // 클릭 시 스크롤 이동
                                            >
                                                {`- (${data.Summary.DtaSetNm})`}<br />
                                                {`${data.Summary.DtaNm}`}
                                            </button>
                                        </li>
                                    ))
                                )}
                                <li>
                                    <button className="side-menu-button">
                                        생산 계획
                                        <span className="required_color">*</span>
                                    </button>
                                </li>
                                {generateProductionPlans().map((year) => (
                                    <li key={year}>
                                        <button
                                            className="side-datasets-button"
                                            onClick={() => handleYear(year)} // 클릭 시 연도 섹션으로 이동
                                        >
                                            {`- ${year}년 생산 계획`}
                                        </button>
                                    </li>
                                ))}
                                {/*<li>*/}
                                {/*    <button onClick={() => handleScrollToSection(analySideDataRef)} className="side-menu-button">*/}
                                {/*        분석 계획*/}
                                {/*        <span className="required_color">*</span>*/}
                                {/*    </button>*/}
                                {/*</li>*/}
                                <li>
                                    <button onClick={() => handleScrollToSection(openDataRef)} className="side-menu-button">
                                        공개 계획
                                        <span className="required_color">*</span>
                                    </button>
                                </li>
                                <li>
                                    <button onClick={() => handleScrollToSection(mgDataRef)} className="side-menu-button">
                                        관리 계획
                                        <span className="required_color">*</span>
                                    </button>
                                </li>
                                {/* 다른 항목도 동일하게 추가할 수 있음 */}
                            </ul>
                        </div>
                    </div>
                    <section className="contents-section">
                        <div className="management-plan-title">
                            <h1>데이터 관리 계획</h1>
                            <div>
                                <span className="required_color">*</span>
                                <span>필수 항목</span>
                            </div>
                        </div>
                        <div className="under_line" style={{width: '1066px'}}></div>
                        <div className="object-info">
                            <div className="object-info-title"    ref={taskInfoRef}>
                                <span className="info">과제 정보</span>
                                <span className="required_color">*</span>
                            </div>
                            <div className="task-title">
                                <div className="task-wrapper">
                                    <>
                                        <button className="check-obj" onClick={toggleObjCheck}>과제 선택</button>
                                        {showObjCheck && <TaskCheck />}
                                    </>

                                    {
                                        tastdataSets?.length > 0 || lastDataCellValue? (
                                            <div key={tastdataSets[0]?.DmpNo} className="objCheck-name">
                                                <input
                                                    type="text"
                                                    className="edit--box"
                                                    ref={taskRef}
                                                    value={createProjNo}
                                                    onChange={(e) => setCreateProjNo(e.target.value)}
                                                    readOnly
                                                    placeholder="과제번호"
                                                />
                                                <input
                                                    type="text"
                                                    className="edit--box"
                                                    ref={taskRef}
                                                    value={createProName}
                                                    onChange={(e) => setCreateProName(e.target.value)}
                                                    readOnly
                                                    placeholder="과제명"
                                                />
                                                <input
                                                    type="text"
                                                    className="edit--box"
                                                    ref={taskRef}
                                                    value={rspnEmpNm}
                                                    onChange={(e) => setRspnEmpNm(e.target.value)}
                                                    readOnly
                                                    placeholder="연구책임자"
                                                />
                                            </div>
                                        ) : (
                                            // 모든 조건이 만족되지 않을 경우 빈 입력 창을 보여줌
                                            <>
                                                <input
                                                    type="text"
                                                    ref={taskRef}
                                                    className="edit--box"
                                                    value={''}
                                                    placeholder="과제번호"
                                                />
                                                <input
                                                    type="text"
                                                    ref={taskRef}
                                                    className="edit--box"
                                                    value={''}
                                                    placeholder="과제명"
                                                />
                                                <input
                                                    type="text"
                                                    ref={taskRef}
                                                    className="edit--box"
                                                    value={''}
                                                    placeholder="연구책임자"
                                                />
                                            </>
                                        )}
                                    <>
                                        <button className="last-dmp" type="button" onClick={toggleLastDmp}>지난 DMP 불러오기</button>
                                        {showLastDmp && <LastDmpShow />}
                                        {/*<button className="history-dmp" type="button" onClick={toggleHistoryDmp}>임시저장 DMP 불러오기</button>*/}
                                        {/*{showHistory && <LastDmpShow setShowHistory = {showHistory}/>}*/}
                                    </>

                                </div>
                                <div>
                                    <span>* 과제 번호를 선택하면 과제 정보가 자동으로 입력됩니다.</span>
                                </div>
                            </div>
                        </div>
                        <div className="start_line"></div>
                        <div className="research-method-info">
                            <div className="object-info-title" ref={methodSummaryRef}>
                                <span className="info">연구 방법 요약</span>
                                <span className="required_color">*</span>
                            </div>
                            <div className="research-method-title">
                                    <textarea
                                        type="text"
                                        ref={researchMethodRef}
                                        value={methodSummary}
                                        onChange={(e)=> setMethodSummary(e.target.value)}
                                        className="edit--box"
                                        placeholder="연구방법을 서술형으로 작성하세요."
                                    />
                            </div>
                        </div>
                        <div className="start_line"></div>
                        <div className="configuring-research-data-info" ref={researchImgRef}>
                            <div className="object-info-title">
                                <span className="info">연구데이터 구성</span>
                            </div>
                            <div className="research-method-title">
                                <label htmlFor="fileDiagram">
                                    <img id="imgDiagram" src={imageSrc} alt="Diagram" />
                                </label>
                                <input
                                    type="file"
                                    id="fileDiagram"
                                    name="fileDiagram"
                                    style={{ display: 'none' }}
                                    onChange={handleImageChange}
                                />
                                <input type="text" id="txtDiagram" name="txtDiagram" style={{ display: 'none' }} />

                            </div>
                        </div>
                        <div className="start_line"></div>

                        <div className="data-set-container" >
                            {lastDataSets? (
                                // lastDataCellValue.SumData가 존재할 때
                                lastDataSets.map((item, index) => (
                                    <div className="data-set-info" key={index}>
                                        <div className="data-set-title-wrapper">
                                            <div className="data-set-title"
                                                 ref={(el) => {
                                                     dataRefs.current[index] = el; // 동적으로 ref 배열에 항목 추가
                                                 }}>
                                                <span className="info">데이터 세트</span>
                                                <span className="required_color">*</span>
                                                <button className="data-set-delete" type="button"  onClick={() => lastHandleDeleteDataSet(item.Summary.id || item.Summary.DtaSetNo)}>
                                                    <span>-</span>
                                                    <span>삭제</span>
                                                </button>
                                            </div>
                                            <div className="selectbox-container">
                                                <div className="selectbox-check">
                                                    <select
                                                        className="resultData"
                                                        value={item.Summary.DtaSetNm}
                                                        onChange={(e) => newSummaryhandleChange(item.Summary.DtaSetNo || item.Summary.id, 'DtaSetNm', e.target.value)}
                                                    >
                                                        <option value=''>-- 구분 --</option>
                                                        <option value="시료데이터">시료데이터</option>
                                                        <option value="방법데이터">방법데이터</option>
                                                        <option value="결과데이터">결과데이터</option>
                                                    </select>
                                                    <select
                                                        className="resultData"
                                                        id="selectBox"
                                                        value={item.Summary.DtaSetNmSub}
                                                        onChange={(e) => newSummaryhandleChange(item.Summary?.id, 'DtaSetNmSub', e.target.value)}
                                                        disabled={["방법데이터", "결과데이터"].includes(item.Summary?.DtaSetNm)} // 조건에 따라 비활성화
                                                        // onChange={(e) => newSummaryhandleChange(item.Summary.DtaSetNo || item.Summary.id, 'DtaSetNmSub', e.target.value)}
                                                    >
                                                        <option value=''>-- 선택 * 필수 --</option>
                                                        <option value="Food, Unprocessed">Food, Unprocessed</option>
                                                        <option value="Food, Processed">Food, Processed</option>
                                                        <option value="Food, Derived">Food, Derived</option>
                                                        <option value="Animal">Animal</option>
                                                        <option value="Human">Human</option>
                                                        <option value="Invertebrate">Invertebrate</option>
                                                        <option value="Microbe">Microbe</option>
                                                        <option value="Cell, Animal">Cell, Animal</option>
                                                        <option value="Cell, Human">Cell, Human</option>
                                                        <option value="Cell, Plant">Cell, Plant</option>
                                                        <option value="etc">etc</option>
                                                    </select>

                                                    <input
                                                        type="text"
                                                        className="edit--box"
                                                        value={item.Summary.DtaNm}
                                                        onChange={(e) => newSummaryhandleChange(item.Summary.DtaSetNo || item.Summary.id, 'DtaNm', e.target.value)}
                                                        id="data-type-name"
                                                        name="DtaNm"
                                                        placeholder="데이터 타입명"
                                                    />
                                                    <input type="text"
                                                           className="edit--box"
                                                           value={item.Summary.DtaDs}
                                                           onChange={(e) => newSummaryhandleChange(item.Summary.DtaSetNo || item.Summary.id, 'DtaDs', e.target.value)}
                                                           id="data-type-desc"
                                                           name="DtaDs"
                                                           placeholder="데이터 타입 설명"
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="toastui-wrapper">
                                            <DmpToastGrid MethodData={item.Summary.DtaSetNm} DateSets={item}/>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                // newDataSummary가 존재할 때

                                newDataSummary && newDataSummary.map((newSetData, index) => (
                                    <div className="data-set-info" key={newSetData.Summary?.id || index}> {/* 고유한 id를 사용하는 것이 더 안전 */}
                                        <div className="data-set-title-wrapper">
                                            <div className="test-summary">
                                                <div
                                                    className="data-set-title"
                                                    ref={(el) => {
                                                        dataRefs.current[index] = el; // 동적으로 ref 배열에 항목 추가
                                                    }}
                                                >
                                                    <span className="info">데이터 세트</span>
                                                    <span className="required_color">*</span>
                                                </div>
                                                <button className="data-set-delete" type="button" onClick={() => newHandleDeleteDataSet(newSetData.Summary?.id)}>
                                                    <span>-</span>
                                                    <span>삭제</span>
                                                </button>
                                            </div>
                                            <div className="selectbox-container" >
                                                <div className="selectbox-check">
                                                    <select
                                                        className="resultData"
                                                        value={newSetData.Summary?.DtaSetNm}
                                                        onChange={(e) => newSummaryhandleChange(newSetData.Summary?.id, 'DtaSetNm', e.target.value)}
                                                    >
                                                        <option value=''>-- 구분 --</option>
                                                        <option value="시료데이터">시료데이터</option>
                                                        <option value="방법데이터">방법데이터</option>
                                                        <option value="결과데이터">결과데이터</option>
                                                    </select>
                                                    <select
                                                        className="resultData"
                                                        id="selectBox"
                                                        value={newSetData.Summary?.DtaSetNmSub}
                                                        onChange={(e) => newSummaryhandleChange(newSetData.Summary?.id, 'DtaSetNmSub', e.target.value)}
                                                        disabled={["방법데이터", "결과데이터"].includes(newSetData.Summary?.DtaSetNm)} // 조건에 따라 비활성화
                                                    >
                                                        <option value=''>-- 선택 * 필수 --</option>
                                                        <option value="Food, Unprocessed">Food, Unprocessed</option>
                                                        <option value="Food, Processed">Food, Processed</option>
                                                        <option value="Food, Derived">Food, Derived</option>
                                                        <option value="Animal">Animal</option>
                                                        <option value="Human">Human</option>
                                                        <option value="Invertebrate">Invertebrate</option>
                                                        <option value="Microbe">Microbe</option>
                                                        <option value="Cell, Animal">Cell, Animal</option>
                                                        <option value="Cell, Human">Cell, Human</option>
                                                        <option value="Cell, Plant">Cell, Plant</option>
                                                        <option value="etc">etc</option>
                                                    </select>

                                                    <input
                                                        type="text"
                                                        className="edit--box"
                                                        value={newSetData.Summary?.DtaNm}
                                                        onChange={(e) => newSummaryhandleChange(newSetData.Summary?.id, 'DtaNm', e.target.value)}
                                                        id="data-type-name"
                                                        name="DtaNm"
                                                        placeholder="데이터 타입명"
                                                    />
                                                    <input
                                                        type="text"
                                                        className="edit--box"
                                                        value={newSetData.Summary?.DtaDs}
                                                        onChange={(e) => newSummaryhandleChange(newSetData.Summary?.id, 'DtaDs', e.target.value)}
                                                        id="data-type-desc"
                                                        name="DtaDs"
                                                        placeholder="데이터 타입 설명"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="toastui-wrapper" ref={newDataSetRef}>
                                            <DmpToastGrid  MethodData={newSetData.Summary?.DtaSetNm} DateSets={newSetData} />
                                        </div>
                                    </div>
                                ))
                            )}
                            <button className="data-set-add" ref={combinedRef} type="button" onClick={handleCreateAddDataSet}>{`+ 데이터 세트 양식 추가`}</button>
                        </div>
                        <div className="start_line" ></div>

                        <div className="data-management-info">
                            {generateProductionPlans().map((year) => (
                                <div
                                    className="production-plan"
                                    key={year}
                                    ref={(el) => {
                                        if (el) {
                                            productionPlanRefs.current[year] = el; // 각 연도별로 ref 할당
                                        }
                                    }}
                                >
                                    <div className="object-info-title">
                                        <span className="info">{year}년 생산 계획</span>
                                        <span className="required_color">*</span>
                                    </div>
                                    {lastDataCellValue ? (
                                        <DmpDataProductionPlan
                                            updateProductionPn={lastDataCellValue}
                                            year={year}
                                            setAllData={setAllData}
                                            id={`production-plan-${year}`}
                                            proplanDataTypes={proplanDataTypes}
                                        />
                                    ) : (
                                        <DmpDataProductionPlan
                                            year={year}
                                            id={`production-plan-${year}`}
                                            setAllData={setAllData}
                                            proplanDataTypes={proplanDataTypes}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                        {/*<div className="start_line"></div>*/}

                        {/*<div className="data-analysis-info" style={{gap: '4px'}} ref={analySideDataRef}>*/}
                        {/*    <div className="object-info-title">*/}
                        {/*        <span className="info">분석계획</span>*/}
                        {/*        <span className="required_color">*</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="research-method-title">*/}
                        {/*                <textarea type="text"*/}
                        {/*                          className="edit--box"*/}
                        {/*                          ref={analysisPlanRef}*/}
                        {/*                          value={analysisPlan}*/}
                        {/*                          onChange={(e) => setAnalysisPlan(e.target.value)}*/}
                        {/*                          placeholder="연구데이터 분석 계획을 서술형으로 작성하세요."></textarea>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="start_line"></div>

                        <div className="data-public-info" ref={openDataRef}>
                            <div ref={publicPlanRef}>
                                <div className="object-info-title">
                                    <span className="info">공개 계획</span>
                                    <span className="required_color">*</span>
                                </div>
                                {
                                    lastDataCellValue ? (
                                        <DataPublicGrid updateProductionPn ={lastDataCellValue}  proplanDataTypes = {proplanDataTypes} />
                                    ) : (
                                        <DataPublicGrid  proplanDataTypes = {proplanDataTypes}/>
                                    )
                                }
                            </div>
                        </div>

                        <div className="start_line"></div>
                        <div className="data-management-info" ref={mgDataRef}>
                            <div ref={managementPlanRef}>
                                <div className="object-info-title">
                                    <span className="info">관리 계획</span>
                                    <span className="required_color">*</span>
                                </div>
                                {
                                    lastDataCellValue ? (
                                        <DataManagementGrid updateProductionPn={lastDataCellValue}  proplanDataTypes = {proplanDataTypes} />
                                    ): (
                                        <DataManagementGrid  proplanDataTypes = {proplanDataTypes}/>
                                    )
                                }
                            </div>
                        </div>
                        <div className="start_line"></div>

                        <div className="save-container">
                            <button id="dmp_list" onClick={() => navigate(`/dmpManagement/dmpList`)}>
                                목록
                            </button>
                            <button id="save-btn" onClick={DataSaveBtn}>
                                저장
                            </button>
                            <button id="his-btn" onClick={DataHisBtn}>
                                임시저장
                            </button>
                        </div>
                    </section>
                </div>



            </div>
        </>
    )
}
export default DmpcreatePage;