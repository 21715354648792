import React, {useCallback, useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import '../submitStatus/dataSubmitStatus.css';
import api from "../../api";
import {useSearchHomeStore} from "../../stores/dataSetsStore";
import {debounce} from "lodash";
function DmpAccessCheck() {
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); //현재페이지

    const pageSize = 10; // 한 페이지에 표시할 데이터 수
    const pageGroupSize = 10; // 한 번에 표시할 페이지 번호 개수 (1-10, 11-20 등)


    const [listCount, setListCount] = useState(0); // 총 데이터 개수

    const [cntPrj, setCntPrj] = useState(0);
    const [cntSave, setCntSave] = useState(0);
    const [cntSubmit, setCntSubmit] = useState(0);

    const [totalPages, setTotalPages] = useState(0); // 전체 페이지 수

    const [btnPage, setBtnPage] = useState(1);

    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState('');
    const [years, setYears] = useState([]);

    const [searchTerm, setSearchTerm] = useState(""); // 검색어 상태

    const adminMenu = [
        { 'id': 1, 'name': "공개시기 관리(캘린더)", 'path': "/adminPage" },
        { 'id': 2, 'name': "과제 목록", 'path': "/adminPage/objView" },
        { 'id': 3, 'name': "데이터 제출 현황", 'path': "/adminPage/submintStatus" },
        { 'id': 4, 'name': "데이터 제출 목록 일괄", 'path': "/adminPage/dataSubmitList" },
        { 'id': 5, 'name': "데이터 제출 상태 변경", 'path': "/adminPage/dataSubmitStatusChange" },
        { 'id': 6, 'name': "DMP 작성 현황 및 제출 상태 변경", 'path': "/adminPage/dmpSubmitStatus" },
        { 'id': 7, 'name': "데이터 관리 계획 접근권한(User별)", 'path': "/adminPage/userManagement" },
        { 'id': 8, 'name': "데이터 관리 계획 접근권한(과제별)", 'path': "/adminPage/dmpAccessCheck" },
        { 'id': 9, 'name': "생산계획 누락현황 확인", 'path': "/adminPage/productionPlanMng" },
        { 'id': 10, 'name': "신청서 관리 페이지(신청서 정보)", 'path': "/adminPage/applicationManagement" },
    ];

    const location = useLocation();
    const activeMenuId = adminMenu.find((menu) => menu.path === location.pathname)?.id || null;


    // 2022년부터 현재 연도까지의 배열을 생성
    useEffect(() => {
        const startYear = 2022;
        const yearList = [];
        for (let year = startYear; year <= currentYear; year++) {
            yearList.push(year);
        }
        setYears(yearList);
    }, [currentYear]);

    const dataSendPage = async (searchTerm= "", year = "", page = 1) => {
        const start = (page - 1) * pageSize + 1;

        let endPoit = `/api/m/dmpauth?kw=${searchTerm}&year=${year}&ps=${start}&pl=${pageSize}`
        try {
            const res = await api.get(endPoit, {
                withCredentials: true,
            });
            setTableData(res.data.item);
            setListCount(res.data.pages.Total); // 총 데이터 개수 설정
            setTotalPages(Math.ceil(res.data.pages.Total / pageSize)); // 전체 페이지 수 계산

        }catch (error) {
            console.err('api 에러를 확인해주세요.', error);
        }
    }

    useEffect(() => {
        dataSendPage(searchTerm, selectedYear, currentPage)
    }, [currentPage, selectedYear, searchTerm]);


    // useEffect(() => {
    //     if (searchData) {
    //         setSearchTerm(searchData); // 검색어 상태를 home에서 받아온 값으로 설정
    //         setCurrentPage(1); // 검색 시 페이지를 처음으로 리셋
    //         dataSendPage(searchData, 1); // 받아온 검색어로 바로 데이터를 조회
    //     }
    // }, [searchData]); // searchData가 변경될 때만 실행



    // const debouncedFetchData = useCallback(
    //     debounce((value) => {
    //         dataSendPage(value, selectedYear, 1);
    //     }, 300),
    //     [selectedYear] // selectedYear가 변경될 때 debounce 함수 갱신
    // );

    const handlerInputChange = (event) => {
        const value = event.target.value;

        if (value.length > 14) {
            alert("입력 가능한 최대 길이는 14자입니다.");
            setSearchTerm(""); // 값을 초기화
            event.target.value = ""; // input 필드도 초기화
            return;
        }

        setSearchTerm(value); // 상태 업데이트
        setCurrentPage(1); // 페이지를 초기화
        dataSendPage(value, selectedYear,1); // 최신 값을 사용하여 즉시 데이터 가져오기
    };

    const handleYearChange = (e) => {
        const year = e.target.value;
        setSelectedYear(year);
        setCurrentPage(1); // 페이지 초기화
        setBtnPage(1);
    };

    // 페이지네이션 버튼 클릭
    const handerbtn = (pageNumber, i) => {
        setCurrentPage(pageNumber); // 상태 업데이트
        dataSendPage(searchTerm,selectedYear, pageNumber); // 최신 값을 사용하여 즉시 데이터 가져오기
    };

    const renderPagination = () => {
        const startPage = Math.floor((currentPage - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

        const pages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <button className="pagination-button" onClick={() => handerbtn(startPage - 1)}>
                        이전
                    </button>
                )}
                {pages.map((page) => (
                    <button
                        key={page}
                        className={`pagination-button ${page === currentPage ? "active" : ""}`}
                        onClick={() => handerbtn(page)}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages && (
                    <button className="pagination-button" onClick={() => handerbtn(endPage + 1)}>
                        다음
                    </button>
                )}
            </div>
        );
    };

    return(
        <>
            <div className="adminpage-container">
                <div className="small-title">
                    <img src={`${process.env.PUBLIC_URL}/adminpage.png`} className="adminlist-img02" alt="search Logo" />
                    <div className="adminhd-line"></div>
                    <h2 className="menu-name">관리자 메뉴</h2>
                </div>
                <div className="menu-list">
                    <div className="menu-title">
                        {adminMenu.map((item) => (
                            <React.Fragment key={item.id}>
                                <div className={`menu-item ${activeMenuId === item.id ? "active" : ""}`}>
                                    <Link to={item.path}>
                                        {item.name}
                                    </Link>
                                </div>
                                <div className="admin-under"></div>
                            </React.Fragment>
                        ))}
                    </div>
                    <div className="admin-line"></div>
                    <div className="year-name">
                        <div className="submin-container">
                            <h3>
                                데이터 관리 계획 접근권한(과제별)
                            </h3>
                            <h4>※ 조회하고자 하는 연도를 아래에서 선택해주세요.</h4>
                            <div>
                                <div className="accessCheck-year">
                                    과제수행년도
                                    <select value={selectedYear} className="submin-year" onChange={handleYearChange}>
                                        <option value="">전체</option>
                                        {years.map((year) => (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="admin-searchbox">
                                <img src={`${process.env.PUBLIC_URL}/icon_search.png`} alt="search Logo" />
                                <input type="text" placeholder="검색어를 입력해주세요." value={searchTerm}
                                       onChange={handlerInputChange} />
                                <h4>※ 과제번호, 과제명, 연책 키워드로 검색이 가능합니다.</h4>
                            </div>
                        </div>
                        <div className="title-line02"></div>
                        <div className="history-select">
                            <div>총 {listCount}건</div>
                        </div>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                            <tr className="dmplist-title">
                                <th>과제번호</th>
                                <th>과제수행년도</th>
                                <th>과제명</th>
                                <th>연책</th>
                                <th>데이터담당자명</th>
                                <th>연차시작일</th>
                                <th>연차종료일</th>
                                <th>접근권한자</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tableData?.map((row, index) => (
                                <tr key={index} className="dmplist-Data">
                                    <td>{row.ProjNo}</td>
                                    <td>{row.AnnlYy}</td>
                                    <td>{row.ProjNm}</td>
                                    <td>{row.RspnEmpNm}({row.RspnEmpNo})</td>
                                    <td>{row.DataEmpNm}({row.DataEmpNo})</td>
                                    <td>{row.ProjAnnlDateSt}</td>
                                    <td>{row.ProjAnnlDateEd}</td>
                                    <td className="auth-user-cell">{row.AuthUser}</td>

                                </tr>
                            ))}
                            </tbody>
                        </table>
                        {
                            renderPagination()
                        }
                    </div>

                </div>
            </div>
        </>
    )
}

export  default DmpAccessCheck;