import React, {useCallback, useEffect, useState} from "react";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import '../submitStatus/dataSubmitStatus.css';
import api from "../../api";
import {useSearchHomeStore} from "../../stores/dataSetsStore";
import {debounce} from "lodash";
function ApplicationManagementPage() {
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); //현재페이지
    const pageSize = 10; // 한 페이지에 표시할 데이터 수
    const pageGroupSize = 10; // 한 번에 표시할 페이지 번호 개수 (1-10, 11-20 등)

    const [listCount, setListCount] = useState(0); // 총 데이터 개수
    const [totalPages, setTotalPages] = useState(0); // 전체 페이지 수

    const navigate = useNavigate();

    const [searchTerm, setSearchTerm] = useState(""); // 검색어 상태

    const [searchParams, setSearchParams] = useSearchParams(); // U

    const dataSendPage = async (searchTerm= "", page = 1) => {

        const start = (page - 1) * pageSize + 1; // 페이지 시작 위치 계산

        let endPoit = `/api/m/getapp?kw=${searchTerm}&ps=${start}&pl=${pageSize}`
        try {
            const res = await api.get(endPoit, {
                withCredentials: true,
            });
            setTableData(res.data.item);
            setListCount(res.data.pages.Total); // 총 데이터 개수 설정
            setTotalPages(Math.ceil(res.data.pages.Total / pageSize)); // 전체 페이지 수 계산

        }catch (error) {
            console.err('api 에러를 확인해주세요.', error);
        }
    }


    useEffect(() => {
        dataSendPage(searchTerm, currentPage)
    }, [currentPage, searchTerm]);


    // 컴포넌트 마운트 시 URL 쿼리 파라미터에서 페이지와 검색어를 가져옴
    useEffect(() => {
        const pageFromParams = parseInt(searchParams.get("page")) || 1; // 쿼리에서 페이지 가져오기
        const searchFromParams = searchParams.get("search") || ""; // 쿼리에서 검색어 가져오기

        setCurrentPage(pageFromParams);
        setSearchTerm(searchFromParams);

        dataSendPage(searchFromParams, pageFromParams);
    }, [searchParams]);


    // const debouncedFetchData = useCallback(
    //     debounce((value, page) => dataSendPage(value, page), 300), // 1초 동안 입력이 없으면 호출
    //     []
    // );

    const handlerInputChange = (event) => {
        const value = event.target.value;

        // 입력된 값의 길이가 14자를 넘으면 alert를 띄우고 값을 초기화
        if (value.length > 14) {
            alert("입력 가능한 최대 길이는 14자입니다.");
            setSearchTerm(""); // 값을 초기화
            event.target.value = ""; // input 필드도 초기화
            return;
        }
        setSearchTerm(value); // 상태 업데이트
        setCurrentPage(1); // 페이지를 초기화
        // dataSendPage(value,1); // 최신 값을 사용하여 즉시 데이터 가져오기
        setSearchParams({ page: 1, search: value }); //
    };

    const handerbtn = (pageNumber, i) => {
        setCurrentPage(pageNumber);
        // dataSendPage(searchTerm, pageNumber); // 최신 값을 사용하여 즉시 데이터 가져오기
        setSearchParams({ page: pageNumber, search: searchTerm }); //
    };

    const renderPagination = () => {
        const startPage = Math.floor((currentPage - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

        const pages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <button className="pagination-button" onClick={() => handerbtn(startPage - 1)}>
                        이전
                    </button>
                )}
                {pages.map((page) => (
                    <button
                        key={page}
                        className={`pagination-button ${page === currentPage ? "active" : ""}`}
                        onClick={() => handerbtn(page)}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages && (
                    <button className="pagination-button" onClick={() => handerbtn(endPage + 1)}>
                        다음
                    </button>
                )}
            </div>
        );
    };


    const adminMenu = [
        { 'id': 1, 'name': "공개시기 관리(캘린더)", 'path': "/adminPage" },
        { 'id': 2, 'name': "과제 목록", 'path': "/adminPage/objView" },
        { 'id': 3, 'name': "데이터 제출 현황", 'path': "/adminPage/submintStatus" },
        { 'id': 4, 'name': "데이터 제출 목록 일괄", 'path': "/adminPage/dataSubmitList" },
        { 'id': 5, 'name': "데이터 제출 상태 변경", 'path': "/adminPage/dataSubmitStatusChange" },
        { 'id': 6, 'name': "DMP 작성 현황 및 제출 상태 변경", 'path': "/adminPage/dmpSubmitStatus" },
        { 'id': 7, 'name': "데이터 관리 계획 접근권한(User별)", 'path': "/adminPage/userManagement" },
        { 'id': 8, 'name': "데이터 관리 계획 접근권한(과제별)", 'path': "/adminPage/dmpAccessCheck" },
        { 'id': 9, 'name': "생산계획 누락현황 확인", 'path': "/adminPage/productionPlanMng" },
        { 'id': 10, 'name': "신청서 관리 페이지(신청서 정보)", 'path': "/adminPage/applicationManagement" },
    ];
    const handleRowClick = (AppNo) => {
        navigate(`/adminPage/applicationManagement/detail/${AppNo}`);
    }

    const location = useLocation();
    const activeMenuId = adminMenu.find((menu) => menu.path === location.pathname)?.id || null;


    return(
        <>
            <div className="adminpage-container">
                <div className="small-title">
                    <img src={`${process.env.PUBLIC_URL}/adminpage.png`} className="adminlist-img02" alt="search Logo" />
                    <div className="adminhd-line"></div>
                    <h2 className="menu-name">관리자 메뉴</h2>
                </div>
                <div className="menu-list">
                    <div className="menu-title">
                        {adminMenu.map((item) => (
                            <React.Fragment key={item.id}>
                                <div className={`menu-item ${activeMenuId === item.id ? "active" : ""}`}>
                                    <Link to={item.path}>
                                        {item.name}
                                    </Link>
                                </div>
                                <div className="admin-under"></div>
                            </React.Fragment>
                        ))}
                    </div>
                    <div className="admin-line"></div>
                    <div className="year-name">
                        <div className="submin-container">
                            <h3>
                                신청서 관리 페이지(신청서 정보)
                            </h3>
                            <div className="history-select">
                                <div>총 {listCount}건</div>
                            </div>
                            <div className="admin-searchbox">
                                <img src={`${process.env.PUBLIC_URL}/icon_search.png`} alt="search Logo" />
                                <input type="text" placeholder="검색어를 입력해주세요." value={searchTerm}
                                       onChange={handlerInputChange} />
                                <h4>※ 사번 사원 키워드로 검색이 가능합니다.</h4>
                            </div>
                        </div>
                        <div className="title-line02"></div>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                            <tr className="dmplist-title">
                                <th>책임자 사번(명)</th>
                                <th>주제</th>
                                <th>이용 목적</th>

                                <th>작성일자</th>
                                <th>상세보기</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tableData?.map((row) => (
                                <tr key={row.AppNo} className="dmplist-Data">
                                    <td>[{row.RespUserNo}]<br/>{row.RespUserNm}</td>
                                    <td>{row.Subject}</td>
                                    <td>{row.Purpose}</td>

                                    <td>{row.InsDt}</td>
                                    <td>
                                        <button className="dataStatus-management" onClick={() => handleRowClick(row.AppNo)}>신청자 상세보기</button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        {
                            renderPagination()
                        }
                    </div>

                </div>
            </div>
        </>
    )
}

export  default ApplicationManagementPage;