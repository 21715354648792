import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import '../submitStatus/dataSubmitStatus.css';
import Cookies from 'js-cookie';
import api from "../../api";

function UserManagementDetail() {
    const { UserAuthSeq } = useParams();
    const [userDetail, setUserDetail] = useState([]);
    const [listCount, setListCount] = useState(0); // 총 데이터 개수
    const [changedProjNos, setChangedProjNos] = useState([]); // 변경된 ProjNo 리스트

    const navigate = useNavigate();

    const userDetailPages = async () => {
        let endPoint = `/api/m/userProjAuthStatus?UserAuthSeq=${UserAuthSeq}`;

        try {
            const res = await api.get(endPoint, {
                withCredentials: true,
            });
            setUserDetail(res.data.item);
            setListCount(res.data.total);

            const initialProjNos = res.data.item
                .filter((row) => row.AuthStatus === "Y")
                .map((row) => row.ProjNo);


            setChangedProjNos((prev) => [...new Set([...prev, ...initialProjNos])]);
        } catch (error) {
            console.error('API 에러 입니다.');
        }
    };

    useEffect(() => {
        userDetailPages();
    }, [UserAuthSeq]);

    const adminMenu = [
        { 'id': 1, 'name': "공개시기 관리(캘린더)", 'path': "/adminPage" },
        { 'id': 2, 'name': "과제 목록", 'path': "/adminPage/objView" },
        { 'id': 3, 'name': "데이터 제출 현황", 'path': "/adminPage/submintStatus" },
        { 'id': 4, 'name': "데이터 제출 목록 일괄", 'path': "/adminPage/dataSubmitList" },
        { 'id': 5, 'name': "데이터 제출 상태 변경", 'path': "/adminPage/dataSubmitStatusChange" },
        { 'id': 6, 'name': "DMP 작성 현황 및 제출 상태 변경", 'path': "/adminPage/dmpSubmitStatus" },
        { 'id': 7, 'name': "데이터 관리 계획 접근권한(User별)", 'path': "/adminPage/userManagement" },
        { 'id': 8, 'name': "데이터 관리 계획 접근권한(과제별)", 'path': "/adminPage/dmpAccessCheck" },
        { 'id': 9, 'name': "생산계획 누락현황 확인", 'path': "/adminPage/productionPlanMng" },
        { 'id': 10, 'name': "신청서 관리 페이지(신청서 정보)", 'path': "/adminPage/applicationManagement" },
    ];
    const handleAuthStatusChange = (index, newStatus) => {
        setUserDetail((prevData) =>
            prevData.map((row, i) => {
                if (i === index) {
                    if (newStatus === "Y" && !changedProjNos.includes(row.ProjNo)) {
                        setChangedProjNos((prev) => [...prev, row.ProjNo]);
                    } else if (newStatus === "N" && changedProjNos.includes(row.ProjNo)) {
                        setChangedProjNos((prev) => prev.filter((projNo) => projNo !== row.ProjNo));
                    }
                    return { ...row, AuthStatus: newStatus };
                }
                return row;
            })
        );
    };

    const authChangeHandler = async () => {

        const formattedProjNos = changedProjNos.map(projNo => `'${projNo}'`).join(",");
        try {
            const csrfToken = Cookies.get('XSRF-TOKEN');
            await api.patch(`/api/m/userProjArr/${UserAuthSeq}/${formattedProjNos}`, {},
                {
                    withCredentials: true,
                    headers: {
                        'X-XSRF-TOKEN': csrfToken,
                    }
            })
                .then(() => {
                    alert("권한 변경이 성공되었습니다.");
                    navigate(-1)
                });
        } catch (err) {
            console.error("API 에러:", err);
        }
    };

    const location = useLocation();
    const activeMenuId = adminMenu.find((menu) => menu.path === location.pathname)?.id || 7;

    return (
        <div className="adminpage-container">
            <div className="small-title">
                <img src={`${process.env.PUBLIC_URL}/adminpage.png`} className="adminlist-img02" alt="search Logo" />
                <div className="adminhd-line"></div>
                <h2 className="menu-name">관리자 메뉴</h2>
            </div>
            <div className="menu-list">
                <div className="menu-title">
                    {adminMenu.map((item) => (
                        <React.Fragment key={item.id}>
                            <div className={`menu-item ${activeMenuId === item.id ? "active" : ""}`}>
                                <Link to={item.path}>
                                    {item.name}
                                </Link>
                            </div>
                            <div className="admin-under"></div>
                        </React.Fragment>
                    ))}
                </div>
                <div className="admin-line"></div>
                <div className="year-name">
                    <div className="submin-container">
                        <h3>데이터 관리 계획 접근권한(User별) 상세</h3>
                        <div className="history-select">
                            <div>총 {listCount}건</div>
                        </div>
                        <div className="auth-container">
                            <textarea
                                placeholder="권한 정보 리스트"
                                value={changedProjNos.map((projNo) => `'${projNo}'`).join(", ")}
                                readOnly
                                style={{ width: '100%', height: '52px' }}
                            />
                            <button className="auth-changebtn" onClick={authChangeHandler}>권한 변경</button>
                        </div>
                    </div>
                    <div className="title-line02"></div>
                    <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '26px' }}>
                        <thead>
                        <tr className="dmplist-title">
                            <th>과제번호</th>
                            <th>과제이름</th>
                            <th>연차</th>
                            <th>연구책임자 사번</th>
                            <th>연구책임자명</th>
                            <th>권한정보</th>
                        </tr>
                        </thead>
                        <tbody>
                        {userDetail.map((row, index) => (
                            <tr key={index} className="dmplist-Data">
                                <td>{row.ProjNo}</td>
                                <td>{row.ProjNm}</td>
                                <td>{row.ProjAnnl}년차</td>
                                <td>{row.RspnEmpNo}</td>
                                <td>{row.RspnEmpNm}</td>
                                <td>
                                    <label>
                                        <input
                                            className="auth-radio"
                                            type="radio"
                                            name={`authStatus-${index}`}
                                            value="Y"
                                            checked={row.AuthStatus === "Y"}
                                            onChange={() => handleAuthStatusChange(index, "Y")}
                                        />
                                        Y
                                    </label>
                                    <label style={{ marginLeft: '10px' }}>
                                        <input
                                            className="auth-radio"
                                            type="radio"
                                            name={`authStatus-${index}`}
                                            value="N"
                                            checked={row.AuthStatus === "N"}
                                            onChange={() => handleAuthStatusChange(index, "N")}
                                        />
                                        N
                                    </label>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default UserManagementDetail;
