import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../dmpManagement/dmpList/dmplist.css';
import api from '../api';

const DataRoom = () => {
    const [boardValue, setBoardValue] = useState([]);

    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
    const [btnPage, setBtnPage] = useState(1);

    const [totalPages, setTotalPages] = useState(0); // 전체 페이지 수
    const pageSize = 10; // 한 페이지에 표시할 데이터 개수
    const pageGroupSize = 10; // 한 그룹에 표시할 페이지 번호 개수 (1-10, 11-20 등)

    const navigate = useNavigate();
    const [selectedCategory, setSelectedCategory] = useState(0); // 기본 선택된 카테고리 (예: "2" - 교육/세미나)

    // API에서 권한 확인
    const [authCheck, setAuthCheck] = useState(null);

    useEffect(() => {
        const checkAdminStatus = async () => {
            try {
                const res = await api.get("/api/auth/ckeckadmin", { withCredentials: true });
                if (res.status === 200) {
                    setAuthCheck(res.data); // "Y" 또는 "N"
                } else {
                    setAuthCheck("N");
                }
            } catch (error) {
                setAuthCheck("N");
                console.error("권한 확인 오류:", error);
            }
        };

        checkAdminStatus();
    }, []);

    // 리스트 불러오기
    const fetchData = async (boardSeq = selectedCategory, page = currentPage) => {
        try {
            const res = await api.get(`/api/board/list?seq=${boardSeq}&ps=${page}&pl=${pageSize}`, {
                withCredentials: true,
            });
            setBoardValue(res.data.list);
            setTotalPages(Math.ceil(res.data.pages.total / pageSize)); // 전체 페이지 수 계산
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // `currentPage` 또는 `selectedCategory`가 변경될 때 리스트 다시 불러오기
    useEffect(() => {
        fetchData();
    }, [currentPage, selectedCategory]);

    // 페이지네이션 핸들러
    const handerbtn = (pageNumber, i) => {
        setCurrentPage(pageNumber);
        setBtnPage(i);
    };

    // 페이지네이션 렌더링
    const renderPagination = () => {
        const startPage = Math.floor((btnPage - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
            const pageNumber = i;
            pages.push(
                <button
                    key={i}
                    className={`pagination-button ${i === currentPage ? 'active' : ''}`}
                    onClick={() => handerbtn(pageNumber, i)}
                >
                    {i}
                </button>
            );
        }

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <button className="pagination-button" onClick={() => setBtnPage(startPage - 1)}>
                        이전
                    </button>
                )}
                {pages}
                {endPage < totalPages && (
                    <button className="pagination-button" onClick={() => setBtnPage(endPage + 1)}>
                        다음
                    </button>
                )}
            </div>
        );
    };

    // Row 클릭 시 상세 페이지로 이동
    const handleRowClick = (BoardSeq, Seq) => {
        navigate(`/board/boardView?BoardSeq=${BoardSeq}&seq=${Seq}`);
    };

    // 새글 작성 페이지로 이동
    const newWriteHandler = () => {
        navigate(`/board/write/${selectedCategory}`, {
            state: { fromBoardSeq: selectedCategory },
        });
    };

    // 카테고리 변경 핸들러
    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
        setCurrentPage(1); // 카테고리 변경 시 첫 페이지로 이동
    };

    return (
        <div className="dmpReallist-container">
            <div className="dmpReallist-banner-img">
                <img src={`${process.env.PUBLIC_URL}/sub-introimg01.jpg`} className="dmpReallist-img02" alt="search Logo" />
                <div className="banner-Realtext">
                    <span className="intro-subName">자료실</span>
                </div>
                <div className="dmpReallist-description">
                    <div className="dmpReallist-description02">
                        <img src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                        <span> > 소개 > 자료실</span>
                    </div>
                </div>
            </div>

            <div className="dmpReallist-inputbox"></div>
            <div className="board-input-box">
                {authCheck === "Y" && (
                    <>
                        카테고리 선택 : <select
                            style={{ height: '37px', fontSize: '13px', borderRadius: '8px', marginLeft: '20px' }}
                            value={selectedCategory}
                            onChange={handleCategoryChange}
                        >
                            <option value="0">전체</option>
                            <option value="2">교육/세미나</option>
                            <option value="3">법령/정책자료</option>
                            <option value="4">자료실</option>
                        </select>
                        <img
                            className="newimgCur"
                            src={`${process.env.PUBLIC_URL}/btn_newwrite.png`}
                            onClick={newWriteHandler}
                            alt="new write"
                        />
                    </>
                )}
            </div>

            <div className="dmpReallist-start_line"></div>

            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                <tr className="dmpReallist-title">
                    <th>카테고리</th>
                    <th>제목</th>
                    <th>작성자</th>
                    <th>등록일</th>
                    <th>조회</th>
                </tr>
                </thead>
                <tbody>
                {boardValue?.map((row, index) => (
                    <tr className="dmpReallist-Data" key={index}>
                        <td>{row.BoardNm}</td>
                        <td style={{ cursor: 'pointer' }} onClick={() => handleRowClick(row.BoardSeq, row.BoardListSeq)}>
                            {row.Title}
                        </td>
                        <td>{row.UserNm}</td>
                        <td className="boards-page">{row.InsDt}</td>
                        <td>{row.ReadCnt}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            {renderPagination()}
        </div>
    );
};

export default DataRoom;
