import React, { useRef, useState, useEffect } from 'react';
import Grid from '@toast-ui/react-grid';
import 'tui-grid/dist/tui-grid.css';
import './toastGrid.css';
import { useToastSetMethodStore, useToastSetResultStore, useToastSetStore } from "../stores/lastDmpData";

const ToastGrid = ({ MethodData, DateSets, ModifySets,  DmpFlg, HisData, viewParams, SubmitStatus}) => {

    console.log("데이터 세트 확인>" ,MethodData, DateSets, ModifySets,  DmpFlg, HisData, viewParams, SubmitStatus);

    const gridRef = useRef(null);
    const [sampleData, setSampleData] = useState([]); // 시료데이터 저장 배열
    const [methodData, setMethodData] = useState([]); // 방법데이터 저장 배열
    const [resultData, setResultData] = useState([]); // 결과데이터 저장 배열

    const { setToastDataSets } = useToastSetStore();
    const { setToastMethodDataSets } = useToastSetMethodStore();
    const { setToastResultDataSets } = useToastSetResultStore();

    // Custom editor for handling shift + Enter
    class CustomTextEditor {
        constructor(props) {
            const el = document.createElement('textarea');
            el.style.width = '100%';
            el.style.height = '100%';
            el.value = String(props.value);
            this.el = el;
        }

        getElement() {
            return this.el;
        }

        getValue() {
            return this.el.value;
        }

        mounted() {
            this.el.focus();
            this.el.addEventListener('keydown', (e) => {
                if (e.key === 'Enter' && e.shiftKey) {
                    e.stopPropagation();  // Prevent grid from interpreting Enter as row submission
                }
            });
        }
    }

    // Columns definition with custom editor for description
    const columns = MethodData === "방법데이터" ? [
        { header: 'METHOD INDEX', name: 'index',   editor: DmpFlg === 'Y' || HisData || viewParams  ? false : 'text', width: 200 },
        { header: 'METHOD NAME', name: 'name',   editor: DmpFlg === 'Y' || HisData || viewParams ? false : 'text', width: 200 },
        {
            header: 'DATATYPE_DESCRIPTION',
            name: 'methoddescription',
            editor: DmpFlg === 'Y' || HisData || viewParams ? false : CustomTextEditor,
            width: 400,
            lineHeight: '1.5',
            whiteSpace: 'pre-wrap'
        },
        {
            name: 'delete',
            header: 'Delete',
            formatter: ({ row }) =>
                DmpFlg === 'Y' || viewParams
                    ? ''
                    : `<button class="btn-delete" data-row-key="${row.rowKey}">삭제</button>`, // 삭제 불가능할 경우 빈 문자열
        }
    ] : [
        { header: 'FIELD', name: 'field',  editor: DmpFlg === 'Y' || HisData || viewParams ? false : 'text' , width: 200 },
        { header: 'TYPE', name: 'type',    editor: DmpFlg === 'Y' || HisData || viewParams ? false : 'text' , width: 200 },
        { header: 'UNIT', name: 'unit',    editor: DmpFlg === 'Y' || HisData || viewParams ? false : 'text' , width: 200 },
        {
            header: 'DATATYPE_DESCRIPTION',
            name: 'description',
            editor:DmpFlg === 'Y' ||    HisData || viewParams ? false : CustomTextEditor,
            lineHeight: '1.5',
            whiteSpace: 'pre-wrap'
        },
        {
            name: 'delete',
            header: 'Delete',
            formatter: ({ row }) =>
                DmpFlg === 'Y' || viewParams
                    ? ''
                    : `<button class="btn-delete" data-row-key="${row.rowKey}">삭제</button>`, // 삭제 불가능할 경우 빈 문자열
        }
    ];

    useEffect(() => {
        const activeSets = ModifySets?.length > 0 ? ModifySets : DateSets;

        if (activeSets?.length > 0) {
            const sampleList = [];
            const methodList = [];
            const resultList = [];

            activeSets.forEach(item => {
                if (MethodData === "시료데이터") {
                    sampleList.push({
                        DmpNo: item.DmpNo,
                        DtaSetNo: item.DtaSetNo,
                        field: item.DtaFld,
                        type: item.DtaTyp,
                        unit: item.DtaUnit,
                        description: item.DtaDsc,
                        delete: item?.BtnMinus
                    });
                } else if (MethodData === "방법데이터") {
                    methodList.push({
                        DmpNo: item.DmpNo,
                        DtaSetNo: item.DtaSetNo,
                        index: item.DtaMtdIdx,
                        name: item.DtaMtdNm,
                        methoddescription: item.DtaDsc,
                        // delete: item?.BtnMinus
                    });
                } else if (MethodData === "결과데이터") {
                    resultList.push({
                        DmpNo: item.DmpNo,
                        DtaSetNo: item.DtaSetNo,
                        field: item.DtaFld,
                        type: item.DtaTyp,
                        unit: item.DtaUnit,
                        description: item.DtaDsc,
                        // delete: item?.BtnMinus
                    });
                }
            });

            setSampleData(sampleList);
            setMethodData(methodList);
            setResultData(resultList);
        }
    }, [DateSets, ModifySets, MethodData]);


    const handleAddRow = () => {
        const newId = Date.now();
        const newRow = MethodData === "방법데이터" ? {
            id: newId,
            index: '-',
            name: '-',
            methoddescription: "-",
            delete: '삭제'  // 행 추가 시에는 삭제 버튼을 보이게 함
        } : {
            id: newId,
            field: '-',
            type: '-',
            unit: '-',
            description: "-",
            delete: '삭제'  // 행 추가 시에는 삭제 버튼을 보이게 함
        };

        if (MethodData === "방법데이터") {
            setMethodData(prevState => {
                const updatedData = [...prevState, newRow]; // 새로운 행을 배열의 끝에 추가
                setToastMethodDataSets(updatedData); // 상태 동기화
                return updatedData;
            });
        } else if (MethodData === "시료데이터") {
            setSampleData(prevState => {
                const updatedData = [...prevState, newRow]; // 새로운 행을 배열의 끝에 추가
                setToastDataSets(updatedData); // 상태 동기화
                return updatedData;
            });
        } else if (MethodData === "결과데이터") {
            setResultData(prevState => {
                const updatedData = [...prevState, newRow]; // 새로운 행을 배열의 끝에 추가
                setToastResultDataSets(updatedData); // 상태 동기화
                return updatedData;
            });
        }
    };

    const handleDeleteRow = (rowKey, MethodData) => {
        const parsedRowKey = parseInt(rowKey, 10);
        if (isNaN(parsedRowKey)) {
            console.error("Invalid rowKey:", rowKey);
            return;
        }

        // const resetRowKeys = (data) => data.map((row, index) => ({ ...row, rowKey: index }));
        //

        if (MethodData === "방법데이터") {
            setMethodData(prevData => {
                const updatedData = prevData.filter((row) => row.rowKey !== parsedRowKey);
                setToastMethodDataSets(updatedData);
                return updatedData;
            });
        } else if (MethodData === "시료데이터") {
            setSampleData(prevData => {
                const updatedData = prevData.filter((row) => row.rowKey !== parsedRowKey);
                setToastDataSets(updatedData);
                return updatedData;
            });
        } else if (MethodData === "결과데이터") {
            setResultData(prevData => {
                const updatedData = prevData.filter((row) => row.rowKey !== parsedRowKey);
                setToastResultDataSets(updatedData);
                return updatedData;
            });
        }
    };

// useEffect로 데이터 동기화와 이벤트 핸들러 추가
    useEffect(() => {

        const grid = gridRef.current.getInstance();

        grid.on('click', (ev) => {

            if (ev.targetType === 'cell' && ev.nativeEvent.target.className.includes('btn-delete')) {
                const rowKey = ev.nativeEvent.target.getAttribute('data-row-key');
                handleDeleteRow(rowKey, MethodData);
            }
        });


        grid.on('keydown', (ev) => {
            const focusedCell = grid.getFocusedCell();
            if (ev.targetType === 'emptySpace') {
                ev.stop();
                return;

            }

            // focusedCell이 없으면 이벤트 중단
            if (!focusedCell) {
                console.warn('No focused cell. Ignoring keydown event.');
                ev.stop(); // Grid 기본 동작 중단
                const nativeEvent = ev.nativeEvent;
                if (nativeEvent && typeof nativeEvent.preventDefault === 'function') {
                    nativeEvent.preventDefault();
                }
                return;
            }

            // editor가 없는 셀도 동작 중단
            if (!columns.find((col) => col.name === focusedCell.columnName)?.editor) {
                console.warn('Focused cell has no editor. Ignoring keydown event.');
                ev.stop();
                const nativeEvent = ev.nativeEvent;
                if (nativeEvent && typeof nativeEvent.preventDefault === 'function') {
                    nativeEvent.preventDefault();
                }
            }
        });

        if (Array.isArray(sampleData) && sampleData.length > 0) {
            setTimeout(() => {
                setToastDataSets(sampleData);  // 상태 업데이트
            }, 2);  // 비동기 처리로 타이밍 조정
        }
        if (Array.isArray(methodData) && methodData.length > 0) {
            setTimeout(() => {
                setToastMethodDataSets(methodData);  // 상태 업데이트
            }, 2);  // 비동기 처리로 타이밍 조정
        }
        if (Array.isArray(resultData) && resultData.length > 0) {
            setTimeout(() => {
                setToastResultDataSets(resultData);  // 상태 업데이트
            }, 2);  // 비동기 처리로 타이밍 조정
        }

    }, [MethodData, sampleData, methodData, resultData]);

    const handleGridUpdated = (ev) => {

        if (ev && ev.changes && ev.changes.length > 0) {
            let alertShown = false; // alert 메시지가 중복 표시되지 않도록 플래그 추가

            ev.changes.forEach(({ rowKey, columnName, value }) => {

                const handleUnitValidation = (currentRowType) => {
                    // 'NUMERIC' 타입일 때 'unit'에 숫자만 입력 가능
                    if (currentRowType === 'NUMERIC' && columnName === 'unit') {
                        if (isNaN(value)) {
                            if (!alertShown) {
                                alert('숫자만 입력이 가능합니다.');
                                alertShown = true;
                            }
                            return '';
                        }
                    }

                    // 'STRING' 타입일 때 'unit'에 문자열만 입력 가능
                    if (currentRowType === 'STRING' && columnName === 'unit') {
                        if (!isNaN(value)) {
                            if (!alertShown) {
                                alert('문자열만 입력이 가능합니다.');
                                alertShown = true;
                            }
                            return '';
                        }
                    }

                    return value;
                };

                if (MethodData === "시료데이터") {
                    setSampleData(prevData => {
                        const currentRow = prevData[rowKey];
                        const currentRowType = currentRow?.type;

                        const validatedValue = handleUnitValidation(currentRowType);

                        const updatedList = prevData.map((row, index) =>
                            index === rowKey ? { ...row, [columnName]: validatedValue } : row
                        );
                        // setToastDataSets(updatedList);
                        return updatedList;
                    });
                }

                else if (MethodData === "방법데이터") {
                    setMethodData(prevData => {
                        const updatedList = prevData.map((row, index) =>
                            index === rowKey ? { ...row, [columnName]: value } : row
                        );
                        // setToastMethodDataSets(updatedList);
                        return updatedList;
                    });
                }

                else if (MethodData === "결과데이터") {
                    setResultData(prevData => {
                        const updatedList = prevData.map((row, index) =>
                            index === rowKey ? { ...row, [columnName]: value } : row
                        );
                        // setToastResultDataSets(updatedList);
                        return updatedList;
                    });
                }
            });
        }
    };

    return (
        <div className="toast-container">
            {/* 행 추가 버튼을 viewParams가 'dmpRealDataView'가 아닌 경우에만 표시 */}
            {(SubmitStatus === '0' || DateSets?.length === 0 || DmpFlg === 'N') && (
                <div className="row-btn">
                    <button className="public-data-addrow" onClick={handleAddRow}>
                        행추가
                    </button>
                </div>
            )}

            {/* 그리드를 viewParams가 'dmpRealDataView'이면 읽기 전용으로 처리 */}
            <Grid
                ref={gridRef}
                data={MethodData === "방법데이터" ? methodData : MethodData === "시료데이터" ? sampleData : resultData}
                columns={columns}
                rowHeight={'auto'}
                bodyHeight={300}
                rowHeaders={['rowNum']}
                editable={true}  // viewParams가 'dmpRealDataView'이면 편집 불가
                copyOptions={{ useFormattedValue: true }}  // 복사-붙여넣기 가능하게 설정
                onAfterChange={handleGridUpdated}
            />
        </div>
    );
};

export default ToastGrid;
