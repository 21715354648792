import './App.css';
import React from "react";
import {Routes, Route, useLocation, Navigate, useNavigate} from "react-router-dom";
import HomePage from "./home/home";
import Header from "./header/header";
import Footer from './footer/footer';
import DmpcreatePage from "./dmpManagement/dmpCreate/dmpcreate";
import Dmplist from "./dmpManagement/dmpList/dmplist";
import Dmpsend from "./dmpManagement/dmpSend/dmpsend";
import DmpRealDataCreate from "./dmpRealData/dmpRealDataCreate/dmpRealDataCreate";
import DmpRealDataList from "./dmpRealData/dmpRealDataList/dmpRealDataList";
import DmpRealDataSend from "./dmpRealData/dmpRealDataSend/dmpRealDataSend";
import DataSubmitStatus from "./adminPage/submitStatus/dataSubmitStatus";
import DmpObjCreate from "./dmpobj/dmpObjCreate";
import DmpDataProductionPlan from "./dmpDataProductionPlan/dmpDataProductionPlan";
import AdminDataPage from "./adminPage/adminDataPage";
import DmpSubmitStatus from "./adminPage/dmpSubmitStatus/dmpSubmitStatus";
import DmpAccessCheck from "./adminPage/dmpAccessCheck/dmpAccessCheck";
import Login from "./login/login";
import OpenData from "./openData/openData";
import OpenDetailPage from "./openData/detailPage/detailPage";
import DmpRealDataModify from "./dmpRealData/dmpRealDataModify/dmpRealDataModify";
import DmpmodifyPage from "./dmpManagement/dmpModify";
import IntroPage from "./intro/intro";
import DmpBoard from "./board/board";
import DmpBoardDetail from "./board/boardDetail";
import {Helmet} from "react-helmet";
import DmpRealDataView from "./dmpRealData/dmpRealDataView/dmpRealDataView";
import DmpHisDataList from "./dmpRealData/dmpHisDataList/dmpHisDataList";
import DmpHisDataView from "./dmpRealData/dmpHisDataView/dmpHisDataView";
import DmpHisPageList from "./dmpManagement/dmpHisPageList/dmpHisPageList";
import DmpHisPageView from "./dmpManagement/dmpHisPageView/dmpHisPageView";
import OpenDataMyPage from "./myPage/myPage";
import DataSubmitList from "./adminPage/dataSubmitList/dataSubmitList";
import DataSubmitStatusChange from "./adminPage/dataSubmitStatusChange/dataSubmitStatusChange";
import DmpPlanStatus from "./adminPage/dmpPlanStatus/dmpPlanStatus";
import BoardWrite from "./board/write";
import ErrorPage from "./error/error";
import CurationList from "./Curation/list";
import UserManagement from "./adminPage/userManagement/userManagement";
import UserManagementDetail from "./adminPage/userManagement/detail";
import MypageCalendar from "./myPage/calendar";
import ApplicationManagementPage from "./adminPage/applicationManagement/applicationManagement";
import ProductionPlanMng from "./adminPage/productionPlanMng/productionPlanMng";
import ApplicationManagementDetail from "./adminPage/applicationManagement/detail";

import PrivateRoute from './PrivateRoute';
import DataRoom from "./dataRoom/dataRoom";
import NotExist from "./error/notExist";
import ProdDetail from "./adminPage/productionPlanMng/prodDetail";
import ProdFiledown from "./adminPage/productionPlanMng/prodFiledown";
import ErrorHandler from "./errorHandler";
import ObjCreate from "./adminPage/objCreate/objCreate";
import BoardView from "./board/boardView";
import ObjView from "./adminPage/objCreate/objView";
import ApplicationManagementPrint from "./adminPage/applicationManagement/print"; // PrivateRoute 추가

// 로그인 여부를 확인하는 함수 (예: 로그인 상태를 확인하는 로직)
// const isAuthenticated = () => {
//     const authData = JSON.parse(localStorage.getItem('loginAuthStore') || '{}');
//     return authData.state?.userId !== null;
// };
//
// // PrivateRoute 컴포넌트
// function PrivateRoute({ children }) {
//     return isAuthenticated() ? children : <Navigate to="/login" />;
// }

function App() {
    const location = useLocation();

    // Header와 Footer를 숨길 경로 설정
    const hideHeaderFooter = location.pathname.startsWith('/adminPage/applicationManagement/print');

    const loginFooter = location.pathname.startsWith('/login');
    return (
        <div className="allproject-container">
            <Helmet>
                <title>KFRI Food Data Platform</title>
                <link rel="icon" href="/public/favicon.ico" />
            </Helmet>

            {/* Header 조건부 렌더링 */}
            {!hideHeaderFooter && <Header />}

            <ErrorHandler>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/notExist" element={<NotExist />} />

                    {/* DMP 관리 */}
                    <Route
                        path="/dmpManagement/*"
                        element={
                            <PrivateRoute>
                                <Routes>
                                    <Route path="dmpCreate" element={<DmpcreatePage />} />
                                    <Route path="dmpList" element={<Dmplist />} />
                                    <Route path="dmpSend" element={<Dmpsend />} />
                                    <Route path="dmpModify" element={<DmpmodifyPage />} />
                                    <Route path="dmpHisPageList" element={<DmpHisPageList />} />
                                    <Route path="dmpHisPageView" element={<DmpHisPageView />} />
                                </Routes>
                            </PrivateRoute>
                        }
                    />

                    {/* DMP 실데이터 */}
                    <Route
                        path="/dmpRealData/*"
                        element={
                            <PrivateRoute>
                                <Routes>
                                    <Route path="dmpRealDataCreate" element={<DmpRealDataCreate />} />
                                    <Route path="dmpRealDataView" element={<DmpRealDataView />} />
                                    <Route path="dmpRealDataModify" element={<DmpRealDataModify />} />
                                    <Route path="dmpRealDataList" element={<DmpRealDataList />} />
                                    <Route path="dmpHisDataList" element={<DmpHisDataList />} />
                                    <Route path="dmpHisDataView" element={<DmpHisDataView />} />
                                    <Route path="dmpRealDataSend" element={<DmpRealDataSend />} />
                                </Routes>
                            </PrivateRoute>
                        }
                    />

                    <Route path="/openData" element={<OpenData />} />
                    <Route path="/openData/detailPage" element={<OpenDetailPage />} />
                    <Route path="/dmpobj/dmpObjCreate" element={<DmpObjCreate />} />
                    <Route path="/dmpDataProductionPlan/dmpDataProductionPlan" element={<DmpDataProductionPlan />} />

                    {/* 소개 페이지 */}
                    <Route path="/intro" element={<IntroPage />} />
                    <Route path="/board" element={<DmpBoard />} />
                    <Route path="/board/boardView" element={<BoardView />} />
                    <Route path="/board/boardDetail" element={<DmpBoardDetail />} />
                    <Route path="/board/write/:BoardSeq" element={<BoardWrite />} />

                    {/* 자료실 */}
                    <Route path="/dataRoom" element={<DataRoom />} />

                    {/* 마이페이지 */}
                    <Route
                        path="/myPage/*"
                        element={
                            <PrivateRoute>
                                <Routes>
                                    <Route path="/" element={<OpenDataMyPage />} />
                                    <Route path="calendar" element={<MypageCalendar />} />
                                </Routes>
                            </PrivateRoute>
                        }
                    />

                    <Route path="/Curation/list" element={<CurationList />} />

                    {/* 관리자 페이지 */}
                    <Route
                        path="/adminPage/*"
                        element={
                            <PrivateRoute adminOnly={true}>
                                <Routes>
                                    <Route path="/" element={<AdminDataPage />} />
                                    <Route path="objCreate" element={<ObjCreate />} />
                                    <Route path="objView" element={<ObjView />} />
                                    <Route path="submintStatus" element={<DataSubmitStatus />} />
                                    <Route path="dataSubmitList" element={<DataSubmitList />} />
                                    <Route path="dataSubmitStatusChange" element={<DataSubmitStatusChange />} />
                                    <Route path="dmpSubmitStatus" element={<DmpSubmitStatus />} />
                                    <Route path="dmpPlanStatus" element={<DmpPlanStatus />} />
                                    <Route path="dmpAccessCheck" element={<DmpAccessCheck />} />
                                    <Route path="applicationManagement" element={<ApplicationManagementPage />} />
                                    <Route path="applicationManagement/detail/:AppNo" element={<ApplicationManagementDetail />} />
                                    <Route path="applicationManagement/print/:AppNo" element={<ApplicationManagementPrint />} />
                                    <Route path="productionPlanMng" element={<ProductionPlanMng />} />
                                    <Route path="productionPlanMng/prodDetail/:DmpNo" element={<ProdDetail />} />
                                    <Route path="productionPlanMng/prodFiledown/:DtaSbmSeqNo" element={<ProdFiledown />} />
                                    <Route path="userManagement" element={<UserManagement />} />
                                    <Route path="userManagement/detail/:UserAuthSeq" element={<UserManagementDetail />} />
                                </Routes>
                            </PrivateRoute>
                        }
                    />

                    {/* 모든 경로에 해당하지 않으면 ErrorPage로 이동 */}
                    <Route path="*" element={<ErrorPage />} />
                </Routes>
            </ErrorHandler>

            {/* Footer 조건부 렌더링 */}
            {(!hideHeaderFooter && !loginFooter) && <Footer />}
        </div>
    );
}

export default App;


