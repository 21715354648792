import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';

// React 17에서는 ReactDOM.render를 사용합니다.
ReactDOM.render(
        <Router>
            <App />
        </Router>,
    document.getElementById('root')
);