import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../submitStatus/dataSubmitStatus.css";
import api from "../../api";

function DataSubminList() {
    const [tableData, setTableData] = useState([]);
    const [listCount, setListCount] = useState(0); // 총 데이터 개수
    const [totalPages, setTotalPages] = useState(0); // 전체 페이지 수
    const [selectedYear, setSelectedYear] = useState(""); // 선택된 연도
    const [years, setYears] = useState([]); // 연도 리스트
    const [searchTerm, setSearchTerm] = useState(""); // 검색어 상태
    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지

    const pageSize = 10; // 한 페이지에 표시할 데이터 수
    const pageGroupSize = 10; // 한 번에 표시할 페이지 번호 개수 (1-10, 11-20 등)
    const currentYear = new Date().getFullYear();
    const location = useLocation();
    const navigate = useNavigate();


    const adminMenu = [
        { 'id': 1, 'name': "공개시기 관리(캘린더)", 'path': "/adminPage" },
        { 'id': 2, 'name': "과제 목록", 'path': "/adminPage/objView" },
        { 'id': 3, 'name': "데이터 제출 현황", 'path': "/adminPage/submintStatus" },
        { 'id': 4, 'name': "데이터 제출 목록 일괄", 'path': "/adminPage/dataSubmitList" },
        { 'id': 5, 'name': "데이터 제출 상태 변경", 'path': "/adminPage/dataSubmitStatusChange" },
        { 'id': 6, 'name': "DMP 작성 현황 및 제출 상태 변경", 'path': "/adminPage/dmpSubmitStatus" },
        { 'id': 7, 'name': "데이터 관리 계획 접근권한(User별)", 'path': "/adminPage/userManagement" },
        { 'id': 8, 'name': "데이터 관리 계획 접근권한(과제별)", 'path': "/adminPage/dmpAccessCheck" },
        { 'id': 9, 'name': "생산계획 누락현황 확인", 'path': "/adminPage/productionPlanMng" },
        { 'id': 10, 'name': "신청서 관리 페이지(신청서 정보)", 'path': "/adminPage/applicationManagement" },
    ];

    // URL 쿼리 파라미터에서 상태 복원
    useEffect(() => {
        // 페이지 이동 시 데이터를 초기화
        setTableData([]); // 초기화
        dataSendPage(searchTerm, selectedYear, currentPage);
    }, [currentPage, selectedYear, searchTerm]);

    useEffect(() => {
        const params = new URLSearchParams();
        if (searchTerm) params.set("search", searchTerm);
        if (currentPage) params.set("page", currentPage);
        navigate(`?${params.toString()}`, { replace: true }); // URL을 업데이트하되 브라우저 기록을 추가하지 않음
    }, [currentPage, searchTerm, navigate]);


    // 연도 리스트 생성
    useEffect(() => {
        const startYear = 2022;
        const yearList = [];
        for (let year = startYear; year <= currentYear; year++) {
            yearList.push(year);
        }
        setYears(yearList);
    }, [currentYear]);

    // 데이터 요청 함수
    const dataSendPage = async (searchTerm = "", year = "", page = 1) => {
        const start = (page - 1) * pageSize + 1; // 페이지 시작 위치 계산
        const endPoint = `/api/m/datasubmitlist?kw=${searchTerm}&year=${year}&ps=${start}&pl=${pageSize}`;
        try {
            const res = await api.get(endPoint, {
                withCredentials: true,
            });
            setTableData(res.data.item || []);
            setListCount(res.data.pages.Total || 0); // 총 데이터 개수 설정
            setTotalPages(Math.ceil((res.data.pages.Total || 0) / pageSize)); // 전체 페이지 수 계산
        } catch (error) {
            console.error("API 요청 에러:", error);
        }
    };

    // 검색어 변경 핸들러
    const handleInputChange = (event) => {
        const value = event.target.value;

        // 입력된 값의 길이가 14자를 넘으면 alert를 띄우고 값을 초기화
        if (value.length > 14) {
            alert("입력 가능한 최대 길이는 14자입니다.");
            setSearchTerm(""); // 값을 초기화
            event.target.value = ""; // input 필드도 초기화
            return;
        }

        setSearchTerm(value); // 상태 업데이트
        setCurrentPage(1); // 페이지를 초기화
        updateURL(value, selectedYear, 1); // URL 업데이트
    };

    // 연도 변경 핸들러
    const handleYearChange = (e) => {
        const year = e.target.value;
        setSelectedYear(year);
        setCurrentPage(1); // 페이지를 처음으로 리셋
        updateURL(searchTerm, year, 1); // URL 업데이트
    };

    // 페이지네이션 버튼 클릭
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber); // 상태 업데이트
        updateURL(searchTerm, selectedYear, pageNumber); // URL 업데이트
    };

    // URL 업데이트 함수
    const updateURL = (searchTerm, year, page) => {
        const params = new URLSearchParams();
        if (searchTerm) params.set("search", searchTerm);
        if (year) params.set("year", year);
        params.set("page", page);
        navigate(`?${params.toString()}`, { replace: true }); // URL 업데이트
    };

    // Row 클릭 시 상세 페이지로 이동
    const handleRowClick = (DtaSbmSeqNo) => {
        navigate("/dmpRealData/dmpRealDataView", {
            state: { DtaSbmSeqNo }, // id를 state로 전달
        });
    };

    // 페이지네이션 렌더링
    const renderPagination = () => {
        const startPage = Math.floor((currentPage - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

        const pages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <button className="pagination-button" onClick={() => handlePageClick(startPage - 1)}>
                        이전
                    </button>
                )}
                {pages.map((page) => (
                    <button
                        key={page}
                        className={`pagination-button ${page === currentPage ? "active" : ""}`}
                        onClick={() => handlePageClick(page)}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages && (
                    <button className="pagination-button" onClick={() => handlePageClick(endPage + 1)}>
                        다음
                    </button>
                )}
            </div>
        );
    };

    const activeMenuId = adminMenu.find((menu) => menu.path === location.pathname)?.id || null;



    return (
        <div className="adminpage-container">
            <div className="small-title">
                <img src={`${process.env.PUBLIC_URL}/adminpage.png`} className="adminlist-img02" alt="search Logo" />
                <div className="adminhd-line"></div>
                <h2 className="menu-name">관리자 메뉴</h2>
            </div>

            <div className="menu-list">
                <div className="menu-title">
                    {adminMenu.map((item) => (
                        <React.Fragment key={item.id}>
                            <div className={`menu-item ${activeMenuId === item.id ? "active" : ""}`}>
                                <Link to={item.path}>
                                    {item.name}
                                </Link>
                            </div>
                            <div className="admin-under"></div>
                        </React.Fragment>
                    ))}
                </div>
                <div className="admin-line"></div>
                <div className="year-name">
                    <div className="submin-container">
                        <h3>데이터 제출 목록 일괄</h3>
                        <div>
                            <h4>※ 조회하고자 하는 연도를 아래에서 선택해주세요.</h4>
                            <div className="accessCheck-year">
                                과제수행년도
                                <select
                                    value={selectedYear}
                                    className="submin-year"
                                    onChange={handleYearChange}
                                >
                                    <option value="">전체</option>
                                    {years.map((year) => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="admin-searchbox">
                            <img src={`${process.env.PUBLIC_URL}/icon_search.png`} alt="search Logo" />
                            <input
                                type="text"
                                placeholder="검색어를 입력해주세요."
                                value={searchTerm}
                                onChange={handleInputChange}
                            />
                            <h4>※ 과제번호, 과제명, 데이터명, 연구책임자명 키워드로 검색이 가능합니다.</h4>
                        </div>
                    </div>
                    <div className="title-line02"></div>
                    <div className="history-select">
                        <div>총 {listCount}건</div>
                    </div>
                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                        <thead>
                        <tr className="dmplist-title">
                            <th>DtaSbmSeqNo</th>
                            <th>과제번호</th>
                            <th>과제수행년도</th>
                            <th>과제명</th>
                            <th>데이터명</th>
                            <th>연구책임자명</th>
                            <th>데이터담당자명</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tableData.map((row, index) => (
                            <tr key={index} className="dmplist-Data">
                                <td>{row.DtaSbmSeqNo}</td>
                                <td>{row.ProjNo}</td>
                                <td>{row.AnnlYy}</td>
                                <td>{row.ProjNm}</td>
                                <td
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleRowClick(row.DtaSbmSeqNo)}
                                >
                                    {row.DtaDs}
                                    <br />({row.DtaFileNm})
                                </td>
                                <td>{row.RspnEmpNm}</td>
                                <td>{row.DataEmpNm}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {renderPagination()}
                </div>
            </div>
        </div>
    );
}

export default DataSubminList;
