import React, { useEffect, useState, useRef } from 'react';
import './dmpCreate/dmpcreate.css';
import DataManagementGrid from "../dmpDataManagement/dataManagementGrid";
import LastDmpShow from "../lastDmp/lastDmpShow";
import DataPublicGrid from "../dmpDataPublic/dataPublicGrid";
import TaskCheck from "../taskCheck/taskCheck";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import DmpDataProductionPlan from "../dmpDataProductionPlan/dmpDataProductionPlan";
import DmpModifyToastGrid from "../dmpModify/dmpModifyToast";
import Cookies from 'js-cookie';
import {
    useNewDmpManagementPlan,
    useNewDmpProductionPlan,
    useNewDmpPublicPlan,
    useNewDmpToastData
} from "../stores/newWriteDmp";
import api from "../api";

function DmpmodifyPage() {
    const navigate = useNavigate();
    const { DmpHisNo } = useParams();

    const location = useLocation();
    const { id } = location.state || {}; // 전달된 id 가져오기

    //생산 계획 수정
    const [allData, setAllData] = useState([]);
    const dataRefs = useRef([]);

    // 로딩 상태와 데이터 상태 관리
    const [allDmpData, setAllDmpData] = useState(null); // null로 초기화하여 데이터가 없는 상태를 구분

    const [updateProNo, setUpdateProNo] = useState("");
    const [updateProName, setUpdateProName] = useState("");
    const [updateProLeader, setUpdateProLeader] = useState("");
    const [modifyMethodSummary, setModifyMethodSummary] = useState("");
    const [modifyMethodAnalysis, setModifyMethodAnalysis] = useState("");

    const [showLastDmp, setShowLastDmp] = useState(false);
    const [showObjCheck, setShowObjCheck] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [modifySummaryData, setModifySummaryData] = useState([]);
    // const [imageSrc, setImageSrc] = useState(`${process.env.PUBLIC_URL}/bg_file_select.png`);

    const [imageSrc, setImageSrc] = useState(allDmpData?.DmpMain?.RsrcImg || ""); // 초기 이미지 값 설정



    // slice year
    const [yearValue , setyearValue] = useState({});

    const yearRefs = useRef({}); // 연도별 ref를 저장하는 객체

    //동적인 데이터 타입
    const [proplanDataTypes, setProplanDataTypes] = useState([]);

    const [RsrcImg, setRsrcImg] = useState(''); // Base64로 변환된 이미지



    //생산 계획 값 저장 modify에서 사용 !!
    // const {newProductionPlandataSets} = useNewDmpProductionPlan();
    const newProductionPlandataSets = Object.values(allData).flat();
    //관리 계획 값 저장 new!!
    const { newManagementPlandataSets } = useNewDmpManagementPlan();
    //공개계획 값 저장
    const { newPublicPlandataSets } = useNewDmpPublicPlan();

    // 가져온 데이터 setData 입니다.
    const dataTypes = ["시료데이터","방법데이터","결과데이터"];

    const lastSummaryItem = modifySummaryData[modifySummaryData.length - 1]?.Summary;
    const lastDataType = lastSummaryItem?.DtaSetNm;
    const currentTypeIndex = dataTypes.indexOf(lastDataType);
    const nextDataTypeIndex = (currentTypeIndex + 1) % dataTypes.length;
    const nextDataType = dataTypes[nextDataTypeIndex];


    //사이드 바 클릭 시 이동
    const taskInfoRef = useRef(null);
    const researchMethodRef = useRef(null);
    const researchImgRef = useRef(null);
    const sidebarDataSetsRef = useRef(null);
    const productionDataRef = useRef(null);
    // const analySideDataRef = useRef(null);
    const mgDataRef = useRef(null);
    const openDataRef = useRef(null);

    useEffect(() => {
        if(allDmpData && allDmpData.DmpMain?.RsrcImg) {
            setImageSrc(allDmpData.DmpMain.RsrcImg);
        }
    }, [allDmpData]);


    const handleScrollToSection = (ref) => {
        if (ref && ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth',  block: 'center'});
        }
    };

    //연도에 따른 생산 계획 ref
    const handleScrollToYears = (year) => {
        if (yearRefs.current[year]) {
            yearRefs.current[year].scrollIntoView({ behavior: "smooth", block: 'center' });
        }
    };

    useEffect(() => {
        // newDataSummary 배열이 변경될 때마다 ref 배열을 동기화
        dataRefs.current = dataRefs.current.slice(0, modifySummaryData.length);
    }, [modifySummaryData]);

    const handleScrollToElement = (index) => {
        if (dataRefs.current[index]) {
            dataRefs.current[index].scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };



    useEffect(async () => {

        if( id === undefined) {
            navigate('/notExist');
            return
        }
        const response = await api.get(`/api/dmp/getdmp?DmpNo=${id}`, {
            withCredentials: true,
        });
        setyearValue(response.data);
    }, []);

    const modifyStartYear =  new Date(yearValue?.ProjDateSt).getFullYear();
    const modifyEndYear = new Date(yearValue?.ProjDateEd).getFullYear();

    //년도 자르기
    const generateProductionPlans = () => {
        const years = [];
        for (let i = modifyStartYear; i <= modifyEndYear; i++) {
            years.push(i);
        }
        return years;
    };

    const yearParams = generateProductionPlans().map(year => `&PrdYear=${year}`).join('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`/api/dmp/loaddmpall?dmpNo=${id}${yearParams}`, {
                    withCredentials: true,
                });
                setAllDmpData(response.data);

                // 만약 response.data가 비어있는 경우
            } catch (err) {
                console.error("API 호출 오류: 데이터를 확인해주세요.");
            }
        };

        fetchData();
    }, [id,yearParams]); // id가 바뀔 때마다 데이터를 새로 불러오도록 설정

    useEffect(() => {
        if (allDmpData?.DmpMain) {
            setUpdateProNo(allDmpData.DmpMain?.ProjNo || '');
            setUpdateProName(allDmpData.DmpMain?.ProjNm || '');
            setUpdateProLeader(allDmpData.DmpMain?.RspnEmpNm || '');
            setModifyMethodSummary(allDmpData.DmpMain?.RsrcSmm || '');
            setModifyMethodAnalysis(allDmpData.DmpMain?.RsrcAnlyPlan || '');
            setModifySummaryData(allDmpData?.SumData || []); // 서머리 데이터 설정
        }
    }, [allDmpData]);

    const objHandler = () => {
        alert("수정페이지에서는 과제선택이 불가합니다.")
    }
    const lastHandler = () => {
        alert("수정페이지에서는 지난 DMP를 불러올 수 없습니다.")
    }

    // 파일 업로드 처리


    // 추가 데이터 처리
    // const handleAddDataSet = () => {
    //     const newDataSets = dataTypes.map(type => ({
    //         DataMain: [
    //         ],
    //         Summary: {
    //             id: Date.now() + Math.random(), // 고유한 ID 생성
    //             DtaSetNm: type,
    //             DmpNo: allDmpData.DmpMain.DmpNo,
    //             DtaSetNmSub: "",
    //             DtaNm: "",
    //             DtaDs: "",
    //             DmpFlg: "Y",
    //         },
    //     }));
    //
    //     setModifySummaryData(prevData => [...prevData, ...newDataSets]);
    // };

    const createNewDataSet = () => ({
        DataMain: [],
        Summary: {
            id: Date.now() + Math.random(), // 고유한 ID 생성
            DtaSetNm: '', // 타입 전달
            DmpNo: allDmpData.DmpMain.DmpNo, // 기존 로직 유지
            DtaSetNmSub: "",
            DtaNm: "",
            DtaDs: "",
            DmpFlg: "Y",
        },
    });

    const handleAddDataSet = () => {

        const newDataSets = createNewDataSet();
        // dataTypes 배열에 있는 각 타입으로 새로운 데이터 세트 생성

        // modifySummaryData 업데이트
        setModifySummaryData(prevData => [...prevData, newDataSets]);
    };


    // 요약 데이터 업데이트 처리
    const handleUpdateChange = (id, event) => {
        const newValue = event.target.value;

        setModifySummaryData(prevData =>
            prevData.map(dataSet => {
                if (dataSet.Summary.DtaSetNo === id || dataSet.Summary.id === id) {
                    // 조건에 맞는 데이터셋만 업데이트
                    return {
                        ...dataSet,
                        Summary: {
                            ...dataSet.Summary,
                            DtaSetNm: newValue,
                            // "방법데이터" 또는 "결과데이터" 선택 시 DtaSetNmSub 값을 빈 문자열로 초기화
                            ...(newValue === "방법데이터" || newValue === "결과데이터" ? { DtaSetNmSub: "" } : {})
                        }
                    };
                }
                // 나머지 데이터셋은 변경하지 않고 그대로 반환
                return dataSet;
            })
        );
    };



    const handleRequiredChange = (id, event) => {
        const newValue = event.target.value;
        setModifySummaryData(prevData =>
            prevData.map(dataSet => {
                if (dataSet.Summary.DtaSetNo === id || dataSet.Summary.id === id) {
                    return {
                        ...dataSet,
                        Summary: {
                            ...dataSet.Summary,
                            DtaSetNmSub: newValue, // DtaSetNmSub을 업데이트
                        }
                    }
                }
                // 나머지 데이터셋은 변경하지 않고 그대로 반환
                return dataSet;
        })
        );
    };


    useEffect(() => {
        setProplanDataTypes(modifySummaryData.map(dataSet => ({
            id: dataSet.Summary.DtaSetNo || dataSet.Summary.id,
            DtaNm: dataSet.Summary.DtaNm
        })));
    }, [modifySummaryData]);  // modifySummaryData가 변경될 때마다 실행

    const handleInputChange = (id, key, event) => {
        const newValue = event.target.value;
        setModifySummaryData(prevData =>
            prevData.map(dataSet => {
                if (dataSet.Summary.DtaSetNo === id || dataSet.Summary.id === id) {
                    return {
                        ...dataSet,
                        Summary: {
                            ...dataSet.Summary,
                            DtaNm: newValue, // DtaNm을 업데이트
                        },
                    }
                }
                return dataSet;
            })
        );

        if (key === 'DtaNm') {
            setProplanDataTypes(prevTypes => {
                // 기존 데이터 중 해당 id가 있는지 확인
                const existingIndex = prevTypes.findIndex(item => item.id === id);

                if (existingIndex !== -1) {
                    // id가 일치하는 데이터가 있으면 값을 덮어쓰기
                    const updatedTypes = [...prevTypes];
                    updatedTypes[existingIndex] = { id, [key]: newValue };
                    return updatedTypes;
                } else {
                    // id가 없으면 기존 값들을 유지하면서 새로운 값을 추가
                    return [...prevTypes, { id, [key]: newValue }];
                }
            });
        }
    };


    const handleImageChange = (event) => {
        const file = event.target.files[0]; // 사용자가 선택한 파일을 가져옴
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const img = new Image();
                img.src = reader.result;

                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    // Set canvas dimensions to the image dimensions
                    canvas.width = img.width;
                    canvas.height = img.height;

                    // Draw the image onto the canvas
                    ctx.drawImage(img, 0, 0);

                    // Convert the canvas content to a JPEG base64 string
                    const jpegBase64 = canvas.toDataURL('image/jpeg');

                    setImageSrc(jpegBase64); // 미리보기용 이미지
                    setRsrcImg(jpegBase64);  // Base64로 변환된 JPEG 이미지 저장
                };
            };
            reader.readAsDataURL(file); // 파일을 Base64로 읽음
        }
    };
    const handleInputdescChange = (id, event) => {
        const newValue = event.target.value;
        setModifySummaryData(prevData =>
            prevData.map(dataSet => {
                if (dataSet.Summary.DtaSetNo === id || dataSet.Summary.id === id) {
                    return {
                        ...dataSet,
                        Summary: {
                            ...dataSet.Summary,
                            DtaDs: newValue, // DtaDs을 업데이트
                        },
                    }
                }
                return  dataSet;
            })
        );
    };
    // 삭제 처리
    const handleDeleteDataSet = (id) => {
        setModifySummaryData(prevData =>
            prevData.filter(dataSet => dataSet.Summary.DtaSetNo !== id && dataSet.Summary.id !== id)
        );
    };

    const replaceEmptyWithDash = (value) => {
        return value && value.trim() !== '' ? value : '-';
    };

    const DataHisBtn = (e) => {
        const imageToSave = RsrcImg || allDmpData.DmpMain.RsrcImg; // 새로운 이미지가 있으면 사용하고, 없으면 기존 이미지 사용

        e.preventDefault();

        const csrfToken = Cookies.get('XSRF-TOKEN');

        const modifySummary = modifySummaryData.map((item) => ({
            DmpNo: item.Summary.DmpNo,
            DtaSetNo: item.Summary.DtaSetNo,
            DtaSetHisNo: item.Summary.DtaSetHisNo,
            DtaSetNm: replaceEmptyWithDash(item.Summary.DtaSetNm),
            DtaSetNmSub: replaceEmptyWithDash(item.Summary.DtaSetNmSub),
            DtaNm: replaceEmptyWithDash(item.Summary.DtaNm),
            DtaDs: replaceEmptyWithDash(item.Summary.DtaDs),
            DmpFlg: item.Summary.DmpFlg,
            InsDt: item.Summary.InsDt,
            UpdDt: item.Summary.UpdDt,
            HisInsDT: item.Summary.HisInsDT,
            DelFlg: item.Summary.DelFlg,
            DmpHisNo: item.Summary.DmpHisNo,
            DataMain: item.DataMain.map((dataSet) => ({
                DtaSetNo: item.Summary.DtaSetNo,
                DmpNo: item.Summary.DmpNo,
                DtaNo: dataSet.DtaNo,
                DtaFld: replaceEmptyWithDash(dataSet.DtaFld),
                DtaTyp: replaceEmptyWithDash(dataSet.DtaTyp),
                DtaUnit: replaceEmptyWithDash(dataSet.DtaUnit),
                DtaMtdIdx: replaceEmptyWithDash(dataSet.DtaMtdIdx),
                DtaMtdNm: replaceEmptyWithDash(dataSet.DtaMtdNm),
                DtaDsc: replaceEmptyWithDash(dataSet.DtaDsc),
                UserNo: dataSet.UserNo,
                UserNm: dataSet.UserNm,
                InsDt: dataSet.InsDt,
                UpdDt: dataSet.UpdDt,
                DelFlg: "N"
            }))
        }));

        const productionPn = newProductionPlandataSets.map(data => ({
            DmpPrdNo: data.DmpPrdNo,
            DmpNo: allDmpData.DmpMain.DmpNo,
            PrdYear: data.PrdYear,
            DtaNm: replaceEmptyWithDash(data.dataType),
            ProCnt: replaceEmptyWithDash(data.count),
            ProPlan: replaceEmptyWithDash(data.proPlan),
            InsDt: data.InsDt,
            UpdDt: data.UpdDt,
            DelFlg: "N"
        }));

        const publicPn = newPublicPlandataSets.map((data) => ({
            DmpPrdNo: data.DmpPrdNo,
            DmpNo: allDmpData.DmpMain.DmpNo,
            DtaNm: replaceEmptyWithDash(data.dataType),
            OpnTm: replaceEmptyWithDash(data.publictime),
            OpnPd: replaceEmptyWithDash(data.externalTime),
            OpnMth: replaceEmptyWithDash(data.publicmethod),
            OpnResn: replaceEmptyWithDash(data.individualplan),
            OpnOpnMth: replaceEmptyWithDash(data.individualmethod),
            InsDt: data.InsDt,
            UpdDt: data.UpdDt,
            DelFlg: "N"
        }));

        const managementPn = newManagementPlandataSets.map((data) => ({
            DmpPrdNo: data.DmpPrdNo,
            DmpMgmtNo: data.DmpMgmtNo,
            DmpNo: allDmpData.DmpMain.DmpNo,
            DtaNm: replaceEmptyWithDash(data.dataType),
            MngMth: replaceEmptyWithDash(data.method),
            MngResn: replaceEmptyWithDash(data.individualManagement),
            MngPos: replaceEmptyWithDash(data.dataLocation),
            MngPd: replaceEmptyWithDash(data.storagePeriod),
            MngStgMth: replaceEmptyWithDash(data.dataLocation),
            MngOpnMth: replaceEmptyWithDash(data.storagePeriod),
            InsDt: data.InsDt,
            UpdDt: data.UpdDt,
            DelFlg: "N"
        }));

        api.post('/api/dmp/modifydmp', {
            DmpMain: {
                DmpNo: allDmpData.DmpMain.DmpNo,
                ProjNo: allDmpData.DmpMain.ProjNo,
                ProjNm: allDmpData.DmpMain.ProjNm,
                RspnEmpNo: allDmpData.DmpMain.RspnEmpNo,
                RspnEmpNm: allDmpData.DmpMain.RspnEmpNm,
                RsrcSmm: replaceEmptyWithDash(modifyMethodSummary),
                RsrcImg: imageToSave,
                RsrcFileNm: "",
                RsrcFilePath: "",
                RsrcAnlyPlan: modifyMethodAnalysis,
                SubmitStatus: "0",
                UserNo: allDmpData.DmpMain.UserNo,
                UserNm: allDmpData.DmpMain.UserNm,
                TmpYn: "Y" // 임시 저장이므로 TmpYn은 "Y"
            },
            Summary: modifySummary,
            Prod: productionPn,
            Mnmt: managementPn,
            Open: publicPn,

        }, {
            withCredentials: true,
            headers: {
                'X-XSRF-TOKEN': csrfToken,
            }
        }).then((res) => {
                alert("임시저장이 완료되었습니다.");
                navigate("/dmpManagement/dmpList");
            })
            .catch((error) => {
                console.error("API 통신 에러 발생: ", error.response ? error.response.data : "No response data");
                alert("임시저장에 실패했습니다. 데이터를 확인해주세요.");
            });
    };



    // 데이터 저장 처리
    const DataSaveBtn = () => {

        const imageToSave = RsrcImg || allDmpData.DmpMain.RsrcImg; // 새로운 이미지가 있으면 사용하고, 없으면 기존 이미지 사용

        const csrfToken = Cookies.get('XSRF-TOKEN');

        const modifySummary = modifySummaryData.map((item) => ({
            DmpNo: item.Summary.DmpNo,
            DtaSetNo: item.Summary.DtaSetNo,
            DtaSetHisNo: item.Summary.DtaSetHisNo,
            DtaSetNm: item.Summary.DtaSetNm,
            DtaSetNmSub: item.Summary.DtaSetNmSub,
            DtaNm: item.Summary.DtaNm,
            DtaDs: item.Summary.DtaDs,
            DmpFlg: item.Summary.DmpFlg,
            InsDt: item.Summary.InsDt,
            UpdDt: item.Summary.UpdDt,
            HisInsDT: item.Summary.HisInsDT,
            DelFlg: item.Summary.DelFlg,
            DmpHisNo: item.Summary.DmpHisNo,
                DataMain : item.DataMain.map((dataSet) => ({
                DtaSetNo: item.Summary.DtaSetNo,
                    DmpNo: item.Summary.DmpNo,
                    DtaNo: dataSet.DtaNo,
                    DtaFld: dataSet.DtaFld ,
                    DtaTyp: dataSet.DtaTyp,
                    DtaUnit: dataSet.DtaUnit,
                    DtaMtdIdx: dataSet.DtaMtdIdx,
                    DtaMtdNm: dataSet.DtaMtdNm ,
                    DtaDsc: dataSet.DtaDsc,
                    UserNo: dataSet.UserNo,
                    UserNm: dataSet.UserNm,
                    InsDt: dataSet.InsDt,
                    UpdDt: dataSet.UpdDt,
                    DelFlg: "N"
            }))
        }))

        const productionPn = newProductionPlandataSets.map(data => ({
            DmpPrdNo: data.DmpPrdNo,
            DmpNo: allDmpData.DmpMain.DmpNo,
            PrdYear: data.PrdYear,
            DtaNm: data.dataType,
            ProCnt: data.count,
            ProPlan: data.proPlan,
            InsDt: data.InsDt,
            UpdDt: data.UpdDt,
            DelFlg: "N"
        }));

        const publicPn = newPublicPlandataSets.map((data) => ({
                DmpPrdNo: data.DmpPrdNo,
                DmpNo: allDmpData.DmpMain.DmpNo,
                DtaNm : data.dataType,
                OpnTm : data.publictime,
                OpnPd : data.externalTime,
                OpnMth : data.publicmethod,
                OpnResn : data.individualplan,
                OpnOpnMth : data.individualmethod,
                InsDt: data.InsDt,
                UpdDt: data.UpdDt,
                DelFlg : "N"
            }
        ))

        const managementPn = newManagementPlandataSets.map((data) => ({
                DmpPrdNo: data.DmpPrdNo,
                DmpMgmtNo: data.DmpMgmtNo,
                DmpNo: allDmpData.DmpMain.DmpNo,
                DtaNm : data.dataType,
                MngMth : data.method,
                MngResn : data.individualManagement,
                MngPos : data.dataLocation,
                MngPd : data.storagePeriod,
                MngStgMth: (data.dataLocation),
                MngOpnMth: (data.storagePeriod),
                InsDt: data.InsDt,
                UpdDt: data.UpdDt,
                DelFlg: "N"
            }
        ))

        api.post('/api/dmp/modifydmp', {
            DmpMain: {
                DmpNo: allDmpData.DmpMain.DmpNo,
                ProjNo: allDmpData.DmpMain.ProjNo,
                ProjNm: allDmpData.DmpMain.ProjNm,
                RspnEmpNo: allDmpData.DmpMain.RspnEmpNo,
                RspnEmpNm: allDmpData.DmpMain.RspnEmpNm,
                RsrcSmm: modifyMethodSummary,
                RsrcImg:imageToSave,
                RsrcFileNm:"",
                RsrcFilePath:"",
                RsrcAnlyPlan: modifyMethodAnalysis,
                SubmitStatus: "1",
                UserNo: allDmpData.DmpMain.UserNo,
                UserNm: allDmpData.DmpMain.UserNm,
                TmpYn: "N"
            },
            Summary: modifySummary,
            Prod: productionPn,
            Mnmt:managementPn,
            Open:publicPn,

        }, {
            withCredentials: true,
            headers: {
                'X-XSRF-TOKEN': csrfToken,
            }
        }).then((res) => {
                alert(res.data)
                navigate("/dmpManagement/dmpList")
            })
            .catch((error) => {
                console.error("API 통신 에러 발생: ", error.response ? error.response.data : "No response data");
                alert("수정데이터를 확인해주세요.");
            });
    };

    const dmpDeleteBtn = async () => {
        try {
            const csrfToken = Cookies.get('XSRF-TOKEN'); // CSRF 토큰을 쿠키에서 가져오기


            await api.delete(`/api/dmp/deldmp/${allDmpData.DmpMain.DmpNo}`,{
                headers: {
                    'X-XSRF-TOKEN': csrfToken, // CSRF 토큰 헤더에 추가
                },
                withCredentials: true, // 쿠키 사용 시 필요
            })
                .then((res) => {
                    alert(res.data)
                    navigate("/dmpManagement/dmpList")
                });
        }catch (err){
            console.error("삭제 api를 확인해주세요. ")
        }
    }

    useEffect(() => {
        // 세션을 유지하기 위한 함수
        const keepSessionAlive = async () => {
            try {
                // 백엔드에 세션 유지 요청 보내기
                const response = await api.get('/api/auth/user', { withCredentials: true });
                console.log('Session kept alive:', response.data);
            } catch (error) {
                console.error('Failed to keep session alive:', error);
            }
        };

        // 30분(1800000ms)마다 세션 유지 요청 보내기
        const intervalId = setInterval(keepSessionAlive, 1200000); // 20분 = 1200000ms

        // 컴포넌트가 언마운트될 때 setInterval 정리
        return () => clearInterval(intervalId);
    }, []);



    return(
        <>
            <div className="dmp-banner-container">
                <div className="dmp-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/sub-introimg02.jpg`} className="dmp-img02" alt="search Logo" />
                    <div className="banner-text">
                        <span className="intro-subName">데이터 관리 계획</span>
                    </div>
                    <div className="dmp-description">
                        <div className="dmp-description02">
                            <img  src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터 관리 계획 > 데이터 관리 계획 등록</span>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="side-container">
                        {/* 사이드바 */}
                        <div className="side-sidebar">
                            <span className="side-name">작성 단계</span>
                            <div className="side-hisBtn-container">
                                <button id="side-hisBtn" onClick={DataHisBtn} >
                                    임시저장
                                </button>
                            </div>
                            <div className="start-side_line"></div>
                            <ul className="side-menu-list">
                                <li>
                                    <button onClick={() => handleScrollToSection(taskInfoRef)} className="side-menu-button">
                                        과제 정보
                                        <span className="required_color">*</span>
                                    </button>
                                </li>
                                <li>
                                    <button onClick={() => handleScrollToSection(researchMethodRef)} className="side-menu-button">
                                        연구 방법 요약
                                        <span className="required_color">*</span>
                                    </button>
                                </li>
                                <li>
                                    <button onClick={() => handleScrollToSection(researchImgRef)} className="side-menu-button">
                                        연구데이터 구성
                                    </button>
                                </li>
                                <li>
                                    <button onClick={() => handleScrollToSection(sidebarDataSetsRef)} className="side-menu-button">
                                        데이터세트
                                        <span className="required_color">*</span>
                                    </button>
                                </li>
                                {modifySummaryData.map((data, index) => (
                                    <li key={data.Summary.id}>
                                        <button className="side-datasets-button"
                                                onClick={() => handleScrollToElement(index)} // 클릭 시 스크롤 이동
                                        >
                                            {`- (${data.Summary.DtaSetNm})`}<br/>
                                            {`${data.Summary.DtaNm}`}
                                        </button>
                                    </li>
                                ))}
                                <li>
                                    <button className="side-menu-button">
                                        생산 계획
                                        <span className="required_color">*</span>
                                    </button>
                                </li>
                                {generateProductionPlans().map((year) => (
                                    <li key={year} onClick={() => handleScrollToYears(year)}>
                                        <button
                                            className="side-datasets-button"
                                        >
                                            {`- ${year}년 생산 계획`}<br />
                                        </button>
                                    </li>
                                ))}

                                {/*<li>*/}
                                {/*    <button onClick={() => handleScrollToSection(analySideDataRef)} className="side-menu-button">*/}
                                {/*        분석 계획*/}
                                {/*        <span className="required_color">*</span>*/}
                                {/*    </button>*/}
                                {/*</li>*/}
                                <li>
                                    <button onClick={() => handleScrollToSection(openDataRef)} className="side-menu-button">
                                        공개 계획
                                        <span className="required_color">*</span>
                                    </button>
                                </li>
                                <li>
                                    <button onClick={() => handleScrollToSection(mgDataRef)} className="side-menu-button">
                                        관리 계획
                                        <span className="required_color">*</span>
                                    </button>
                                </li>
                                {/* 다른 항목도 동일하게 추가할 수 있음 */}
                            </ul>
                        </div>
                    </div>
                    <section className="contents-section">
                        <div className="management-plan-title">
                            <h1>데이터 관리 계획</h1>
                            <div>
                                <span className="required_color">*</span>
                                <span>필수 항목</span>
                            </div>
                        </div>
                        <div className="under_line"></div>
                        <div className="object-info">
                            <div className="object-info-title" ref={taskInfoRef}>
                                <span className="info">과제 정보</span>
                                <span className="required_color">*</span>
                            </div>
                            <div className="task-title">
                                <div className="task-wrapper">

                                    <>
                                        <button className="check-obj" onClick={objHandler}>과제 선택</button>
                                        {showObjCheck && <TaskCheck />}
                                    </>

                                    {
                                        allDmpData?.DmpMain?.DmpNo && (
                                            <div key={allDmpData.DmpMain.DmpNo} style={{display : 'flex'}}>
                                                <input
                                                    type="text"
                                                    className="edit--box"
                                                    readOnly
                                                    value={updateProNo || ''}
                                                    onChange={(e) => setUpdateProNo(e.target.value)}
                                                    placeholder="과제번호"
                                                />
                                                <input
                                                    type="text"
                                                    className="edit--box"
                                                    readOnly
                                                    value={updateProName || ''}
                                                    onChange={(e) => setUpdateProName(e.target.value)}
                                                    placeholder="과제명"
                                                />
                                                <input
                                                    type="text"
                                                    className="edit--box"
                                                    readOnly
                                                    value={updateProLeader || ''}
                                                    onChange={(e) => setUpdateProLeader(e.target.value)}
                                                    placeholder="연구책임자"
                                                />
                                            </div>
                                        )}

                                    <>
                                        <button className="last-dmp" onClick={lastHandler}>지난 DMP 불러오기</button>
                                        {showLastDmp && <LastDmpShow />}
                                        {/*<button className="history-dmp" >임시저장 DMP 불러오기</button>*/}
                                        {/*{showHistory && <LastDmpShow setShowHistory = {showHistory}/>}*/}
                                    </>



                                </div>
                                <div>
                                    <span>* 과제 번호를 선택하면 과제 정보가 자동으로 입력됩니다.</span>
                                </div>
                            </div>
                        </div>
                        <div className="start_line"></div>
                        <div className="research-method-info">
                            <div className="object-info-title" ref={researchMethodRef}>
                                <span className="info">연구 방법 요약</span>
                                <span className="required_color">*</span>
                            </div>
                            <div className="research-method-title">
                                <textarea
                                    type="text"
                                    value={modifyMethodSummary}
                                    onChange={(e) => setModifyMethodSummary(e.target.value)}
                                    className="edit--box"
                                    placeholder="연구방법을 서술형으로 작성하세요."
                                />
                            </div>
                        </div>
                        <div className="start_line"></div>

                        <div className="configuring-research-data-info" ref={researchImgRef}>
                            <div className="object-info-title">
                                <span className="info">연구데이터 구성</span>
                            </div>
                            <div className="research-method-title">
                                <label htmlFor="fileDiagram" style={{ cursor: 'pointer', display: 'block', textAlign: 'center' }}>
                                    {imageSrc ? (
                                        <img id="imgDiagram" src={imageSrc} alt="Diagram" style={{ maxWidth: '100%', height: 'auto' }} />
                                    ) : (
                                        <div>
                                            <img
                                                id="imgPlaceholder"
                                                src={`${process.env.PUBLIC_URL}/no-img02.png`}
                                                alt="No Image Available"
                                                style={{ maxWidth: '600px', height: 'auto', display: 'block', margin: '0 auto' }}
                                            />
                                            <p style={{ color: '#999', fontSize: '14px', marginTop: '10px' }}>이미지가 없습니다. 클릭해서 이미지를 첨부해주세요.</p>
                                        </div>
                                    )}
                                </label>
                                <input
                                    type="file"
                                    id="fileDiagram"
                                    name="fileDiagram"
                                    style={{ display: 'none' }}
                                    onChange={handleImageChange}
                                />
                                <input type="text" id="txtDiagram" name="txtDiagram" style={{ display: 'none' }} />
                            </div>
                        </div>
                        <div className="start_line"></div>

                        {
                            <div className="data-set-container" >
                                {modifySummaryData.map((updateSummaryItem, index) => (
                                    <div className="data-set-info" key={index}>
                                        <div className="data-set-title-wrapper">
                                            <div className="test-summary">
                                                <div className="data-set-title"
                                                     ref={(el) => {
                                                         dataRefs.current[index] = el; // 동적으로 ref 배열에 항목 추가
                                                     }}
                                                >
                                                    <span className="info">데이터 세트</span>
                                                    <span className="required_color">*</span>
                                                </div>
                                                <button className="data-set-delete" onClick={() => handleDeleteDataSet(updateSummaryItem.Summary.id || updateSummaryItem.Summary.DtaSetNo)}>
                                                    <span>-</span>
                                                    <span>삭제</span>
                                                </button>

                                            </div>
                                            <div className="selectbox-container">
                                                <div className="selectbox-check">
                                                    <select
                                                        className="resultData"
                                                        // id={`selectBox-${updateSummaryItem.Summary.DtaSetNo}`}
                                                        value={updateSummaryItem.Summary.DtaSetNm}
                                                        onChange={(event) => handleUpdateChange(updateSummaryItem.Summary.id || updateSummaryItem.Summary.DtaSetNo, event)}

                                                    >
                                                        <option value=''>-- 구분 --</option>
                                                        <option value="시료데이터">시료데이터</option>
                                                        <option value="방법데이터">방법데이터</option>
                                                        <option value="결과데이터">결과데이터</option>
                                                    </select>
                                                    <select
                                                        className="resultData"
                                                        id="selectBox"
                                                        value={updateSummaryItem.Summary.DtaSetNmSub}
                                                        // onChange={(event) => handleRequiredChange( updateSummaryItem.Summary.id || updateSummaryItem.Summary.DtaSetNo, event)}

                                                        onChange={(event) => handleRequiredChange(updateSummaryItem.Summary.id || updateSummaryItem.Summary.DtaSetNo, event)}
                                                        disabled={["방법데이터", "결과데이터"].includes(updateSummaryItem.Summary?.DtaSetNm)} // 조건에 따라 비활성화
                                                    >
                                                        <option value=''>-- 선택 * 필수 --</option>
                                                        <option value="Food, Unprocessed">Food, Unprocessed</option>
                                                        <option value="Food, Processed">Food, Processed</option>
                                                        <option value="Food, Derived">Food, Derived</option>
                                                        <option value="Animal">Animal</option>
                                                        <option value="Human">Human</option>
                                                        <option value="Invertebrate">Invertebrate</option>
                                                        <option value="Microbe">Microbe</option>
                                                        <option value="Cell, Animal">Cell, Animal</option>
                                                        <option value="Cell, Human">Cell, Human</option>
                                                        <option value="Cell, Plant">Cell, Plant</option>
                                                        <option value="etc">etc</option>
                                                    </select>

                                                    <input
                                                        type="text"
                                                        className="edit--box"
                                                        value={updateSummaryItem.Summary.DtaNm}
                                                        onChange={(event) => handleInputChange( updateSummaryItem.Summary.id || updateSummaryItem.Summary.DtaSetNo, 'DtaNm', event)}
                                                        id="data-type-name"
                                                        name="DtaNm"
                                                        placeholder="데이터 타입명"
                                                    />
                                                    <input type="text"
                                                           className="edit--box"
                                                           value={updateSummaryItem.Summary.DtaDs}
                                                           onChange={(event) => handleInputdescChange( updateSummaryItem.Summary.id || updateSummaryItem.Summary.DtaSetNo, event)}
                                                           id="data-type-desc"
                                                           name="DtaDs"
                                                           placeholder="데이터 타입 설명"
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="toastui-wrapper">
                                            <DmpModifyToastGrid ref={sidebarDataSetsRef} MethodData={updateSummaryItem.Summary.DtaSetNm} DateSets={updateSummaryItem} />
                                        </div>
                                    </div>
                                ))}
                                <button className="data-set-add" ref={sidebarDataSetsRef} type="button" onClick={handleAddDataSet}>{`+ 데이터 세트 양식 추가`}</button>
                            </div>

                        }
                        <div className="start_line"></div>
                        <div className="data-management-info" >
                            {generateProductionPlans().map((year, index) => (
                                <div className="data-hisTitle-info"  key={index}
                                     ref={(el) => (yearRefs.current[year] = el)} // 동적으로 각 연도에 ref 할당
                                     id={`production-plan-${year}`}>
                                    <div className="object-info-title">
                                        <span className="info">{year}년 생산 계획</span>
                                        <span className="required_color">*</span>
                                    </div>
                                    {
                                        allDmpData && (
                                            <div>
                                                <DmpDataProductionPlan updateProductionPn = {allDmpData}
                                                                       year={year}
                                                                       id={`production-plan-${year}`}
                                                                       setAllData={setAllData}
                                                                       proplanDataTypes = {proplanDataTypes}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            ))}

                        </div>

                        {/*<div className="start_line"></div>*/}
                        {/*<div className="data-analysis-info">*/}
                        {/*    <div className="object-info-title">*/}
                        {/*        <span className="info">연구데이터 분석 계획</span>*/}
                        {/*        <span className="required_color">*</span>*/}
                        {/*    </div>*/}
                        {/*    {*/}
                        {/*        allDmpData && (*/}
                        {/*            <div className="research-method-title">*/}
                        {/*                <textarea type="text"*/}
                        {/*                          className="edit--box"*/}
                        {/*                          value={modifyMethodAnalysis}*/}
                        {/*                          onChange={(e) => setModifyMethodAnalysis(e.target.value)}*/}
                        {/*                          placeholder="연구데이터 분석 계획을 서술형으로 작성하세요."></textarea>*/}
                        {/*            </div>*/}
                        {/*        )*/}
                        {/*    }*/}
                        {/*</div>*/}
                        <div className="start_line"></div>

                        <div className="data-hispublic-info" ref={openDataRef}>
                            <div className="object-info-title">
                                <span className="info">공개 계획</span>
                                <span className="required_color">*</span>
                            </div>

                            {
                                allDmpData && (
                                    <div>
                                        <DataPublicGrid updateProductionPn = {allDmpData}  proplanDataTypes = {proplanDataTypes}/>
                                    </div>
                                )
                            }

                        </div>
                        <div className="start_line"></div>
                        <div className="data-hisTitle-info" ref={mgDataRef}>
                            <div className="object-info-title">
                                <span className="info">관리 계획</span>
                                <span className="required_color">*</span>
                            </div>

                            {
                                allDmpData && (
                                    <div>
                                        <DataManagementGrid updateProductionPn = {allDmpData}  proplanDataTypes = {proplanDataTypes}  />
                                    </div>
                                )
                            }
                        </div>
                        <div className="start_line"></div>
                    </section>
                </div>

                <div className="save-container">
                    {/*<button id="temporary-Storage">임시저장</button>*/}

                        <button id="dmp_list" onClick={()=> navigate("/dmpManagement/dmpList")}>
                            목록
                        </button>

                        <button id="save-btn" onClick={DataHisBtn} >
                            임시저장
                        </button>
                        <button id="save-btn" onClick={DataSaveBtn}>
                            수정
                        </button>

                        <button id="dmp_list" onClick={dmpDeleteBtn}>삭제</button>
                </div>

            </div>
        </>
    )
}
export default DmpmodifyPage;